import loadJsonPost from 'Components/Lib/Utils/loadJsonPost';
import URLS from 'Components/Lib/Utils/URLs.API';

export interface IUrlData {
  url: URLS | null;
  sendData : Object;
  id: string | number | null;
}

const apiPost = (url: URLS | null | string, sendData : Object, id: string | number | null = null) : any  => {  
    
  return new Promise( resolve => {
    if (url) {
        loadJsonPost(url, sendData, id).then(data => {
          if (data.Meta.Success && data.Data) {
            resolve(data.Data) ;  
            //logger.info('Load successfully: ' + url);
          }else if(data.DataCreated){
            resolve(data.DataCreated);
            //logger.info('Created successfully: ' + url);
          }
          else {
            resolve(data) ;
          }
          return data;
        }, (error) => {
          //logger.error(error);
          resolve(error);
        });
      }
      else 
        resolve( null);
    
  });
};

export default apiPost;
