/* eslint-disable jsx-a11y/anchor-is-valid */

/**
 * Renders a Quicklink
 * @returns {JSX.Element} The styled Link.
 * @param {any} props given by caller
 */

import { useState } from 'react';

const Quicklink = props => {
    return (
        <>
            <a className="mfx-element-quicklink button button-border" onClick={() => props.onClickHandler('contact')} /*href={props.designData.Link} */ style={{ borderColor: props.designData.LinkColor }}>
                <i className={props.designData.LinkIcon + ' icons'}></i>
                {!isNaN(props.designData.Text) ? window.Dictionary.getTranslation(props.designData.Text) : props.designData.Text}
            </a>
        </>
    );
};

export default Quicklink;
