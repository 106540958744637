import { useEffect, useState } from 'react';

// import registerBroadcasts from 'Components/Lib/Utils/registerBroadcasts';
import LocalStorage, { ILocal } from 'Helpers/MFXLocalStorage';

export interface IStatedSession {
    id: string;
    selected: boolean;
    data: ILocal;
    onClickHandler?(): any;
    mobile?: boolean;
}

export default function useSessions(): IStatedSession[] {
    const [sessionKeysChecksum, setLocalKeys] = useState([] as any);
    const [sessionData, setLocalData] = useState([] as IStatedSession[]);

    useEffect(() => {
        if (LocalStorage.getDBKeys().length > 0) {
            const curr = LocalStorage.getCurrentPath();
            setLocalKeys(LocalStorage.getDBKeys());

            let dbData = LocalStorage.getAllTabData();
            setLocalData(
                Object.keys(dbData).map(key => ({
                    id: key,
                    selected: key === curr,
                    data: dbData[key] as ILocal,
                }))
            );
        }
    }, [setLocalData]);

    return sessionData;
}
