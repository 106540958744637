/**
 * Renders an generic text
 * @returns {JSX.Element} The basic text.
 * @param {any} props given by caller
 */


const TableText = props => {

    let inputValue = '';
    if (props.viewName === 'addView' && !props.value) {
        inputValue = props.designData.Value;
        if (props.designData.Key === 'Begin') inputValue = getTodaysDate();
        else if (props.designData.Key === 'EndTime') inputValue = getTodaysDate(2);
    } else if (props.designData?.ElementType?.toLowerCase() === 'text') {
        inputValue = props.value ? props.value : props.designData?.Defaultvalue ? props.designData?.Defaultvalue : '';
    } else if (props.designData?.Defaultvalue === 'today') {
        inputValue = getTodaysDate();
    } else {

        inputValue =
            props.designData.Type.toLowerCase() === 'number'
                ? Math.round((props.value ?? props.designData?.Defaultvalue ?? 0) * 100) / 100
                : props.designData.Type.toLowerCase() === 'datetime-local'
                ? toDateTimeFormat(props.value ?? props.designData?.Defaultvalue)
                : props.designData.Type.toLowerCase() === 'date'
                ? toDateFormat(props.value ?? props.designData?.Defaultvalue)
                : props.value ?? props.designData?.Defaultvalue;
    }
    if (props.designData.Key === 'Priority' || props.designData.Key === 'Data-Priority') {

        switch (inputValue) {
            case '':
            case 0:
                inputValue = window.Dictionary.getTranslation(1408);
                break;
            case 1:
                inputValue = window.Dictionary.getTranslation(1409);
                break;
            case 2:
                inputValue = window.Dictionary.getTranslation(1410);
                break;
            case 3:
                inputValue = window.Dictionary.getTranslation(4955);
                break;
            default:
                break;
        }
    }


    function getTodaysDate(hour = 0) {
        let date = new Date();
        let month = new Date().getMonth() + 1;   

        if (props.designData?.Type === 'date') return addZero(date.getDate())+ '.' + addZero(month) + '.' + date.getFullYear() ;
        else {
            let nhour = date.getHours() + hour;
            nhour = nhour <= 24 ? nhour : nhour - 24;
            return addZero(date.getDate()) + '.' + addZero(month) + '.' + date.getFullYear()+ ' ' + addZero(nhour) + ':' + addZero(date.getMinutes());
        }
    }

    function addZero(number) {
        return number > 9 ? number : '0' + number;
    }

    function toDateFormat(input) {
        if (input === null || input === undefined) {
            return input;
        }
        let date = new Date(input);
        if (isNaN(date.getTime())) date = new Date(null);
        return addZero(date.getDate()) + '.' + addZero(date.getMonth() + 1) + '.' + date.getFullYear();
        
    }

    function toDateTimeFormat(input) {
        if (input === null || input === undefined) {
            return input;
        }
        /*if (typeof input === 'string') {
            return input.substring(0, 16).replace('T','  ');
        }*/
        let date = new Date(input);

        if (isNaN(date.getTime())) {
            date = new Date(null);
        }
        //date = date.toJSON().slice(0, 16);
        return addZero(date.getDate()) + '.' + addZero(date.getMonth() + 1) + '.' + date.getFullYear()+ ' ' + addZero(date.getHours()) + ':' + addZero(date.getMinutes());
    }

    
    return (
        <div className={`mfx-text-element ${props.designData.Type}`}>
            {(props.designData.Key === 'Process-SignalCode' || props.designData.Key === 'SignalCode') && <span className={'signalcode' + (inputValue !== null && inputValue !== undefined ? '_' + inputValue : '')}></span>}
            {(props.designData.Key !== 'Process-SignalCode' && props.designData.Key !== 'SignalCode') && <p>{inputValue ?? ''}</p>}
        </div>
    );
};

export default TableText;
