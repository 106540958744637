/**
 * Renders an generic text currency field
 * @returns {JSX.Element} The basic text input.
 * @param {any} props given by caller
 */

const Currency = props => {
    let currencyID = !isNaN(props.designData.Name) ? window.Dictionary.getTranslation(props.designData.Name) : props.designData.Name;
    currencyID = 'input-' + currencyID?.replace(/\s/g, '-').toLowerCase();

    if (props.customerData || props.customerData === 0) {
        props.customerData = props.customerData.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
        });
    }

    const formatCurrencyEvent = event => {
        let input_val = formatCurrency(event.target.value);
        // send updated string to input
        event.target.value = input_val;
    };

    function formatCurrency(value) {
        let input_val = value?.toString()?.replace('.',',');
        if (input_val === '' || !input_val) input_val = '0';
        if (input_val.indexOf(',') >= 0) {
            var decimal_pos = input_val.lastIndexOf(',');
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = formatNumber(left_side);
            right_side = formatNumber(right_side);

            // On blur make sure 2 numbers after decimal
            right_side += '00';
            right_side = right_side.substring(0, 2);
            input_val = left_side + ',' + right_side;
        } else {
            input_val = formatNumber(input_val);
            input_val += ',00';
        }
        return input_val;
    }

    function formatNumber(n) {
        // format number 1000000 to 1,234,567
        return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    return (
        <div className="mfx-input-element mfx-input-currency">
            {props.designData.Label && <label htmlFor={currencyID}>{!isNaN(props.designData.Label) ? window.Dictionary.getTranslation(props.designData.Label) : props.designData.Label}</label>}
            <input
                id={currencyID}
                type={props.disableMode === true ? 'text' : props.designData.Type}
                defaultValue={formatCurrency(props.designData.Value ?? props.designData.DefaultValue ?? props.customerData)}
                name={currencyID}
                placeholder={props.designData.Placeholder}
                disabled={Boolean(props.disableMode)}
                min={props.designData.Min}
                max={props.designData.Max}
                readOnly={props.designData.ReadOnly || props.designData.ReadOnly}
                pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                onBlur={formatCurrencyEvent}
            />

            {
                //<i className='fas fa-euro-sign'></i>
            }
        </div>
    );
};

export default Currency;
