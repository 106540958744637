/*
 * Storage for all Customers
 * Customer List
 */

import { createSlice, configureStore, current } from '@reduxjs/toolkit';

const pageSlice = createSlice({
    name: 'page',
    initialState: {
        pageID: -1,
        pageData: [
            {
                name: '',
                Headline: '',
                Subheadline: '',
                Description: '',
                Icon: '',
                IconColor: '',
                w: 0,
                x: 0,
                y: 0,
                h: 0,
                index: 0,
                children: [],
                Group: ''
            },
        ],
        savedPageData: [
            {
                name: '',
                Headline: '',
                Subheadline: '',
                Description: '',
                Icon: '',
                IconColor: '',
                w: 0,
                x: 0,
                y: 0,
                h: 0,
                index: 0,
                children: [],
                Group: ''
            },
        ],
    },
    reducers: {
        init(state, action) {
            //sets initial Data
            state.pageData = action.payload.json;
            state.savedPageData = action.payload.json;
            state.pageID= action.payload.id;
        },
        addContainer(state, action) {
            let newContainer = action.payload;
            newContainer.Name = newContainer.i;
            state.pageData = [...state.pageData, action.payload];
        },
        changeData(state, action) {
            console.log(action.payload);
            let curContainerIndex = action.payload.curSelected;
            state.pageData[curContainerIndex][action.payload.type] = action.payload.value;
        },
        changeInGroup(state, action){
            if(state.pageData[action.payload.curSelected]['Group'] != action.payload.groupType)
                state.pageData[action.payload.curSelected]['Group'] = action.payload.groupType;
            else 
                state.pageData[action.payload.curSelected]['Group'] = '';
        },
        changeCheckedViews(state, action) {
            let viewType = action.payload.view;
            let layout = action.payload.label;

            let curContainerIndex = action.payload.curSelected;
            let foundViewInContainer = false;

            if (Object.keys(state.pageData[curContainerIndex].children).length > 0) {
                Object.entries(state.pageData[curContainerIndex].children).forEach(e => {
                    if (e[1].viewType === viewType && e[1].layoutName === layout) {
                        if (!action.payload.newValue) {
                            foundViewInContainer = true;
                            let name = Object.entries(state.pageData[curContainerIndex].children).find(d => {console.log(current(d[1])); return d[1].viewType === viewType && d[1].layoutName === layout;})[0];
                            delete state.pageData[curContainerIndex].children[name];
                        }
                    }
                });
            }
            console.log(action.payload);
            if (!foundViewInContainer && action.payload.newValue)
                state.pageData[curContainerIndex].children[viewType + '-' + layout] = {
                    order: Object.keys(state.pageData[curContainerIndex].children).length,
                    index: Object.keys(state.pageData[curContainerIndex].children).length,
                    viewType: action.payload.view,
                    layoutName: layout,
                    viewLabel: action.payload.viewLabel

                };
        },
        deleteContainer(state, action) {
            if (state.pageData.length === 1) state.pageData = [];
            else state.pageData.splice(action.payload.containerindex, 1);
        },
        deleteView(state, action) {
            console.log(action.payload);
            delete state.pageData[action.payload.containerindex].children[action.payload.view];
        },
        changeOrder(state, action) {
            console.log(action.payload);
            let curContainerIndex = action.payload.curContainerSelected;
            let curContainerchildren = state.pageData[curContainerIndex].children;
            let curItemOrder = curContainerchildren[action.payload.listElement].order;

            if (action.payload.directionUp) curContainerchildren[action.payload.listElement].order = curItemOrder - 1.5;
            else state.pageData[curContainerIndex].children[action.payload.listElement].order = curItemOrder + 1.5;

            let sortArray = [];
            Object.keys(curContainerchildren).forEach(key => {
                sortArray.push({ Name: key, Order: curContainerchildren[key].order });
            });
            sortArray = sortArray.sort((a, b) => {
                if (a['Order'] < b['Order']) return -1;
                else return 1;
            });
            console.log(sortArray);
            for (let i = 0; i < sortArray.length; i++){
                console.log(current(curContainerchildren[sortArray[i]['Name']]));
                curContainerchildren[sortArray[i]['Name']].order = i + 1;
            } 
            console.log(current(state.pageData[curContainerIndex].children));
        },
        changeLayout(state, action) {
            for (let i = 0; i < action.payload.layout.length; i++) {
                state.pageData[i] = { ...state.pageData[i], x: action.payload.layout[i].x, y: action.payload.layout[i].y, w: action.payload.layout[i].w, h: action.payload.layout[i].h };
            }
        },
        resetData(state) {
            state.pageData = state.savedPageData;
        },
        changeTabLabel(state, action) {
            state.pageData[action.payload.containerindex].children[action.payload.view].ViewLabel = action.payload.newValue;
        },
        saveData(state) {
            state.savedPageData = state.pageData;
        },
        setID(state, action) {
            state.pageID = action.payload.id;
        },
        removeHeadline(state, action) {
            state.pageData[action.payload.containerid].Headline = '';
        },
        removeSubheadline(state, action) {
            state.pageData[action.payload.containerid].Subheadline = '';
        }
    },
});

const store = configureStore({
    reducer: pageSlice.reducer,
});
export const pageActions = pageSlice.actions;
export default store;
