import {useState} from 'react';
import ConfirmPopup from './ConfirmPopup';

/**
 * Renders only the Editbutton
 * @returns {JSX.Element} The basic Editbutton.
 */

 const Editbutton = (props) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
        
    return(
        <>
            {!props.disableMode && props.actions?.update && 
                <button type="reset" className="mfx-button mfx-button-type-icon" onClick={props.resetChangeHandler}>
                    <i className="icon-close"></i>
                </button>
            }
            {props.disableMode && props.actions?.update &&
                <button type="button" className="mfx-button mfx-button-type-icon" onClick={() => props.disableChangeHandler(false)}>
                    <i className="icon-edit"></i>
                </button>
            } 
            {!props.disableMode && props.saveChangeHandler &&
               <button type="button" className="mfx-button mfx-button-type-icon" onClick={props.saveChangeHandler}>
                    <i className="icon-save"></i>     
                </button> 
            }{!props.disableMode && props.submit &&
                <button type="submit" className="mfx-button mfx-button-type-icon" >
                    <i className="icon-save"></i>     
                </button> 
            }
            {props.deleteChangeHandler && props.actions?.delete &&
                <button type="button" className="mfx-button mfx-button-type-icon" onClick={() => setConfirmOpen(true)}>
                    <i className="icon-delete"></i>
                </button>
            }
            {confirmOpen && 
                <ConfirmPopup confirm={props.deleteChangeHandler} stop={() => setConfirmOpen(false)} >
                    {window.Dictionary.getTranslation(props.confirmNotice)}
                </ConfirmPopup>
            }
            
               
        </>
     );
 
 };
 
 export default Editbutton;