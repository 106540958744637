import { Route, useParams } from 'react-router-dom';
import { GenericPageProvider } from '../Components/Lib/Designer/GenericPageEditor';
import menu from '../Data/Designer/menu_main.json';


const Pages = (
    
    <Route path=":pageName" element={<PageByName />} />
);

export default Pages;


function PageByName():JSX.Element {
    const { pageName } = useParams<{ pageName: string }>();
    let entry = getMenuEntry(pageName as string);

    return (
        <GenericPageProvider name={pageName} menuData={entry} />
    );
}

export function getMenuEntry(pageName:string) {
    for(let i = 0; i < menu.entries.length; i++ ) {
        for(let j = 0; j < (menu.entries[i] as any).children.length; j++ ) {
            if(((menu.entries[i] as any).children[j] as any).pagename === pageName){
                return  (menu.entries[i] as any).children[j];
            }   
        }
    }
    return {};
}