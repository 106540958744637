import { Navigate, To } from 'react-router-dom';
import LocalStorage from './MFXLocalStorage';

export default function LocalStorageNavigate(props: { to: To | string }) {

  const newTo =
    '/designer/' +
    LocalStorage.getConnectionKey() +
    (props.to.toString().startsWith('/') ? '' : '/') +
    props.to;
    return <Navigate to={newTo} />;
}
