import { useEffect, useState } from 'react';

import Loading from 'Components/Lib/Elements/Loading';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';
import URLS from '../Utils/URLs.API';
import deleteData from '../Utils/deleteData';
import ModalPopup from '../Common/ModalPopup';
import { AddViewProvider } from './AddView';
import Addbutton from '../Elements/Addbutton';
import { getPropertiesPerType } from 'Helpers/getPropertiesPerType';
import Quicklink from '../Elements/Quicklink';

interface IListItems {
    Time?: string;
    ListItemName?: string;
    Action?: string;
    Tasknumber?: string | number;
    Taskdesc?: string;
}

/**
 * Renderes a List
 * @param {any} props Properties given by the caller
 * @returns {JSX.Element} Rendered Element.
 */
function NotesView(props: any): JSX.Element {
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [list, setList] = useState({ Data: [], Meta: {} });

    //TODO
    //load todays Appointments from Outlook
    //from - props.Listtype, today
    useEffect(() => {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetNotes).then(data => {
            if (data.Meta.Success && data.Data) {
                setList({ ...list, Data: data.Data as any, Meta: data.ListMeta });
                setLoading(false);
            }
        });
    }, []);

    function deleteNote(noteId) {
        deleteData('/' + LocalStorage?.getConnectionKey() + URLS.DeleteNote + '?Index=' + noteId).then((data: any) => {
            if (data.Meta.Success) window.location.reload();
            else console.log('Error' + data);
        });
    }

    return (
        <div className="mfx-view mfx-listview">
            {loading && <Loading />}
            {!loading && (
                <>
                <ul className="mfx-list notes">
                    {list.Data.map((listitem: any, idx: any) => {
                        return (
                            <li className="note-text" key={idx}>
                                <span>{listitem.Text}</span>
                                <button className="mfx-button" onClick={() => deleteNote(listitem.ID)}>
                                    <i className="icon-delete"></i>
                                </button>
                            </li>
                        );
                    })}
                </ul>

                </>
            )}
            {!loading && list && list.Data.length <= 0 && <h4>{window.Dictionary.getTranslation(14470)}</h4>}
        </div>
    );
}

export default NotesView;
