import { useEffect, useState } from 'react';
import LocalStorage from '../../../../Helpers/MFXLocalStorage';
import loadJsonData from '../../Utils/jsonGet';
import loadJsonPost from '../../Utils/jsonPost';
import loadJsonPut from '../../Utils/jsonPut';
import URLS from '../../Utils/URLs.API';
import defaultColors from '../../../../Data/designer/default_colors.json';
import defaultColorsDark from '../../../../Data/designer/default_colors_dark.json';
import SavedModal from '../Elements/SavedModal';
import Button from '../Elements/Button';

export default function Stylingsview(props: any) {
    const [curLayoutName, setCurLayoutName] = useState('default' as any);
    const [viewLabel, setViewLabel] = useState(props.name);
    const [curViewId, setCurViewId] = useState(null as any);
    const [defaultData, setDefaultData] = useState(null as any);
    const [dataColor, setDataColor] = useState('' as any);
    const [dataLogo, setDataLogo] = useState(null as any);
    const [saveMessage, setSaveMessage] = useState(null as any);

    useEffect(() => {
        getColors();
    }, [props.name]);
    function getColors() {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetViewLayouts + props.name + '&LayoutName=' + curLayoutName)
            .then((data: any) => {
                setCurViewId(data.Data.ID);
                if (props.name.includes('colors')) {
                    if (data.Meta.Success) {
                        setColors(data.Data.Json);
                        setViewLabel(data.Data.ViewLabel);
                    } else {
                        getDefaultDataColor();
                    }
                } else {
                    setDataLogo(data.Data.Json);
                    setDefaultData(data.Data.Json);
                }
            })
            .catch(e => {
                console.log('Default Json Data!');
                getDefaultDataColor();
            });
    }
    console.log(props);
    function getDefaultDataColor() {
        let stylingString = '';
        let ourDefaultColors = defaultColors;
        if (props.name === 'colorsDark') ourDefaultColors = defaultColorsDark;
        console.log(ourDefaultColors);
        ourDefaultColors.map(color => {
            return (stylingString += color + ';');
        });
        setColors(stylingString);
    }

    function setColors(colorString: string) {
        let colors = colorString.split(';');
        (colors as any) = colors.map((color: string) => {
            return color.replace('--', '').replace(' ', '').split(':');
        });
        setDataColor(colors);
        setDefaultData(colors);
    }

    async function handleSubmit(e: any) {
        e.preventDefault();
        let curFormData = e.target;
        let data: any = '';

        if (props.name.includes('colors')) {
            data = '';
            Object.keys(curFormData).forEach((input: any, idx: number) => {
                if (curFormData[input].name && curFormData[input].name !== 'color-text') data += '--' + curFormData[input].name + ':' + curFormData[input].value + ';';
            });
        } else if (props.name.includes('logo')) {
            if (dataLogo) data = dataLogo;
        }

        if (curViewId === null) {
            loadJsonPost('/' + LocalStorage?.getConnectionKey() + URLS.PostBusinessProcessView, {
                CreateData: { ConfigData: JSON.stringify(data), LayoutName: 'default', ViewName: props.name, ViewType: 'Settings' },
            }).then(data => {
                if (data.Meta.Success) setSaveMessage({ message: 14445, success: true });
                else setSaveMessage({ message: 13656, success: false });
            });
        } else {
            loadJsonPut('/' + LocalStorage?.getConnectionKey() + URLS.PutBusinessProcessView, {
                UpdateData: { ConfigData: JSON.stringify(data), LayoutName: 'default', ViewName: props.name, ID: curViewId, ViewType: 'Settings' },
            }).then(data => {
                if (data.Meta.Success) setSaveMessage({ message: 14445, success: true });
                else setSaveMessage({ message: 13656, success: false });
            });
        }

        //setCurPage({ ...curPage, selected: false, saved: true, containerId: -1 });*/
    }

    function fileToDataUri(e: any) {
        let file = e.target.files[0];
        if (file) {
            readFile(file).then(dataUri => {
                setDataLogo(dataUri);
            });
        }
    }
    const readFile = (file: any) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                resolve(event?.target?.result);
            };
            reader.readAsDataURL(file);
        });

    const resetData = () => {
        if (props.name.includes('logo')) setDataLogo(defaultData);
        else if (props.name.includes('colors')) setDataColor(defaultData);
    };

    const randomizeColors = () => {
        let stylingString = '';
        let ourDefaultColors = defaultColors;
        if (props.name === 'colorsDark') ourDefaultColors = defaultColorsDark;
        ourDefaultColors.map(color => {
            var darkColor = '';
            for (var i = 0; i < 6; i++) {
                darkColor += Math.floor(Math.random() * 10);
            }
            return (stylingString += color.split(':')[0] + ': #' + (props.name.includes('Dark') ? darkColor : Math.floor(Math.random()*16777215).toString(16))  + ';');
        });
        console.log(stylingString);
        setColors(stylingString);
    };

    const resetToDefaultData = () => {
        if (props.name.includes('logo')) setDataLogo('');
        else if (props.name.includes('colors')) getDefaultDataColor();
    };

    return (
        <>
            <div className="mfx-styling-view">
                <div id="mfx-generic-view-preview" className="mfx-gp-page">
                    <h1>{props.menuData && window.Dictionary.getTranslation(props.menuData.label)}</h1>
                    <form id="stylingsform" className="mfx-view " onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className={`mfx-gp-container ${props.name.includes('Dark') ? 'dark' : ''}`}>
                            {props.name.includes('colors') && dataColor && (
                                <>
                                    <div className="colorsgrid">
                                        {dataColor.map((key: any, idx: number) => {
                                            if (key[0] !== '') {
                                                return (
                                                    <div key={idx}>
                                                        <label>{key[0]}</label>
                                                        <div className="color-inputs">
                                                            <input
                                                                type="color"
                                                                name={key[0]}
                                                                value={key[1]}
                                                                className="colorpicker"
                                                                onChange={e => {
                                                                    let colors = [...dataColor];
                                                                    colors[idx] = [key[0], e.target.value];
                                                                    setDataColor(colors);
                                                                }}
                                                            />
                                                            <input
                                                                type="text"
                                                                name="color-text"
                                                                value={key[1]}
                                                                onChange={e => {
                                                                    let colors = [...dataColor];
                                                                    colors[idx] = [key[0], e.target.value];
                                                                    setDataColor(colors);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return <></>;
                                        })}
                                    </div>
                                </>
                            )}
                            {props.name.includes('logo') && (
                                <div className={'image-upload'}>
                                    {dataLogo && <img alt="" src={dataLogo} height="40px" width="auto" />}
                                    {!dataLogo && <div id="defaultlogo"></div>}
                                    <label htmlFor="file-upload" className="custom-file-upload mfx-button">
                                        <i className="fa fa-cloud-upload"></i> {window.Dictionary.getTranslation(15235)}
                                    </label>
                                    <input type="file" name="logo" id="file-upload" onChange={e => fileToDataUri(e)} />
                                </div>
                            )}
                        </div>
                        <div className="grid-common-buttons">
                            <Button onClickHandler={resetToDefaultData} iconClass="icon-check" dll={351} />
                            <Button onClickHandler={resetData} iconClass="icon-close" dll={1472} />
                            {props.name.includes('colors') && <Button onClickHandler={randomizeColors} iconClass="icon-close" dll={'Feeling Lucky'} />}
                            <button type="submit" className={'mfx-button mfx-button-type-icon save-button-designer important'}>
                                <i className="icon-save"></i>
                                <span>{window.Dictionary.getTranslation(63)}</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {saveMessage && <SavedModal saveMessage={saveMessage} closeIcon={true} handleClose={() => setSaveMessage(null)} />}
        </>
    );
}
