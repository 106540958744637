import menu from '../../../../Data/Designer/menu_main.json';
import { NavLink } from 'react-router-dom';
import Topheader from './Topheader';
import localStorageLink from '../../../../Helpers/LocalStorageLink';

export default function Header() {
    function removeActiveClass() {
        let listitems = document.querySelectorAll('.list-items');
        listitems.forEach(listitem => {
            listitem.classList.remove('active');
        });
    }
    return (
        <div id="header" className='designer'>
            <Topheader />
            <nav id="menu">
                <ul>
                    {menu.entries.map((menuitem, index) => (
                        <li key={`menu-item-${index}`} className={`list-items ${menuitem.pagename}`}>
                            
                            <div>{index === 0 && <i className='pagemenu-icon icon-pages'></i>}
                                {window.Dictionary ? window.Dictionary.getTranslation(menuitem.label) : menuitem.label}
                            </div>
                           
                            {menuitem.children && (
                                <ul className="sub-menu">
                                    {menuitem.children.map((submenuitem, idx) => {
                                        return (
                                            <li key={idx}>
                                                <NavLink to={localStorageLink(submenuitem.link)} onClick={removeActiveClass}>
                                                    {window.Dictionary ? window.Dictionary.getTranslation(submenuitem.label) : submenuitem.label}
                                                </NavLink>
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
}
