/**
 * Renders only the Button
 * @returns {JSX.Element} The basic Button.
 */

 const Button = (props) => {

    let buttonID = (!isNaN(props.designData.Name) ? window.Dictionary.getTranslation(props.designData.Name) : props.designData.Name);
    buttonID = 'button-' + buttonID.replace(/\s/g, '-').toLowerCase();

    let buttonType = 'mfx-button-type-text';
    
    if(props.designData.Icon && props.designData.Label)
        buttonType = 'mfx-button-type-icon-text' ;
    else if(props.designData.Icon)
        buttonType = 'mfx-button-type-icon';
    
     return(
         <button type='button' className={`mfx-button ${buttonType}`}  name={buttonID} id={buttonID}
            onClick={props.onClickHandler}>
            {props.designData.Icon && <i className={props.designData.Icon}></i>}
            {props.designData.Label &&
                <span className='button-text'>{!isNaN(props.designData.Label) ? window.Dictionary.getTranslation(props.designData.Label) : props.designData.Label}</span>
            }
        </button>
     );
 
 };
 
 export default Button;