/**
 * Renders only the LinkIcon
 * @returns {JSX.Element} The basic Icons.
 */

 const LinkIcon = (props) => {

     return(
         <a  className='linkicon button' target='_blank' href={`${props.designData.Type}:${props.designData.Link}`}>
             <i className={props.designData.Icon}></i>
        </a>
     );
 
 };
 
 export default LinkIcon;