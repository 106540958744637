/**
 * Renders an generic checkbox
 * @returns {JSX.Element} The basic checkbox.
 * @param {any} props given by caller
 */
import { useState , useEffect} from 'react';

const Checkbox = (props) => {
  let checkboxID = 'checkbox-' + props.designData.Key;
  const [inputChecked, setInputChecked] = useState(false);
  
  useEffect(() => {
    setInputChecked(props.designData.CheckedInDesigner);
  },[props.designData.CheckedInDesigner]);

  const checkedChangeHandler = (e) => {
    setInputChecked(!inputChecked);
    props.onChangeHandler({'key': props.designData.Key, 'newValue': e.target.checked, 'label' : props.designData.Label,'designData': props.designData});
  };

  return (
    <div className='mfx-checkbox-element'>
      {props.disableMode &&
        <>
          <input type="checkbox" id={checkboxID}
          name={checkboxID} 
          checked={false}/>
        
          <label htmlFor={checkboxID} >
            {(!isNaN(props.designData.Label) ? window.Dictionary.getTranslation(props.designData.Label) : props.designData.Label ?? '')}
          </label>
        </>
      }{!props.disableMode &&
        <>
          <input type="checkbox" id={checkboxID}
            name={checkboxID} 
            checked={inputChecked}
            onChange={(e) => checkedChangeHandler(e)} />
          
            <label htmlFor={checkboxID} >
            {(!isNaN(props.designData.Label) ? window.Dictionary.getTranslation(props.designData.Label) : props.designData.Label ?? '')}
            </label>
        </>
      }
    </div>
  );
};


export default Checkbox;