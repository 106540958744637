/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* IMPORT MACHEN!!!!!  */
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { viewActions } from '../../../../Helpers/Stores/viewStore';
import CreatableSelect from 'react-select/creatable';
import { RootState } from '../GenericViewEditor';
import SqlLibrary from '../../../../Data/Designer/dashboard_library.json';
import Info from 'Components/Lib/Elements/Info';

interface IGenericViewMenuProps {
    data: Object;
}

export default function DashboardMenu(props: IGenericViewMenuProps) {
    const dispatch = useDispatch();
    const [settingsSelected, setSettingsSelected] = useState({
        default: true,
    });

    const [addGraphIsLoading, setAddGraphIsLoading] = useState(false);
    const [curGraphId, setCurGraphId] = useState(0);
    let viewData: any = useSelector((state: RootState) => state.viewData);
    let graphData: any = useSelector((state: RootState) => state.viewData.DashboardElements);
    const handleCreate = (inputValue: string) => {
        setAddGraphIsLoading(true);
        dispatch(viewActions.addDashboard(inputValue));
        setCurGraphId(graphData.length);
        setAddGraphIsLoading(false);
    };
    function changeDashboard(newValue: any, target: any) {
        dispatch(viewActions.changeDashboard({ newValue: newValue, target: target, idx: curGraphId }));
    }
    function changeDashboardUpdateTimer(newValue: any) {
        dispatch(viewActions.changeDashboardUpdateTimer({ newValue: newValue }));
    }
    function removeDashboard() {
        dispatch(viewActions.removeDashboard(curGraphId));
        setCurGraphId(0);
    }

    function showSettings(type: string) {
        let newSettingsObject = { ...settingsSelected } as any;
        Object.keys(newSettingsObject).forEach(settings => {
            if (settings === type) newSettingsObject[type] = !newSettingsObject[type];
            else newSettingsObject[settings] = false;
        });
        setSettingsSelected(newSettingsObject);
    }
    return (
        <div id="mfx-generic-view-menu">
            {graphData && (
                <>
                    <div className="mfx-settings-accordion">
                        <h3 className={settingsSelected.default ? 'active' : ''} onClick={() => showSettings('default')}>
                            {window.Dictionary.getTranslation(14466)}
                        </h3>
                        {settingsSelected.default && (
                            <>
                                <label>{window.Dictionary.getTranslation(14889)}:</label>
                                <div className='mfx-react-select'>
                                    <CreatableSelect
                                        isDisabled={addGraphIsLoading}
                                        isLoading={addGraphIsLoading}
                                        onChange={(newValue: any) => setCurGraphId(newValue.value)}
                                        onCreateOption={handleCreate}
                                        options={graphData?.map((data: any, idx: any) => {
                                            return { label: data.Name, value: idx };
                                        })}
                                        value={{ label: graphData[curGraphId]?.Name, value: curGraphId }}
                                    />
                                </div>
                                <label> {window.Dictionary.getTranslation(632)}:</label>
                                <input type="text" value={graphData[curGraphId]?.Name} onChange={e => changeDashboard(e.target.value, 'Name')} />
                                <label> {window.Dictionary.getTranslation(14867)}:</label>
                                <input type="text" value={graphData[curGraphId]?.Headline} onChange={e => changeDashboard(e.target.value, 'Headline')} />
                                <label> {window.Dictionary.getTranslation(53)}:</label>
                                <select value={graphData[curGraphId]?.Type} onChange={e => changeDashboard(e.target.value, 'Type')}>
                                    <option value={'base'}>Base</option>
                                    <option value={'pie'}>Pie</option>
                                    <option value={'dataset'}>Dataset</option>
                                    <option value={'line'}>Line</option>
                                    <option value={'gauge'}>Gauge</option>
                                    <option value={'sqlTable'}>SQL Table</option>
                                    
                                </select>
                                <label> {window.Dictionary.getTranslation(16123)} (m):</label>
                                {graphData[0] && <input type="number" value={graphData[0]?.UpdateTime} onChange={e => changeDashboardUpdateTimer(e.target.value)} />}
                                {graphData[curGraphId]?.Type === 'base' && (
                                    <>
                                        <label> {window.Dictionary.getTranslation(15611)}:</label>
                                        <select value={graphData[curGraphId]?.GroupMode} onChange={e => changeDashboard(e.target.value, 'GroupMode')}>
                                            <option value={''}>{'Default (' + window.Dictionary.getTranslation(15610) + ')'}</option>
                                            <option value={'stacked'}>{window.Dictionary.getTranslation(15610)}</option>
                                            <option value={'grouped'}>{window.Dictionary.getTranslation(15609)}</option>
                                        </select>
                                        <label> {window.Dictionary.getTranslation(134)}:</label>
                                        <select value={graphData[curGraphId]?.Layout} onChange={e => changeDashboard(e.target.value, 'Layout')}>
                                            <option value={''}>{'Default (' + window.Dictionary.getTranslation(15612) + ')'}</option>
                                            <option value={'horizontal'}>{window.Dictionary.getTranslation(15612)}</option>
                                            <option value={'vertical'}>{window.Dictionary.getTranslation(15613)}</option>
                                        </select>
                                        <label> {window.Dictionary.getTranslation(15980)}:</label>
                                        <select value={graphData[curGraphId]?.Percent} onChange={e => changeDashboard(e.target.value, 'Percent')}>
                                            <option value={''}>{'Default (' + window.Dictionary.getTranslation(15983) + ')'}</option>
                                            <option value={'none'}>{window.Dictionary.getTranslation(5976)}</option>
                                            <option value={'first'}>{window.Dictionary.getTranslation(15981)}</option>
                                            <option value={'second'}>{window.Dictionary.getTranslation(15982)}</option>
                                        </select>
                                        <label> {window.Dictionary.getTranslation(15860)}: <Info text={window.Dictionary.getTranslation(15861)} /></label>
                                        <input type="number" value={graphData[curGraphId]?.TickCount} onChange={e => changeDashboard(e.target.value, 'TickCount')} />
                                    </>
                                )}
                                <label>{window.Dictionary.getTranslation(11538)}:</label>
                                <select className="margin-bottom" value={''} onChange={e => changeDashboard(e.target.value, 'SQL-Data')}>
                                    <option value="" disabled>
                                        SQL-Library
                                    </option>
                                    {SqlLibrary.map(d => (
                                        <option title={d.Description} value={d.SQL}>
                                            {d.DatabaseType}{d.Based ? '.'+d.Based: ''}: {d.Name}{' '}
                                        </option>
                                    ))}
                                </select>
                                <textarea value={graphData[curGraphId] ? graphData[curGraphId]['SQL-Data'] : ''} onChange={e => changeDashboard(e.target.value, 'SQL-Data')} />
                                <button onClick={e => removeDashboard()}>{window.Dictionary.getTranslation(14880)}</button>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
