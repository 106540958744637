import URLS from '../Components/Lib/Utils/URLs.API';

export function getPropertiesPerType(viewName) {
    switch (viewName) {
        case 'TasksView':
        case 'task' :
        case 'customervisit':
            return {
                actions: { add: true, tab: false, update : true, delete : true },
                addLabel: 15582,
                addView: 'TaskAddView',
                type: 'task',
                extraFilter: 'OnlyOwnTasks=true',
                createUrl: URLS.CreateTask,
                url: URLS.AllTasksData,
                customerFilter: 'Process.CustomerSupplierNumber',
                needCustomer: true,
                modalButtons: [/*{label: window.Dictionary.getTranslation(14378), url:'processes/detailview?type=task&', action: 'toCreatedData'},*/ 
                                {label: window.Dictionary.getTranslation(14369), action: 'toOverview'}],
                detailView:'TaskDetailView', 
                detailLabel: window.Dictionary.getTranslation(35) , 
                getData: URLS.GetTask, 
                updateData: URLS.UpdateTask, 
                deleteData: URLS.DeleteTask, 
            };
        case 'LeadTasksView':
        case 'lead':
            return {
                actions: { add: true, tab: false, update: true, delete: true},
                addLabel: 14177,
                addView: 'TaskAddView',
                type: 'task',
                extraFilter: 'OnlyOwnTasks=true',
                createUrl: URLS.CreateTask,
                url: URLS.AllTasksData,
                customerFilter: 'Process.CustomerSupplierNumber',
                needCustomer: false,
                modalButtons: [/*{label: window.Dictionary.getTranslation(14378), url:'processes/detailview?type=task&', action: 'toCreatedData'},*/ 
                                {label: window.Dictionary.getTranslation(14369), action: 'toOverview'}],
                detailView:'LeadDetailView', 
                detailLabel: 'Lead', 
                getData: URLS.GetLead, 
                updateData: URLS.UpdateLead,
                deleteData: URLS.DeleteLead,  
            };
        case 'CustomerVisitsView':
            return {
                actions: { add: false, tab: false },
                addLabel: 14177,
                addView: 'CustomerVisitsView',
                type: 'customervisit',
                extraFilter: 'TaskType=Visit',
                createUrl: URLS.CreateTask,
                url: URLS.AllTasksData,
                customerFilter: 'Process.CustomerSupplierNumber',
                needCustomer: true
            };
        case 'CustomerView':
            return {
                actions: { add: false, tab: true },
                addLabel: '',
                addView: '',
                type: 'customer',
                extraFilter: '',
                createUrl: 'TODO CREATECUSTOMER',
                url: URLS.AllCustomersData,
                customerFilter: '',
                needCustomer: true
            };
        case 'LeadsView':
            return {
                actions: { add: true, tab: false },
                addLabel: 14223,
                addView: 'LeadAddView',
                enableqrcode: true,
                qrPrefix: 'ContactPersonData-',
                type: 'lead',
                extraFilter: '',
                createUrl: URLS.CreateLead,
                url: URLS.AllLeadsData,
                customerFilter: '',
                needCustomer: false
            };
        case 'OfferView':
        case 'offer':
            return {
                actions: { add: false, tab: false, update: false, delete:false },
                addLabel: '',
                addView: '',
                type: 'offer',
                extraFilter: '',
                createUrl: 'TODO CREATEOFFER',
                url: URLS.AllOffersData,
                customerFilter: 'CustomerNumber',
                needCustomer: true,
                detailView:'OfferDetailView', 
                detailLabel: window.Dictionary.getTranslation(648), 
                getData: URLS.GetOffer
            };
        case 'OfferPositionsView':
            return {
                actions: { add: false, tab: false, update: false, delete:false },
                addLabel: '',
                addView: '',
                type: 'offer',
                extraFilter: '',
                createUrl: 'TODO CREATEOFFER',
                url: URLS.GetOfferOrderPositions,
                customerFilter: 'CustomerNumber',
                needCustomer: true,
                detailView:'OfferDetailView', 
                detailLabel: window.Dictionary.getTranslation(648), 
                getData: URLS.GetOfferOrderPositions
            };
        case 'ContactView':
        case 'contactperson':
            return {
                actions: { add: true, tab: false, update: true, delete: true},
                addLabel: 14303,
                addView: 'ContactPersonAddView',
                enableqrcode: true,
                qrPrefix: '',
                type: 'contactperson',
                extraFilter: '',
                createUrl: URLS.CreateContactPerson,
                url: URLS.AllContactPersonsData,
                customerFilter: 'CustomerNumber',
                needCustomer: true,
                detailView:'ContactPersonDetailView', 
                detailLabel: 'Ansprechpartner', 
                getData: URLS.GetContactPerson, 
                updateData: URLS.UpdateContactPerson, 
                deleteData: URLS.DeleteContactPerson
            };
        case 'NoteView':
        case 'note':
        return {
                actions: { add: true, tab: false },
                addLabel: 14355,
                addView: 'NoteAddView',
                type: 'note',
                extraFilter: '',
                createUrl: URLS.CreateNote,
                customerFilter: '',
                needCustomer: false,
                modalButtons : [{label: window.Dictionary.getTranslation(14369), url: '/processes/user'}]
            };
        case 'process':
            return {
                actions: {  update: true, add: false, tab: false },
                addLabel: 1398,
                addView: 'ProcessAddView',
                type: 'process',
                extraFilter: '',
                getData: URLS.GetProcess,
                createUrl: URLS.CreateProcess,
                updateData: URLS.UpdateProcess,
                detailView:'ProcessDetailView', 
                customerFilter: '',
                needCustomer: true,
            };
            case 'timerecording':
                return {
                    actions: {  update: false, add: false, tab: false },
                    type: 'timerecording',
                    extraFilter: '',
                    getData: URLS.GetProcess,
                    createUrl: URLS.CreateProcess,
                    updateData: URLS.UpdateProcess,
                    detailView:'TimeRecordingDetailView', 
                    customerFilter: '',
                    needCustomer: false,
                };
        default: return;            
    }
}

export function getContext(data, viewName) {
    switch (viewName) {
        case 'TasksView':
            return {};
        case 'CustomerView':
            return {
                customerName: data.RawAddress.Name1,
            };
        case 'LeadsView':
            return {};
        case 'OfferView':
            return {};
        case 'ContactView':
            return {};
        default:
            return {};
    }
}
//#endregion View Differentiator