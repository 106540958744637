/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 * Renders a CustomerDetailView.
 * @returns {JSX.Element} Rendered component
 */
import { useEffect, useState } from 'react';
import Loading from '../Elements/Loading';
import { ViewElement } from '../Common/Interfaces/IViewProperties';
import GenericElement from '../Utils/GenericElement';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';
import { useSearchParams } from 'react-router-dom';
import NewTab from '../Elements/NewTab';
import URLS from '../Utils/URLs.API';
import GoogleMaps from '../Elements/GoogleMaps';

function CustomerDetailView(props: any): JSX.Element {
    //true for debugging
    const [searchParams, setSearchParams] = useSearchParams();
    let customerId = searchParams.get('customer');

    const [loading, setLoading] = useState(true);
    const [disableMode /*, setDisableMode*/] = useState(true);
    const [customer, setCustomer] = useState(null as any);
    const [googleData, setGoogleData] = useState({} as any);
    const [radius, setRadius] = useState(100);

    useEffect(() => {
        LoadData();
    }, [radius]);

    function LoadData() {
        if (!customerId) customerId = props.id ? props.id : LocalStorage?.getTabData()?.primaryId;
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetCustomer + customerId).then((data: any) => {
            if (data.Meta.Success && data.Data) {
                setCustomer(data.Data);
                if (data.Data.Longitude && data.Data.Latitude) {
                    loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetSystemSettings).then((settings: any) => {
                        if (settings.Meta.Success && settings.Data.CRM.GoogleAPIKey) {
                            console.log(data.Data);
                            loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetGoogleMapsMarkers + '?CustomerNumber=' + data.Data.Number + '&Longitude=' + data.Data.Longitude + '&Latitude=' + data.Data.Latitude + '&Radius=' + radius).then((markData: any) => {
                                var markers = [
                                    {
                                        lng: data.Data.Longitude,
                                        lat: data.Data.Latitude,
                                        searchCode: data.Data.SearchCode,
                                        customerNumber: data.Data.Number,
                                    },
                                ];
                                markers = markers.concat(
                                    markData.Data.map((m: any) => {
                                        return { lng: m.Longitude, lat: m.Latitude, searchCode: m.SearchCode, customerNumber: m.CustomerNumber };
                                    })
                                );

                                console.log(markers);
                                setGoogleData({
                                    apiKey: settings.Data.CRM.GoogleAPIKey,
                                    markers: markers,
                                });
                            });
                        }
                    });
                }
            }
            setLoading(false);
        });
    }

    const addNewTab = (customer: any, index: number) => {
        let name = customer.RawAddress.Name1;
        LocalStorage.createTab(
            {
                primaryId: index,
                primaryType: 'customer',
                context: {
                    customerName: name,
                },
            },
            true,
            true,
            '/processes/customer',
            '/processes/customer'
        );
    };
    const getSearchResults = e => {
        if (e.key === 'Enter') {
            setRadius(e.target.value);
        }
    };
    return (
        <>
            {loading && <Loading title="Customer details" />}

            {!loading && customer && (
                <div className="mfx-view disabled-views">
                    <>
                        {((props.designer?.Elements ?? props.designer?.CustomElements) as any[]).map((e, idx) => {
                            return <GenericElement key={idx} designData={e as ViewElement} customData={customer} disableMode={disableMode} index={idx} />;
                        })}
                    </>
                    {searchParams.get('customer') && (
                        <div className="mfx-table-column">
                            <NewTab idx={customerId} type={'CUSTOMER'} data={customer} onAddNewTab={addNewTab} />
                        </div>
                    )}
                    {customer && (googleData as any).apiKey && (googleData as any).markers[0].lng && (googleData as any).markers[0].lat && (
                        <div style={{borderTop: '1px solid var(--borderColor)'}}>
                            <div className="mfx-input-element">
                                <label>Radius:</label>
                                <input defaultValue={radius} style={{width:'auto'}} type="number" onKeyUp={e => getSearchResults(e)}></input>
                            </div>
                            <GoogleMaps {...googleData} />
                        </div>
                    )}
                </div>
            )}
            {!loading && !customer && (
                <h4 className="nothing-found">
                    {window.Dictionary.getTranslation(14296)} <i className="icon-search"></i>
                </h4>
            )}
        </>
    );
}

export default CustomerDetailView;
