import { useEffect, useState } from 'react';

import Loading from 'Components/Lib/Elements/Loading';
import SmallHeadline from 'Components/Lib/Elements/SmallHeadline';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';
import URLS from '../Utils/URLs.API';
import sessionedLink from '../Utils/sessionedLinks';
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import { DefaultDateTimeRecognizer } from 'quicktype/dist/quicktype-core/DateTime';
import { setEmitFlags } from 'typescript';
import InputElement from '../Elements/InputElement';
import {AddViewProvider} from './AddView';
import Table from '../Elements/Table';
import NewOffer from './NewOffer';

export interface IDashboardProperties {
    Listtype: 'mytasks' | 'appointments';
    designer: any;
}

interface IListItems {
    Time?: string;
    ListItemName?: string;
    Action?: string;
    Tasknumber?: string | number;
    Taskdesc?: string;
}

/**
 * Renderes a List
 * @param {any} props Properties given by the caller
 * @returns {JSX.Element} Rendered Element.
 */
function ItemView(props: any): JSX.Element {
    const [loading, setLoading] = useState(true); // eslint-disable-line
   

    return (
        <>
        {props.LayoutName !== 'default' && 
            <>
                {/* Display ITEM??? */}
            </>
        } 
        {props.LayoutName === 'newoffer' && 
        
            <NewOffer designer={props.designer}/>
        
        } 
        
        </>
    );
}

export default ItemView;
