/*
* Storage for all Customers
* Customer List
*/

import { createSlice, configureStore } from '@reduxjs/toolkit';


const detailViewSlice = createSlice ( {
    name : 'view',
    initialState : {
        viewData  : {
            Data: {},
            DesignElements : {}
        },
        savedViewData: {
            Data: {},
            DesignElements : {}
        }
    },
    reducers: {
        init(state, action) { //sets initial Data
            state.viewData = action.payload;
            state.savedViewData = action.payload;
        },
        changeData(state, action) {
            
            if(action.payload.designData.Conditions && action.payload.designData.Conditions[0] && action.payload.designData.Conditions[0].type === 'alternative') {
                console.log(action.payload.designData.Conditions[0].value);
                state.viewData.Data[action.payload.designData.Conditions[0].value] = {value: '', label: ''};
            }
            
            state.viewData.Data[action.payload.designData.Key] = action.payload.newValue;
        },
        saveData(state) {
            state.savedViewData = state.viewData;
        },
        resetData(state) {
            state.viewData = state.savedViewData;
        },
    }
});

const store = configureStore({
    reducer: detailViewSlice.reducer
});
export const detailViewActions = detailViewSlice.actions;
export default store;