import { useState } from 'react';
import LocalStorage from '../../../../Helpers/MFXLocalStorage';
import Logo from '../../../../Styles/images/logo.png';
import {deleteCookie} from '../../../../Helpers/MFXCookie';
import { Link } from 'react-router-dom';

const Topheader = () => {
    const [submenuOpen, setSubmenuOpen] = useState(false);


    return (
        <div id="mfx-topheader">
            <div id='mfx-logocontainer'>
                <img src={Logo} id='logo' alt='Logo' />
                <h4>CRM DESIGNER</h4>
            </div>
            <div id='mfx-infocontainer'>
                <nav id='user'>
                    <ul className='menu' >
                        <li>
                            <i className='icon-user' onClick={() => setSubmenuOpen(!submenuOpen)}></i>
                            <ul className={`sub-menu ${(submenuOpen) ? 'open' : ''}`}>
                                {/*set correctDatabase + Storage <li>
                                    <Link to={LocalStorage.getDBName() + '/processes/dashboard'}>CRM</Link>
                                </li>*/}
                                <li><button className='no-styling' onClick={() => {
                                    LocalStorage.removeAllLocals();
                                    deleteCookie('mfx_Login');
                                    document.location.href = '/login' ;
                                }}>Logout</button></li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        
    );
};


export default Topheader;
