/**
 * Renders an generic text input field
 * @returns {JSX.Element} The basic text input.
 * @param {any} props given by caller
 */


export const InputElement = (props) => {
  let inputID = 'input-' + props.designData.Key + props.uniqueIndex;
  let readonly = props.designData.EditableStatus === 'never' || props.designData.EditableStatus === 'onlyNew' || props.designData.ReadOnly? true : false; 

  const inputOnchangeHandler = (event) => {
      props.onChangeHandler({newValue: event.target.value, type: props.uniqueIndex, designData: props.designData, key: props.designData.Key});
  };

  const showPwd = (event) => {
    let curElement = event.target;

    //if clicked on Icon
    if(event.target.tagName.toLowerCase() === 'i') curElement = event.target.parentNode;
    
    if(curElement.previousElementSibling.type === 'password')
      curElement.previousElementSibling.type='text';
    else 
      curElement.previousElementSibling.type='password';
  };

  return (
    <div className={`mfx-input-element mfx-input-${props.designData.Type} readonly-${props.designData.ReadOnly}`}>
      {!props.noLabel && props.designData.Label && 
        <label htmlFor={inputID}>
          {!isNaN(props.designData.Label) ? window.Dictionary.getTranslation(props.designData.Label) : props.designData.Label}
          {props.designData.Required && '*'}
        </label>
      }
      
      <input 
        style={props.disableMode ? {pointerEvents: 'none'} : {}}
        id={inputID}
        type={props.designData.Type} 
        value={props.designData.DefaultValue}
        placeholder={props.designData.Placeholder} 
        name={inputID} 
        disabled={Boolean(props.disableMode)}
        min={props.designData.Min}
        max={props.designData.Max}
        readOnly={readonly}
        step={props.designData.Step}
        data-type={props.designData.DataType}
        required={props.designData.Required}
        onChange={inputOnchangeHandler}
      />
      
      
      {props.designData.Type === 'tel' &&
        <i className='fas fa-phone'></i>
      }
      {props.designData.Type === 'email' &&
        <i className='fas fa-envelope'></i>
      }
      {props.designData.Type === 'date' &&
        <i className='fas fa-calendar'></i>
      }
      {props.designData.Type === 'currency' &&
        <i className='fas fa-euro-sign'></i>
      }
      {props.designData.Type === 'password' &&
          <button type='button' className='no-styling' onMouseDown={showPwd}><i className='fas fa-eye' ></i></button>
      }
      {props.designData.Elementtype === 'currency' &&
          <button type='button' className='no-styling' onMouseDown={showPwd}><i className='fas fa-euro-sign' ></i></button>
      }
      {/*props.designData.Type === 'range' &&
          <output>{ inputValue }</output>*/
      }

    </div>
  );
};


export default InputElement;