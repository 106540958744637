
/**
 * Renders a Wrapper fpr a PopUp.
 * @returns {JSX.Element} Rendered component
 */

export default function ModalOverlay(props:any) { 
    return (
        <div className={`mfx-modal ${props?.loading}`}>
            {props.children}
        </div>
    );
}