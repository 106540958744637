/**
 * Renders an generic dropdown
 * @returns {JSX.Element} The basic dropdown.
 * @param {any} props given by caller
 */

import { useState, useEffect } from 'react';
import loadJsonData from '../../Utils/jsonGet';
import LocalStorage from '../../../../Helpers/MFXLocalStorage';
import URLS from '../../Utils/URLs.API';

const Dropdown = props => {
    const [dropdownOptions, setDropdownOptions] = useState(props.designData?.Options || null);

    let dropdownID = !isNaN(props.designData.Label) ? window.Dictionary.getTranslation(props.designData.Label) : props.designData.Label;
    dropdownID = 'dropdown-' + dropdownID?.replace(/\s/g, '-').toLowerCase();

    const handleOnChange = e => {
        if (props.onChangeHandler) props.onChangeHandler(e.target.value);
    };

    useEffect(() => {
        if (props.getDropdownValues) {
            let dropdownKey = '';
            if (props.designData.DropdownName) dropdownKey = props.designData.DropdownName;
            else {
                dropdownKey = props.designData.Key.split('-');
                dropdownKey = dropdownKey[dropdownKey.length - 1];
            }

            if (dropdownKey !== 'ContactPerson') {
                loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetDropdownValues + dropdownKey).then(data => {
                    if (data.Meta.Success && data.Data) {
                        let options = [];
                        data.Data.map(option => {
                            options.push({ Name: option.Name, Value: option.Value });
                        });

                        setDropdownOptions(options);
                    } else {
                        window.logger.error('No DropdownData ' + dropdownKey); // ERROR MESSAGE
                    }
                });
            }
        }
    }, [props.designData.Name]);

    return (
        <div className="mfx-dropdown-element">
            {!props.noLabel && props.designData.Label && (
                <label htmlFor={dropdownID}>
                    {!isNaN(props.designData.Label) ? window.Dictionary.getTranslation(props.designData.Label) : props.designData.Label}
                    {props.designData.Required && '*'}
                </label>
            )}
            <select id={dropdownID} name={dropdownID} /*defaultValue={props.designData.Value}*/ disabled={props.getDropdownValues ? false : Boolean(props.disableMode)} onChange={handleOnChange}>
                {dropdownOptions && (
                    <>
                        <option key={''} value={''}>
                            {''}
                        </option>
                        {dropdownOptions.map(option => {
                            if (option === props.customData || option.Value == props.designData.DefaultValue)
                                return (
                                    <option key={option} value={option.Value} selected>
                                        {option.Name}
                                    </option>
                                );
                            else
                                return (
                                    <option key={option} value={option.Value}>
                                        {option.Name}
                                    </option>
                                );
                        })}
                    </>
                )}
            </select>
        </div>
    );
};

export default Dropdown;
