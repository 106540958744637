/**
 * Renders a CustomerDetailView.
 * @returns {JSX.Element} Rendered component
 */
import { useEffect, useState } from 'react';
import Loading from '../Elements/Loading';
import { QRCodeSVG } from 'qrcode.react';
import loadJsonData from '../Utils/loadJsonData';
import URLS from '../Utils/URLs.API';
import LocalStorage from 'Helpers/MFXLocalStorage';

function UserView(props: any): JSX.Element {
    //true for debugging
    const [vsCardData, setVsCardData] = useState(null as any);
    const [curEmployee, setCurEmployee] = useState(null as any);
    const [curOrginasation, setCurOrganisation] = useState(null as any);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let vsCard = 'BEGIN:VCARD\nVERSION:3.0\n';

        //loadJsonData('/' + LocalStorage.getConnectionKey() + URLS.GetEmployeeInfo).then(data => {
        //if (data.Meta.Success && (data as any).Data) {
        let employee = LocalStorage.getData('UserData');

        if (employee.Lastname) vsCard += 'N:' + employee.Lastname.replace(/\s/g, '') + ';' + employee.Firstname + '\n';
        if (employee.Phonenumber) vsCard += 'TEL:' + employee.Phonenumber.replace(/\s/g, '') + '\n';
        if (employee.MobilePhoneNumber) vsCard += 'TEL;CELL:' + employee.MobilePhoneNumber.replace(/\s/g, '') + '\n';
        if (employee.Email) vsCard += 'MAIL;WORK;INTERNET:' + employee.Email + '\n';

        setCurEmployee(employee);

        loadJsonData('/' + LocalStorage.getConnectionKey() + URLS.GetOrganisationalInfo).then(data => {
            if (data.Meta.Success && (data as any).Data) {
                let orginasation = (data as any).Data as any;

                /*if (orginasation.Lastname) vsCard += 'N:' + orginasation.Lastname.replace(/\s/g, '') + ';' + orginasation.Firstname + '\n';
                    if (orginasation.Phonenumber) vsCard += 'TEL:' + orginasation.Phonenumber.replace(/\s/g, '') + '\n';
                    if (orginasation.Email) vsCard += 'MAIL;WORK;INTERNET:' + orginasation.Email + '\n';*/
                if (orginasation.Street) vsCard += 'ADR:;;' + orginasation.Street + ';' + (orginasation.City ?? orginasation.City) + ';;' + (orginasation.PostalCode ?? orginasation.PostalCode) + ';' + (orginasation.CountryDescription ?? orginasation.CountryDescription) + '\n';

                setCurOrganisation(orginasation);
            }
            vsCard += 'END:VCARD';

            setVsCardData(vsCard);
            setLoading(false);
        });

    }, []);

    return (
        <>
            {loading && <Loading title="UserView" />}

            {!loading && (
                <div className="mfx-view">
                    <div className="mfx-grid-container">
                        <div className="mfx-col-12 mfx-qr-code-container">
                            <div className="mfx-qr-code">
                                <QRCodeSVG value={vsCardData} size={200} />
                            </div>

                            {(curOrginasation || curEmployee) && (
                                <div className="mfx-text-element">
                                    <h3>
                                        {curEmployee?.Title} {curEmployee?.Lastname} {curEmployee?.Firstname}
                                    </h3>
                                    {curEmployee?.MobilePhoneNumber && <p>{curEmployee?.MobilePhoneNumber}</p>}
                                    {curEmployee?.Phonenumber && <p>{curEmployee?.Phonenumber}</p>}
                                    <p>{curEmployee?.Email}</p>
                                    {curOrginasation?.Street && curOrginasation?.PostalCode && curOrginasation?.City && <p>{curOrginasation?.Street + ', ' + curOrginasation?.PostalCode + ' ' + curOrginasation?.City}</p>}
                                    <p>{curOrginasation?.CountryDescription}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {loading && (
                <h4 className="nothing-found">
                    {window.Dictionary.getTranslation(14296)} <i className="icon-search"></i>
                </h4>
            )}
        </>
    );
}

export default UserView;
