import SavedModal from '../Elements/SavedModal';
import Button from '../Elements/Button';
import { useEffect, useState } from 'react';
import loadJsonData from 'Components/Lib/Utils/loadJsonData';
import URLS from 'Components/Lib/Utils/URLs.API';
import LocalStorage from 'Helpers/MFXLocalStorage';
import jsonPost from 'Components/Lib/Utils/jsonPost';
import jsonDelete from 'Components/Lib/Utils/jsonDelete';
import loadJsonPost from 'Components/Lib/Utils/jsonPost';
import jsonPut from 'Components/Lib/Utils/jsonPut';
import ConfirmPopup from 'Components/Lib/Elements/ConfirmPopup';
import CreatableSelect from 'react-select/creatable';

export default function Settingsview(props: any) {
    const [saveMessage, setSaveMessage] = useState(null as any);
    const [users, setUsers] = useState(null as any);
    const [userGroups, setUserGroups] = useState(null as any);
    const [newGroupName, setNewGroupName] = useState(null as any);
    const [missingGroupName, setMissingGroupName] = useState(false);

    const [confirmOpen, setConfirmOpen] = useState(false);

    const [search, setSearch] = useState('');
    const [curUser, setCurUser] = useState(null as any);

    useEffect(() => {
        getUsers();
        getUserGroups();
    }, [props.name]);

    function getUsers() {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetUsers)
            .then((data: any) => {
                setUsers(
                    data.Data.map(d => {
                        return { ...d, OtherUserGroupIDs: d.OtherUserGroupIDs?.split(',').map(d => (!isNaN(d) ? +d : d)), NewOtherUserGroupIDs: d.OtherUserGroupIDs?.split(',').map(d => (!isNaN(d) ? +d : d)) };
                    })
                );
            })
            .catch(e => {
                console.log('No Users found!');
            });
    }
    function getUserGroups() {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetUserGroups)
            .then((data: any) => {
                setUserGroups(data.Data);
            })
            .catch(e => {
                console.log('No Usergroups found!');
            });
    }

    //#region Users
    const saveUser = () => {
        //deleteUser from curGroup
        console.log(curUser);
        if (curUser.UserGroupID != null && curUser.NewGroupID) {
            jsonDelete('/' + LocalStorage?.getConnectionKey() + URLS.RemoveUserFromGroup + '?UserGroupID=' + curUser.UserGroupID + '&Username=' + curUser.Username + '&IsMainGroup=true')
                .then((data: any) => {
                    if (curUser.NewGroupID >= 0) saveUsertoGroup();
                    else {
                        saveOtherUserGroups();
                    }
                })
                .catch(e => {
                    console.log('Could not delete the user from the group!');
                });
        } else if (curUser.NewGroupID) {
            saveUsertoGroup();
        } else {
            saveOtherUserGroups();
        }
    };

    function saveOtherUserGroups() {
        if (JSON.stringify(curUser.OtherUserGroupIDs) !== JSON.stringify(curUser.NewOtherUserGroupIDs)) {
            let removeArray = curUser.OtherUserGroupIDs?.filter(x => !curUser.NewOtherUserGroupIDs?.includes(x));
            let addArray = curUser.NewOtherUserGroupIDs?.filter(x => !curUser.OtherUserGroupIDs?.includes(x));
            console.log(removeArray);
            console.log(addArray);
            removeArray?.forEach(groupID => {
                jsonDelete('/' + LocalStorage?.getConnectionKey() + URLS.RemoveUserFromGroup + '?UserGroupID=' + groupID + '&Username=' + curUser.Username + '&IsMainGroup=false')
                    .then((data: any) => {
                        setCurUser(null);
                        getUsers();
                    })
                    .catch(e => {
                        console.log('Could not delete the user from the group!');
                    });
            });
            addArray?.forEach(groupID => {
                jsonPost('/' + LocalStorage?.getConnectionKey() + URLS.AddUserToGroup, { CreateData: { UserGroupID: groupID, Username: curUser.Username, ProgramType: 'TCMobile' } })
                    .then((data: any) => {
                        setCurUser(null);
                        getUsers();
                    })
                    .catch(e => {
                        console.log('Could not save the user from the group!');
                    });
            });
        } else {
            setCurUser(null);
            getUsers();
        }
    }

    const saveUsertoGroup = () => {
        console.log('S');
        jsonPost('/' + LocalStorage?.getConnectionKey() + URLS.AddUserToGroup, { CreateData: { UserGroupID: curUser.NewGroupID, Username: curUser.Username, ProgramType: 'TCMobile', IsMainGroup: 1 } })
            .then((data: any) => {
                saveOtherUserGroups();
            })
            .catch(e => {
                console.log('Could not save the user from the group!');
            });
    };
    //#endregion Users

    //#region  UserGroups
    function removeUserGroup(userGroup) {
        jsonDelete('/' + LocalStorage?.getConnectionKey() + URLS.DeleteUserGroup + '?Index=' + userGroup.UserGroupID)
            .then((data: any) => {
                getUserGroups();
            })
            .catch(e => {
                console.log('Could not delete the usergroup!');
            });
    }

    function addUserGroup() {
        if (!newGroupName) {
            setMissingGroupName(true);
            return;
        }
        setMissingGroupName(false);
        jsonPost('/' + LocalStorage?.getConnectionKey() + URLS.CreateUserGroup, { CreateData: { Name: newGroupName, ExistsProfileAssignment: false, ProgramType: 1 } })
            .then((data: any) => {
                setNewGroupName('');
                getUserGroups();
            })
            .catch(e => {
                console.log('Could not create the usergroup!');
            });
    }
    //#endregion  UserGroups

    //#region JSON Import/Export
    var allViewData: any = [];
    var allPageData: any = [];

    var waitingView = 0;
    var waitingPage = 0;

    function getAllViews() {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetAllBusinessProcessViewNamesAndLayoutNames)
            .then((data: any) => {
                waitingView = data.Data.length;
                data.Data.forEach((d: any) => {
                    loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetViewLayouts + d.ViewName + '&LayoutName=' + d.LayoutName)
                        .then((view: any) => {
                            if (view.Data) {
                                allViewData.push({ ConfigData: view.Data.Json ? JSON.stringify(view.Data.Json) : '', LayoutName: view.Data.LayoutName, ViewName: view.Data.ViewName, ViewLabel: view.Data.ViewLabel, ViewType: view.Data.ViewType });
                                waitingView--;
                            } else {
                                console.log('Empty View');
                                console.log(d);
                                waitingView--;
                            }
                            if (waitingView === 0) {
                                exportJson(allViewData, 'ViewExport-' + LocalStorage?.getDBName() + '.json');
                            }
                        })
                        .catch(e => {
                            console.log('View Error');
                            console.log(d);
                            waitingView--;
                        });
                });
            })
            .catch(e => {
                console.log('No views found!');
            });
    }
    function getAllPages() {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetPageLayoutsAndLayoutNames)
            .then((data: any) => {
                waitingPage = data.Data.length;
                data.Data.forEach((d: any) => {
                    //if (d.PageName) {
                    loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.PageLayouts + '?PageLayout=' + d.PageLayout + '&PageName=' + d.PageName)
                        .then((page: any) => {
                            if (page.Data) {
                                allPageData.push({ ConfigData: page.Data.Json ? JSON.stringify(page.Data.Json) : '', PageLayout: page.Data.PageLayout, PageName: page.Data.PageName, BusinessProcessName: page.Data.BusinessProcessName, Type: page.Data.Type });
                                waitingPage--;
                            } else {
                                console.log('Empty Page');
                                console.log(d);
                                waitingPage--;
                            }
                            if (waitingPage === 0) {
                                exportJson(allPageData, 'PageExport-' + LocalStorage?.getDBName() + '.json');
                            }
                        })
                        .catch(e => {
                            console.log('Page Error');
                            console.log(d);
                            waitingPage--;
                        });
                    /*} else {
                        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetBusinessProcess + 'CRM&PageLayout=' + d.PageLayout)
                            .then((page: any) => {
                                console.log(page);
                                if (page.Data) {
                                    allPageData.push({ ConfigData: page.Data.Json ? JSON.stringify(page.Data.Json) : '', PageLayout: page.Data.PageLayout, PageName: page.Data.PageName, BusinessProcessName: page.Data.BusinessProcessName, Type: page.Data.Type });
                                    waitingPage--;
                                } else {
                                    console.log('Empty Page');
                                    console.log(d);
                                    waitingPage--;
                                }
                                if (waitingPage === 0) {
                                    exportJson(allPageData, 'PageExport-' + LocalStorage?.getDBName() + '.json');
                                }
                            })
                            .catch(e => {
                                console.log('Page Error');
                                console.log(d);
                                waitingPage--;
                            });
                    }*/
                });
            })
            .catch(e => {
                console.log('No pages found!');
            });
    }

    function exportJson(data, name) {
        const element = document.createElement('a');
        const str = JSON.stringify(data);
        const bytes = new TextEncoder().encode(str);
        const blob = new Blob([bytes], {
            type: 'application/json;charset=utf-8',
        });
        element.href = URL.createObjectURL(blob);
        element.download = name;
        document.body.appendChild(element);
        element.click();
    }

    function importJson(data, type) {
        setUploadResult({ added: 0, updated: 0, failure: 0 });
        data.forEach((d: any) => {
            if (type === 'view')
                loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetViewLayouts + d.ViewName + '&LayoutName=' + d.LayoutName)
                    .then((existing: any) => {
                        console.log(existing);
                        if (existing.Data && existing.Data.LayoutName === d.LayoutName) uploadData({ ...d, ID: existing.Data.ID }, type);
                        else uploadData(d, type);
                    })
                    .catch(e => {
                        console.log('ERROR:');
                        console.log(e);
                    });
            if (type === 'page')
                loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.PageLayouts + '?PageName=' + d.PageName + '&PageLayout=' + d.PageLayout)
                    .then((existing: any) => {
                        if (existing.Data && existing.Data.PageLayout === d.PageLayout) uploadData({ ...d, ID: existing.Data.ID }, type);
                        else uploadData(d, type);
                    })
                    .catch(e => {
                        console.log('ERROR:');
                        console.log(e);
                    });
            //uploadData(d, type);
        });
    }

    const [uploadResult, setUploadResult] = useState({ updated: 0, added: 0, failure: 0 });

    function uploadData(data, type) {
        if (data.ID)
            jsonPut('/' + LocalStorage?.getConnectionKey() + (type === 'view' ? URLS.PutBusinessProcessView : URLS.PutBusinessProcessPage), { UpdateData: { ...data } })
                .then(d => {
                    if (d.Meta.Success) {
                        setUploadResult((prev: any) => {
                            return { ...prev, updated: prev.updated + 1 };
                        });
                    } else {
                        console.log('Could not upload the data!');
                        console.log(data);
                        setUploadResult((prev: any) => {
                            return { ...prev, failure: prev.failure + 1 };
                        });
                    }
                })
                .catch(e => {
                    setUploadResult((prev: any) => {
                        return { ...prev, failure: prev.failure + 1 };
                    });
                    console.log('Could not upload the data! PUT');
                    console.log(data);
                    console.log(e);
                });
        else
            loadJsonPost('/' + LocalStorage?.getConnectionKey() + (type === 'view' ? URLS.PostBusinessProcessView : URLS.PostBusinessProcessPage), { CreateData: { ...data } })
                .then(d => {
                    if (d.Meta.Success) {
                        setUploadResult((prev: any) => {
                            return { ...prev, added: prev.added + 1 };
                        });
                    } else {
                        console.log('Could not upload the data! POST');
                        console.log(data);
                        setUploadResult((prev: any) => {
                            return { ...prev, failure: prev.failure + 1 };
                        });
                    }
                })
                .catch(e => {
                    setUploadResult((prev: any) => {
                        return { ...prev, failure: prev.failure + 1 };
                    });
                    console.log('Could not upload the data!');
                    console.log(data);
                    console.log(e);
                });
    }

    const [fileData, setFileData] = useState({ data: null as any, type: null as any });

    function handleChange(e: any, type) {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0]);
        fileReader.onload = e => {
            setFileData({ data: JSON.parse((e?.target?.result as string) ?? ''), type: type });
            setConfirmOpen(true);
            console.log(JSON.parse(e?.target?.result as string));
        };
    }

    //#endregion JSON Import/Export

    useEffect(() => {
        console.log(curUser);
    }, [curUser]);

    return (
        <>
            <div className="mfx-styling-view">
                <div id="mfx-generic-view-preview" className="mfx-gp-page">
                    <h1>{props.menuData && window.Dictionary.getTranslation(props.menuData.label)}</h1>
                    {props.name === 'usersettings' && (
                        <div className="designer-usertable">
                            <input type="text" placeholder={window.Dictionary.getTranslation(162)} onKeyUp={e => setSearch((e.target as any).value)} />
                            <div className="mfx-view mfx-table-container">
                                <table className="mfx-table">
                                    <tr className="mfx-table-row mfx-table-head">
                                        <td>{window.Dictionary.getTranslation(632)}</td>
                                        <td>{window.Dictionary.getTranslation(277)}</td>
                                        <td>{window.Dictionary.getTranslation(12587)}</td>
                                        <td>{window.Dictionary.getTranslation(63)}</td>
                                    </tr>
                                    {users &&
                                        users
                                            .filter(user => {
                                                if (search) return user.Username.toLowerCase().includes(search.toLowerCase());
                                                else return true;
                                            })
                                            .map((user, idx) => {
                                                //if (user.Username === 'SYSADM') console.log(user);
                                                return (
                                                    <tr
                                                        key={idx}
                                                        onClick={() => {
                                                            if (!curUser || user.Username !== curUser.Username) setCurUser(user);
                                                        }}
                                                        className={curUser && user.Username === curUser.Username ? 'designer-curuser' : ''}>
                                                        <td>
                                                            <h4>{user.Username}</h4>
                                                        </td>
                                                        <td>
                                                            <select
                                                                value={curUser && user.Username === curUser.Username ? curUser.NewGroupID : (user as any).UserGroupID || -1}
                                                                onChange={e => {
                                                                    let index = curUser.NewOtherUserGroupIDs?.indexOf(+e.target.value);
                                                                    if (index >= 0) {
                                                                        var arrCopy = JSON.parse(JSON.stringify(curUser.NewOtherUserGroupIDs));
                                                                        arrCopy.splice(index, 1);
                                                                        setCurUser({ ...curUser, NewGroupID: +e.target.value, NewOtherUserGroupIDs: arrCopy, index: idx });
                                                                    } else setCurUser({ ...curUser, NewGroupID: +e.target.value, index: idx });
                                                                }}
                                                                disabled={curUser && user.Username === curUser.Username ? false : true}>
                                                                <option value={-1}>-</option>
                                                                {userGroups &&
                                                                    userGroups.map((userGroup, index) => {
                                                                        return (
                                                                            <option key={index} value={userGroup.UserGroupID}>
                                                                                {userGroup.Name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            {userGroups && (
                                                                <div className='mfx-react-select'>
                                                                    <CreatableSelect
                                                                        onChange={(newValue: any) => {
                                                                            var val = newValue.map(v => v.value);
                                                                            setCurUser({ ...curUser, NewOtherUserGroupIDs: val, index: idx });
                                                                        }}
                                                                        isDisabled={curUser && user.Username === curUser.Username ? false : true}
                                                                        isMulti
                                                                        options={userGroups
                                                                            ?.filter((group: any) => {
                                                                                return (curUser?.NewGroupID ?? curUser?.UserGroupID) !== group.UserGroupID;
                                                                            })
                                                                            .map((group: any) => {
                                                                                return { label: group.Name, value: group.UserGroupID };
                                                                            })}
                                                                        menuPosition="fixed"
                                                                        value={
                                                                            curUser && user.Username === curUser.Username && curUser.NewOtherUserGroupIDs
                                                                                ? curUser.NewOtherUserGroupIDs?.map((group: any) => {
                                                                                      return { label: userGroups?.find(g => g.UserGroupID === group)?.Name, value: group };
                                                                                  })
                                                                                : (user as any).OtherUserGroupIDs?.map((group: any) => {
                                                                                      return { label: userGroups?.find(g => g.UserGroupID === group)?.Name, value: group };
                                                                                  }) || null
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>{curUser && user.Username === curUser.Username && <button onClick={saveUser}>{window.Dictionary.getTranslation(63)}</button>}</td>
                                                    </tr>
                                                );
                                            })}
                                </table>
                            </div>
                        </div>
                    )}
                    {props.name === 'usergroupsettings' && (
                        <div className="flex-display">
                            <div className="designer-usertable half-width">
                                <input type="text" placeholder={window.Dictionary.getTranslation(162)} onKeyUp={e => setSearch((e.target as any).value)} />
                                <div className="mfx-view mfx-table-container">
                                    <table className="mfx-table">
                                        <tr className="mfx-table-row mfx-table-head">
                                            <td>{window.Dictionary.getTranslation(7713)}</td>
                                            <td>{window.Dictionary.getTranslation(632)}</td>
                                            <td>{window.Dictionary.getTranslation(6824)}</td>
                                        </tr>
                                        {userGroups &&
                                            userGroups
                                                .filter(usergroup => {
                                                    if (search) return usergroup.Name.toLowerCase().includes(search.toLowerCase()) || usergroup.UserGroupID.toString().toLowerCase().includes(search.toLowerCase());
                                                    else return true;
                                                })
                                                .map((userGroup, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            {' '}
                                                            <td>
                                                                <h4>{userGroup.UserGroupID}</h4>
                                                            </td>
                                                            <td>
                                                                <h4>{userGroup.Name}</h4>
                                                            </td>
                                                            <td>
                                                                <button onClick={e => removeUserGroup(userGroup)}>{window.Dictionary.getTranslation(6824)}</button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                    </table>
                                </div>
                            </div>
                            <div className="designer-usertable half-width small-margin-left">
                                <h1>{window.Dictionary.getTranslation(15215)}</h1>
                                <input style={{ borderColor: missingGroupName ? 'red' : '' }} value={newGroupName} type="text" placeholder={window.Dictionary.getTranslation(15409)} onChange={e => setNewGroupName(e.target.value)}></input>
                                <button onClick={addUserGroup}>{window.Dictionary.getTranslation(15215)}</button>
                            </div>
                        </div>
                    )}
                    {props.name === 'importexport' && (
                        <div className="flex-display">
                            {confirmOpen && (
                                <ConfirmPopup
                                    confirm={() => {
                                        importJson(fileData.data, fileData.type);
                                        setConfirmOpen(false);
                                    }}
                                    stop={() => setConfirmOpen(false)}>
                                    {window.Dictionary.getTranslation(15868)} {'\n'}
                                    Type: {fileData.type} {'\n'}
                                    Count: {fileData.data.length}
                                </ConfirmPopup>
                            )}
                            <div className="designer-usertable width100">
                                <button onClick={getAllViews}>Download Views as Json</button>
                                <button onClick={getAllPages}>Download Pages as Json</button>
                                <br />
                                <h1>Upload View Json file</h1>
                                <input type="file" onChange={e => handleChange(e, 'view')} />
                                <br />
                                <h1>Upload Page Json file</h1>
                                <input type="file" onChange={e => handleChange(e, 'page')} />
                                {(uploadResult.updated !== 0 || uploadResult.added !== 0 || uploadResult.failure !== 0) && (
                                    <h3>
                                        Updated: {uploadResult.updated}, Added: {uploadResult.added} Failure: {uploadResult.failure}
                                    </h3>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {saveMessage && <SavedModal saveMessage={saveMessage} closeIcon={true} handleClose={() => setSaveMessage(null)} />}
        </>
    );
}
