/**
 * Renders only the Editbutton
 * @returns {JSX.Element} The basic Editbutton.
 */

 const Addbutton = (props) => {

     return(
        <>
            {!props.disableMode &&
                <button type="button" className="mfx-button mfx-button-type-icon" onClick={props.disableChangeHandler}>
                    <i className="icon-add"></i>
                </button>
            }
            {props.disableMode &&
                <>
                <button type="button" className="mfx-button mfx-button-type-icon" onClick={props.disableChangeHandler} >
                    <i className="icon-close"></i>
                </button>

                {props.save && 
                    <button type="submit" className="mfx-button mfx-button-type-icon" >
                        <i className="icon-save"></i>
                    </button>
                }
                </>
            }
        </>
     );
 
 };
 
 export default Addbutton;