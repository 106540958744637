import { useEffect, useState } from 'react';

import Loading from 'Components/Lib/Elements/Loading';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';
import URLS from '../Utils/URLs.API';
import { useSearchParams } from 'react-router-dom';
import Table from '../Elements/Table';
import NewOffer from './NewOffer';

export interface IDashboardProperties {
    Listtype: 'mytasks' | 'appointments';
    designer: any;
}

interface IListItems {
    Time?: string;
    ListItemName?: string;
    Action?: string;
    Tasknumber?: string | number;
    Taskdesc?: string;
}

/**
 * Renderes a List
 * @param {any} props Properties given by the caller
 * @returns {JSX.Element} Rendered Element.
 */
function OfferPositionsView(props: any): JSX.Element {
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [curOffer, setCurOffer] = useState(null as any);

    //getParams
    const [searchParams, setSearchParams] = useSearchParams();
    let offerId = searchParams.get('index');
    let customerId = searchParams.get('customer');

    useEffect(() => {
      setLoading(true);

      if(offerId) {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetOfferOrderPositions + '?HideAssemblyParts=true&OfferOrderIndex=' + offerId).then(data => {
            if (data.Meta.Success && data.Data) {
                setCurOffer(data.Data);
                setLoading(false);
            }
        });
    }else {
        setLoading(false);
    }
    },[setLoading]);

    return (
        <>
        {props.LayoutName !== 'newoffer' && 
            <>
                <div className="mfx-view">
                    {loading && <Loading />}
                    {!loading && curOffer && curOffer?.length > 0 && 
                    
                    <Table
                        data={curOffer}
                        states={{disableMode:true}}
                        type={'OFFERPOSITIONS'}
                        designer={props.designer}
                    />

                    } 
                </div>

                {!loading && curOffer && curOffer.length === 0 && (
                    <h4 className="nothing-found">
                        {window.Dictionary.getTranslation(14342)} <i className="icon-search"></i>
                    </h4>
                )}
            </>
                } 
        {props.LayoutName === 'newoffer' && 
        
            <NewOffer designer={props.designer}/>
        
        } 
        
        </>
    );
}

export default OfferPositionsView;
