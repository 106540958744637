/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* IMPORT MACHEN!!!!!  */
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Checkbox from '../Elements/ViewCheckbox';
import { viewActions } from '../../../../Helpers/Stores/viewStore';
import { toDateFormat } from 'Helpers/dateHelper';
import Info from 'Components/Lib/Elements/Info';

interface IGenericViewMenuProps {
    data: Object;
    onSelectFilter(e: any, id: any): void;
    filter?: any;
    curFilter?: number | any;
    tablehead: any;
    defaultSettings: any;
}

export default function TableMenu(props: IGenericViewMenuProps) {
    const dispatch = useDispatch();
    const [checkboxSearch, setCheckboxSearch] = useState('' as string);
    const [withType, setWithType] = useState(false);

    const [settingsSelected, setSettingsSelected] = useState({
        default: false,
        table: false,
        filter: false,
    });

    function changeFilterColHandler(newValue: any) {
        dispatch(viewActions.changeFilterColumns(newValue));
    }

    function changeFilter(newValue: any, target: string) {
        dispatch(viewActions.changeFilter({ newValue: newValue, target: target, idx: props.curFilter }));
    }

    function showSettings(type: string) {
        let newSettingsObject = { ...settingsSelected } as any;
        Object.keys(newSettingsObject).forEach(settings => {
            if (settings === type) newSettingsObject[type] = !newSettingsObject[type];
            else newSettingsObject[settings] = false;
        });
        setSettingsSelected(newSettingsObject);
    }
    function changeSettings(settingsObject: object) {
        dispatch(viewActions.changeData(settingsObject));
    }
    function removeFilter() {
        dispatch(viewActions.removeFilter(props.curFilter));
        props.onSelectFilter(null, null);
    }
    function changeTableHead(target: any, curSelected: any, property: any) {
        dispatch(viewActions.changeTableHead({ target: target, prevTarget: curSelected, property: property }));
    }
    function changeDefaultSettings(value: any, target: any) {
        dispatch(viewActions.changeDefaultSettings({ target: target, newValue: value }));
    }
    function changeSortDescending(target: any) {
        dispatch(viewActions.changeSortDescending({ target: target }));
    }

    const [valueAccord, setValueAccord]: any = useState({});

    function ViewData(curdata: Object, index: string, type: string, withType = false): any {
        if (Array.isArray(curdata)) curdata = curdata[0];
        if (!Object.entries(curdata).find((d: any) => d[1].Designable)) {
            return null;
        }
        return (
            <>
                {curdata &&
                    Object.entries(curdata)
                        .filter((data: any) => {
                            if (type !== 'Filter' && data[1].Label) return window.Dictionary.getTranslation(data[1].Label).toLowerCase().includes(checkboxSearch.toLowerCase());
                            return true;
                        })
                        .sort((a, b) => {
                            return !a[1].Designable && b[1].Designable ? 1 : window.Dictionary.getTranslation(a[1].Label) > window.Dictionary.getTranslation(b[1].Label) ? 1 : -1;
                        })
                        .map((key, idx) => {
                            if (type === 'Filter' && typeof key[1] === 'object' && key[1].Designable === true && (key[1].Label || key[1].Name)) {
                                return (
                                    <option key={(index ? index + '-' : '') + key[0]} value={key[1].Key}>
                                        {(index ? index + '-' : '') + window.Dictionary.getTranslation(key[1].Label ?? key[1].Name) + (withType && key[1].Elementtype ? (' (' + (key[1].Type ?? key[1].Elementtype) + ')') : '')}
                                    </option>
                                );
                            } else if (typeof key[1] === 'object' && key[1].Designable === true && (key[1].Label || key[1].Name)) {
                                return (
                                    <div key={idx}>
                                        <Checkbox
                                            designData={key[1]}
                                            checked={Object.entries(props.tablehead[0]).find((d: any) => d[1].Key === key[1].Key) ? true : false}
                                            uniqueKey={(index ? index + '-' : '') + key[0]}
                                            onChangeHandler={(settingsObject: object) => changeSettings(settingsObject)}
                                            withType={withType}
                                        />
                                    </div>
                                );
                            } else if ((Array.isArray(key[1]) || typeof key[1] === 'object') && !key[1].Designable && key[1].Designable !== false && ViewData(key[1], (index ? index + '-' : '') + key[0], type)) {
                                if (type === 'Filter') {
                                    return <>{ViewData(key[1], (index ? index + '-' : '') + key[0], type)}</>;
                                }
                                return (
                                    <Fragment key={idx}>
                                        <h4 onClick={() => setValueAccord({ ...valueAccord, [key[0]]: !valueAccord[key[0]] ?? true })}>{'-'.repeat(index ? index.split('-').length + 1 : 1) + key[0]}</h4>
                                        {valueAccord[key[0]] && ViewData(key[1], (index ? index + '-' : '') + key[0], type, withType)}
                                    </Fragment>
                                );
                            }
                            return <></>;
                        })}
            </>
        );
    }
    function setSpecificProperty(property: any) {
        let curSelected = Object.entries(props?.tablehead[0]).find(key => {
            return (key[1] as any)[property] === true;
        });
        curSelected = curSelected ? curSelected[0] : ('' as any);
        return (
            <select value={curSelected as any} onChange={e => changeTableHead(e.target.value, curSelected, property)}>
                <option value={''}></option>
                {Object.entries(props.tablehead[0]).map((e: any, idx: any) => {
                    return (
                        <>
                            <option key={idx} value={e[0]}>
                                {window.Dictionary.getTranslation(e[1].Name)}
                            </option>
                        </>
                    );
                })}
            </select>
        );
    }

    function getSortDescending() {
        let sortingColumnObj: any = Object.entries(props.tablehead[0]).find(key => {
            return (key[1] as any)['DefaultSortingColumn'] === true;
        });
        let sortingColumn = sortingColumnObj ? sortingColumnObj[1] : '';
        return (
            <>
                {sortingColumn && (
                    <>
                        <input
                            type="checkbox"
                            checked={sortingColumn.SortDescending ?? false}
                            onChange={e => {
                                console.log(e);
                                return changeSortDescending(sortingColumnObj[0]);
                            }}
                        />
                        <label>{window.Dictionary.getTranslation(14468)}</label>
                    </>
                )}
            </>
        );
    }
    useEffect(() => {
        if (!settingsSelected.filter && props.curFilter !== null) showSettings('filter');
    }, [props.curFilter]);

    return (
        <div id="mfx-generic-view-menu">
            <div className="mfx-settings-accordion">
                <h3 className={settingsSelected.table ? 'active' : ''} onClick={() => showSettings('table')}>
                    {window.Dictionary.getTranslation(14467)}
                </h3>
                {settingsSelected.table && (
                    <div>
                        <div className="mfx-checkbox-element">
                            <input
                                id="withTypeCheckbox"
                                type="checkbox"
                                checked={withType}
                                onClick={(e: any) => {
                                    setWithType(e.target.checked);
                                }}
                            />
                            <label>
                                {window.Dictionary.getTranslation(15166)} <Info text={window.Dictionary.getTranslation(15402)} />
                            </label>
                        </div>
                        <input id="checkboxsearch" placeholder={window.Dictionary.getTranslation(162)} value={checkboxSearch} onChange={e => setCheckboxSearch(e.target.value)} />
                        {ViewData(props.data, '', 'Table', withType)}
                    </div>
                )}
            </div>

            <div className="mfx-settings-accordion">
                <h3 className={settingsSelected.default ? 'active' : ''} onClick={() => showSettings('default')}>
                    {window.Dictionary.getTranslation(14466)}
                </h3>
                {settingsSelected.default && (
                    <>
                        <div>
                            <label>
                                {window.Dictionary.getTranslation(14450)} <Info text={window.Dictionary.getTranslation(15403)} />
                            </label>
                            <input type="number" value={props?.defaultSettings?.NumberOfEntries ?? null} onChange={e => changeDefaultSettings(e.target.value, 'NumberOfEntries')} />
                        </div>
                        <div className="mfx-dropdown-element">
                            <label>
                                {window.Dictionary.getTranslation(14452)} <Info text={window.Dictionary.getTranslation(15404)} />
                            </label>
                            {setSpecificProperty('DefaultSortingColumn')}
                        </div>
                        <div className="mfx-checkbox-element">{getSortDescending()}</div>
                        <div className="mfx-checkbox-element">
                            <input
                                type="checkbox"
                                checked={props?.defaultSettings?.IsListView ?? false}
                                onChange={e => {
                                    console.log(e);
                                    return changeDefaultSettings(e.target.checked, 'IsListView');
                                }}
                            />
                            <label>
                                {window.Dictionary.getTranslation(14451)} <Info text={window.Dictionary.getTranslation(15167)}></Info>
                            </label>
                        </div>
                        <div className="mfx-checkbox-element">
                            <input
                                type="checkbox"
                                checked={props?.defaultSettings?.IsFilterClosed ?? false}
                                onChange={e => {
                                    console.log(e);
                                    return changeDefaultSettings(e.target.checked, 'IsFilterClosed');
                                }}
                            />
                            <label>
                                {window.Dictionary.getTranslation(15182)} <Info text={window.Dictionary.getTranslation(15183)}></Info>
                            </label>
                        </div>
                        <div>
                            <label>
                                {window.Dictionary.getTranslation(14866)} <Info text={window.Dictionary.getTranslation(15405)}></Info>
                            </label>
                            <input type="text" value={props?.defaultSettings?.ExtraFilter ?? ''} onChange={e => changeDefaultSettings(e.target.value, 'ExtraFilter')} />
                        </div>
                        {/*props.filter && (
                            <div className="mfx-dropdown-element">
                                <label>{window.Dictionary.getTranslation(14449)}</label>
                                <select value={props.filter?.Col} onChange={e => changeFilterColHandler(e.target.value)}>
                                    <option value={1}>12</option>
                                    <option value={2}>6</option>
                                    <option value={3}>4</option>
                                    <option value={4}>3</option>
                                    <option value={6}>2</option>
                                    <option value={12}>1</option>
                                </select>
                            </div>
                        )*/}
                    </>
                )}
            </div>

            {props.filter && (
                <div className="mfx-settings-accordion">
                    <h3 className={settingsSelected.filter ? 'active' : ''} onClick={() => showSettings('filter')}>
                        {window.Dictionary.getTranslation(14465)}
                    </h3>
                    {settingsSelected.filter && (
                        <>
                            {props.curFilter === null && <label>{window.Dictionary.getTranslation(14464)}</label>}
                            {props.curFilter !== null && props.filter.Data[props.curFilter] && (
                                <>
                                    <div className="mfx-checkbox-element">
                                        <input
                                            id="withTypeCheckbox"
                                            type="checkbox"
                                            checked={withType}
                                            onClick={(e: any) => {
                                                setWithType(e.target.checked);
                                            }}
                                        />
                                        <label>
                                            {window.Dictionary.getTranslation(15166)} <Info text={window.Dictionary.getTranslation(15402)} />
                                        </label>
                                    </div>
                                    <div className="mfx-dropdown-element">
                                        <label>{window.Dictionary.getTranslation(11349)}</label>
                                        <select value={props.filter.Data[props.curFilter].Key ?? ''} onChange={e => changeFilter(e.target.value, 'Key')}>
                                            <option value={''}></option>
                                            <option key="OnlyOwnTasks" value="OnlyOwnTasks">
                                                {window.Dictionary.getTranslation(14200)}
                                            </option>16122
                                            <option key="FromRole" value="FromRole">
                                                {window.Dictionary.getTranslation(16122)}
                                            </option>
                                            {ViewData(props.data, '', 'Filter',withType)}
                                        </select>
                                    </div>
                                    <label>{window.Dictionary.getTranslation(14469)}</label>
                                    <input type="text" value={window.Dictionary.getTranslation(props.filter.Data[props.curFilter].Label)} onChange={e => changeFilter(e.target.value, 'Label')} />

                                    <div className="mfx-dropdown-element">
                                        <label>{window.Dictionary.getTranslation(53)}</label>
                                        <select value={props.filter.Data[props.curFilter].Type} onChange={e => changeFilter(e.target.value, 'Type')}>
                                            <option value={'bool'}>{window.Dictionary.getTranslation(14453)}</option>
                                            <option value={'date'}>{window.Dictionary.getTranslation(7)}</option>
                                            <option value={'number'}>{window.Dictionary.getTranslation(6)}</option>
                                            <option value={'text'}>{window.Dictionary.getTranslation(545)}</option>
                                            <option value={'dropdown'}>{window.Dictionary.getTranslation(1672)}</option>
                                        </select>
                                    </div>
                                    <div className="mfx-dropdown-element">
                                        
                                        {props.filter.Data[props.curFilter].Type !== 'bool' && (<>
                                            <label>{window.Dictionary.getTranslation(5680)}</label>
                                            <input
                                                type={props.filter.Data[props.curFilter].Type ?? 'text'}
                                                value={props.filter.Data[props.curFilter].DefaultValue}
                                                onChange={e => changeFilter(props.filter.Data[props.curFilter].Type !== 'date' ? e.target.value : toDateFormat(e.target.value), 'DefaultValue')}
                                            />
                                        </>)}
                                        {props.filter.Data[props.curFilter].Type === 'bool' && props.filter.Data[props.curFilter].Key !== 'OnlyOwnTasks' && props.filter.Data[props.curFilter].Key !== 'FromRole' && (<>
                                            <label>{window.Dictionary.getTranslation(5680)}</label>
                                            <select value={props.filter.Data[props.curFilter].DefaultValue} onChange={e => changeFilter(e.target.value, 'DefaultValue')}>
                                                <option value={''}></option>
                                                <option value={1}>{window.Dictionary.getTranslation(1651)}</option>
                                                <option value={0}>{window.Dictionary.getTranslation(1652)}</option>
                                            </select>
                                        </>)}
                                    </div>
                                    <div className="mfx-checkbox-element">
                                        <input id="date-now" type={'checkbox'} checked={props.filter.Data[props.curFilter].ReadOnly} onChange={e => changeFilter(e.target.checked, 'ReadOnly')} />
                                        <label htmlFor="date-now">{window.Dictionary.getTranslation(14933)}</label>
                                    </div>
                                    {(props.filter.Data[props.curFilter].Type === 'date' || props.filter.Data[props.curFilter].Type === 'datetime-local') && (
                                        <div className="mfx-checkbox-element">
                                            <input id="date-now" type={'checkbox'} checked={props.filter.Data[props.curFilter].DefaultValue === 'NOW'} onChange={e => changeFilter(e.target.checked ? 'NOW' : '', 'DefaultValue')} />
                                            <label htmlFor="date-now">{window.Dictionary.getTranslation(12152)}</label>
                                        </div>
                                    )}
                                    {(props.filter.Data[props.curFilter].Type === 'date' || props.filter.Data[props.curFilter].Type === 'number' || props.filter.Data[props.curFilter].Type === 'text') && (
                                        <div className="mfx-dropdown-element">
                                            <label>{window.Dictionary.getTranslation(918)}</label>
                                            <select value={props.filter.Data[props.curFilter].Operator ? props.filter.Data[props.curFilter].Operator : ''} onChange={e => changeFilter(e.target.value, 'Operator')}>
                                                <option value={''}></option>
                                                <option value={'='}>{'='}</option>
                                                <option value={'IS'}>{'IS'}</option>
                                                {(props.filter.Data[props.curFilter].Type === 'date' || props.filter.Data[props.curFilter].Type === 'number') && (
                                                    <>
                                                        <option value={'>'}>{'>'}</option>
                                                        <option value={'<'}>{'<'}</option>
                                                        <option value={'>='}>{'>='}</option>
                                                        <option value={'<='}>{'<='}</option>
                                                    </>
                                                )}
                                                {(props.filter.Data[props.curFilter].Type === 'number' || props.filter.Data[props.curFilter].Type === 'text') && <option value={'LIKE'}>LIKE</option>}
                                            </select>
                                        </div>
                                    )}
                                    <button className="mfx-button button-border" onClick={removeFilter}>
                                        {window.Dictionary.getTranslation(14463)}
                                    </button>
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
