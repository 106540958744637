import LocalStorage from '../../../Helpers/MFXLocalStorage';
import { Navigate, Outlet } from 'react-router-dom';

export function VerifyConnectionKey() {
  const urlKey = document.location.pathname.split('/')[1];

  /*if ((document.location.pathname !== '/login') && !(urlKey && hasLocal)) {
    console.log('login');
    return <Navigate to='/login' />;
  }*/

  if (
    LocalStorage.hasKeyInDB(urlKey) &&
    LocalStorage.getCurrentPath() !== urlKey
  ) {
    LocalStorage.setCurrent(urlKey, false);
  }

  return <Outlet />;
}
