/**
 * Renders an generic SmallHeadline
 * @returns {JSX.Element} The basic SmallHeadline.
 */

const SmallHeadline = (props) => {

    return(
        
        <div className="mfx-element-headline-small">
            <div>
                {props.designData.Headline && <h3 className='mfx-headline'>{!isNaN(props.designData.Headline) ? window.Dictionary.getTranslation(props.designData.Headline) : props.designData.Headline}</h3> }
                {props.designData.Subheadline && <h4 className='mfx-subheadline'>{!isNaN(props.designData.Subheadline) ? window.Dictionary.getTranslation(props.designData.Subheadline) : props.designData.Subheadline}</h4>} 
            </div>
        </div>
    );

};

export default SmallHeadline;