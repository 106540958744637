/* global google */
import { useState } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import Loading from './Loading';
import LocalStorage from 'Helpers/MFXLocalStorage';

/**
 * @params {apiKey, markers[]}
 * @returns {JSX.Element} The GoogleMaps with Markers.
 */

const GoogleMaps = props => {
    const { isLoaded } = useLoadScript({ googleMapsApiKey: props.apiKey });
    const [mapRef, setMapRef] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [curAddress, setCurAddress] = useState({ id: 0, searchCode: props.markers[0].searchCode, lat: props.markers[0].lat, lng: props.markers[0].lng, customerNumber: props.markers[0].customerNumber });
    const markers = props.markers;

    const handleMarkerClick = (id, lat, lng, searchCode, customerNumber) => {
        mapRef?.panTo({ lat, lng });
        setCurAddress({ id, searchCode, lat, lng, customerNumber });
        setIsOpen(true);
    };

    function handleOpenInfo() {
      if (!LocalStorage.hasKeyInDB('customer'.toUpperCase() + '_' + curAddress.customerNumber)) {
            LocalStorage.createTab(
                {
                    primaryId: curAddress.customerNumber,
                    primaryType: 'customer',
                    context: {customerName: curAddress.searchCode},
                },
                true,
                true,
                '/processes/customer',
                '/processes/customer'
            );
        } else {
            LocalStorage.setCurrent('customer'.toUpperCase() + '_' + curAddress.customerNumber, true, '/processes/customer');
        }
    }

    return (
        <div className="mfx-google-maps">
            {!isLoaded ? (
                <Loading />
            ) : (
                <GoogleMap mapContainerClassName="map-container" mapContainerStyle={{ width: '100%', height: '500px' }} zoom={15} center={{ lat: props.markers[0].lat, lng: props.markers[0].lng }} onClick={() => setIsOpen(false)}>
                    {markers.map(({ searchCode, lat, lng, customerNumber }, ind) => (
                        <Marker
                            key={ind}
                            position={{ lat, lng }}
                            onClick={() => {
                                handleMarkerClick(ind, lat, lng, searchCode, customerNumber);
                            }}>
                            {isOpen && curAddress?.id === ind && (
                                <InfoWindow
                                    onCloseClick={() => {
                                        setIsOpen(false);
                                    }}>
                                    <>
                                        <h3>{curAddress.searchCode}</h3>
                                        <button onClick={handleOpenInfo}>{window.Dictionary.getTranslation(1933)}</button>
                                    </>
                                </InfoWindow>
                            )}
                        </Marker>
                    ))}
                </GoogleMap>
            )}
            <a className="mfx-button button-border" target="_blank" href={`https://www.google.com/maps/dir/?api=1&destination=${curAddress.lat},${curAddress.lng}`}>
                {window.Dictionary.getTranslation(15153)}
            </a>
        </div>
    );
};

export default GoogleMaps;
