/**
 * Functions for Cookies
 */

export function setLoginCookies(Username, Language, Database) {
    setCookie('MFX_Login', Username, 1);
    setCookie('MFX_LANGUAGE', Language, 1);
    setCookie('MFX_DATABASE', Database, 1);
}


export function setCookie(name: string, data: string, time: number) {
    const date = new Date();
    date.setTime(date.getTime() + (time * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + date.toUTCString();
    if (data !== null) {
        data = btoa(data);
    }
    document.cookie = name + '=' + data + ';' + expires + ';path=/';
}

export function getCookie(name: string) {
    return document.cookie.split(';').some(c => {
        return c.trim().startsWith(name + '=');
    });
}

export function getCookieValue(name: string) {
    var x =
        document.cookie.split(';').find(c => {
            return c.trim().startsWith(name + '=') === true;
        });
    let data = x?.split('=')[1] ?? null;
    if (data !== null) {
        try {
            data = atob(data);
        } catch (e) {
            console.log(e);
        }
    }
    return data;
}

export function deleteCookie(name: string) {
    if (getCookie(name)) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
}


