/**
 * Renders an generic text input field
 * @returns {JSX.Element} The basic text input.
 * @param {any} props given by caller
 */
 const TextareaElement = (props) => {

 
   let textareaID = 'textarea-' + props.designData.Key;
   let textareaName = props.designData.Key;
  
   let textareaValue = props.customerData ? props.customerData : '';
   
    if(props.transcript) {
          textareaValue = props.transcript;
    }
   
   const textareaOnChangeHandler = (event) => {
      props.onChangeHandler({newValue: event.target.value, type: props.uniqueIndex, designData: props.designData});
   };

   return (
     <div className='mfx-textarea-element'>
       {props.designData.Label && 
         <label htmlFor={textareaID}>
           {!isNaN(props.designData.Label) ? window.Dictionary.getTranslation(props.designData.Label) : props.designData.Label}
           {props.designData.Required && '*'}
         </label>
       }
       <textarea 
         id={textareaID + textareaName}
         className={textareaID}
         placeholder={props.placeholder ?? props.designData.Placeholder}
         value={textareaValue ? textareaValue : props.designData.DefaultValue }
         name={textareaName} 
         onChange={textareaOnChangeHandler}
         readOnly={Boolean(props.designData.ReadOnly) || Boolean(props.disableMode)}
         required={props.designData.Required}
       ></textarea>
       
 
     </div>
   );
 };

 
 export default TextareaElement;