/**
 * Renders an generic text input field
 * @returns {JSX.Element} The basic text input.
 * @param {any} props given by callerDefaultValue
 */

import { getTodaysDate, toDateFormat, toDateTimeFormat, changeTimeOfDate, getTimeFromDate } from '../../../Helpers/dateHelper';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import LocalStorage from 'Helpers/MFXLocalStorage';

const InputElement = props => {
    //DESIGNDATA -> DESIGNER
    //VALUE -> CUSTOMER
    let inputClasses = '';
    let inputID = 'input-' + props.designData.Key + '-' + props.uniqueIndex;
    let inputName = !isNaN(props.designData.Name) ? props.designData.Name : window.Dictionary.getTranslation(props.designData.Name);
    let inputValue = '';
    let multiple = props.designData.Type === 'email' ? true : false;
    if (props.ignoreDefaultValue) {
        props.designData.DefaultValue = null;
    }
    if (props.viewName === 'addView' && !props.customerData) {
        inputValue = props.designData.Value;
        if (!props.designData.Value) {
            if (props.designData.Key === 'Begin' || props.designData?.DefaultValue === 'today') inputValue = getTodaysDate(0, props.designData?.Type);
            else if (props.designData.Key === 'EndTime') inputValue = getTodaysDate(2, props.designData?.Type);
        }
    } else if (props.designData.Type.toLowerCase() === 'tel') {
        inputValue = props.customerData ? props.customerData : props.designData?.DefaultValue ? props.designData?.DefaultValue : '';
    } else if (props.designData.Type.toLowerCase() === 'text') {
        inputValue = props.customerData ? props.customerData : props.designData?.DefaultValue ? props.designData?.DefaultValue : '';
    } else if (props.designData.Type.toLowerCase() === 'email') {
        inputValue = props.customerData ? props.customerData.replace(';', ',') : props.designData?.DefaultValue ? props.designData?.DefaultValue.replace(';', ',') : '';
    } else if (props.designData.Type.toLowerCase() === 'time') {
        inputValue = props.customerData && props.customerData instanceof Date ? getTimeFromDate(props.customerData) : props.designData?.DefaultValue && props.designData?.DefaultValue instanceof Date ? getTimeFromDate(props.designData?.DefaultValue) : '';
    } else if (props.designData?.DefaultValue === 'today') {
        inputValue = getTodaysDate(0, props.designData?.Type);
    } else {
        inputValue =
            props.designData.Type.toLowerCase() === 'number'
                ? props.customerData || props.designData?.DefaultValue
                    ? Math.round((props.customerData ?? props.designData?.DefaultValue ?? 0) * 100) / 100
                    : null
                : props.designData.Type.toLowerCase() === 'datetime-local'
                ? toDateTimeFormat(props.customerData ?? props.designData?.DefaultValue)
                : props.designData.Type.toLowerCase() === 'date'
                ? toDateFormat(props.customerData ?? props.designData?.DefaultValue)
                : props.customerData ?? props.designData?.DefaultValue;
    }

    let readonly = (props.designData.EditableStatus === 'never' || props.designData.EditableStatus === 'onlyNew' || props.designData.ReadOnly) && !props.designData.WriteGroups?.includes(LocalStorage.getData('UserRole')) ? true : false;

    const inputChangeHandler = event => {
        var newValue = event.target ? event.target.value : event;

        if (!props.isFilter) {
            switch (props.designData.Type.toLowerCase()) {
                case 'time':
                    var fullDate = props.customerData ?? props.designData?.DefaultValue ?? new Date();
                    console.log(fullDate);
                    newValue = changeTimeOfDate(fullDate, newValue);
                    break;
                case 'date':
                    newValue = new Date(newValue);
                    break;
                default:
                    break;
            }
        }
        props.onChangeHandler({ newValue: newValue, type: props.uniqueIndex, designData: props.designData });
        inputValue = newValue;
    };

    if (props.designData.Key === 'Priority' || props.designData.Key === 'Data-Priority') {
        inputClasses += 'priority_' + inputValue + ' ';

        switch (inputValue) {
            case '':
            case 0:
                inputValue = window.Dictionary.getTranslation(1408);
                break;
            case 1:
                inputValue = window.Dictionary.getTranslation(1409);
                break;
            case 2:
                inputValue = window.Dictionary.getTranslation(1410);
                break;
            case 3:
                inputValue = window.Dictionary.getTranslation(4955);
                break;
            default:
                break;
        }
        props.designData.Type = 'text';
    }

    function clearInput(e) {
        props.onChangeHandler({ newValue: '', type: props.uniqueIndex, designData: props.designData });
    }

    const showPwd = event => {
        let curElement = event.target;
        if (event.target.tagName.toLowerCase() === 'i') curElement = event.target.parentNode;

        if (curElement.previousElementSibling.type === 'password') curElement.previousElementSibling.type = 'text';
        else curElement.previousElementSibling.type = 'password';
    };
    const handleKeyDown = event => {
        if (event.key === 'Enter') props.onEnterPressed();
    };

    return (
        <div className={`mfx-input-element mfx-input-${props.designData.Type} ${inputClasses}`}>
            {!props.noLabel && props.designData.Label && (
                <label htmlFor={inputName}>
                    {!isNaN(props.designData.Label) ? window.Dictionary.getTranslation(props.designData.Label) : props.designData.Label}
                    {props.designData.Required && '*'}
                </label>
            )}
            <div className="mfx-input-element-wrapper">
                {(props.designData.Key === 'Process-SignalCode' || props.designData.Key === 'SignalCode') && <span className={'signalcode' + (inputValue !== null && inputValue !== undefined ? '_' + inputValue : '')}></span>}

                {props.designData.Type !== 'tel' && (
                    <input
                        style={props.disableMode ? { pointerEvents: 'none' } : {}} //props.underlined ? { textDecoration: 'underline', cursor: 'pointer' } : {}}
                        id={inputID}
                        type={props.designData.Type}
                        value={inputValue}
                        placeholder={props.designData.Placeholder}
                        name={props.designData.Key}
                        disabled={Boolean(props.disableMode)}
                        min={props.designData.Min}
                        max={props.designData.Max}
                        readOnly={readonly}
                        step={props.designData.Step}
                        data-type={props.designData.DataType}
                        required={props.designData.Required}
                        onChange={inputChangeHandler}
                        onKeyDown={handleKeyDown}
                        multiple={multiple}
                    />
                )}

                {props.designData.Type === 'tel' && (
                    <>
                        <PhoneInput country={'de'} placeholder="Telefonnummer hinzufügen" value={inputValue} disabled={Boolean(props.disableMode)} onChange={inputChangeHandler} />
                        <i className="icon-phone"></i>
                    </>
                )}
                {props.designData.Type === 'email' && <i className="icon-mail"></i>}
                {/*props.designData.Type === 'date' && <i className='fas fa-calendar'></i>*/}
                {/*props.designData.Type === 'datetime-local' && <i className='fas fa-calendar'></i>*/}
                {props.designData.Type === 'currency' && <i className="fas fa-euro-sign"></i>}
                {props.designData.Type === 'password' && (
                    <button type="button" className="no-styling" onMouseDown={showPwd}>
                        <i className="fas fa-eye"></i>
                    </button>
                )}
                {props.withDelete === true && (
                    <button type="button" className="no-styling" onMouseDown={clearInput}>
                        <i className="fas fa-close"></i>
                    </button>
                )}
                {props.designData.Type === 'range' && <output>{inputValue}</output>}
            </div>
        </div>
    );
};

export default InputElement;
