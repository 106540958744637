
/**
 * Renders only the Editbutton
 * @returns {JSX.Element} The basic Editbutton.
 */

import ModalPopup from '../Common/ModalPopup';

 const ConfirmPopup = (props) => {
    

    return(
       <>
        <ModalPopup closeIcon={true} closePopup={props.stop}>
            <div className='mfx-confirm-notice'>{props.children}</div>

            <div className='mfx-modal-button'>
                <button type="button" className='mfx-cancel-button mfx-button' onClick={props.stop}>
                    {window.Dictionary.getTranslation(40)}
                </button>
                <button type="button" className='mfx-confirm-button mfx-button' onClick={props.confirm}>
                    {window.Dictionary.getTranslation(6324)}
                </button>
            </div>
            
        </ModalPopup>
       </>
    );

};

export default ConfirmPopup;