import ChartContainer from '../../Elements/ChartContainer';

export default function DashboardView(props: any){
    return (
        <>
            <div className="mfx-gp-container">
                <div className="mfx-view">
                    <div className="mfx-grid-container">
                        {props?.data?.Json?.map((data: any, idx: any) => {
                            return <ChartContainer key={idx} cols={props.data.Json.length} chart={data} />;
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}
