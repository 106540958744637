/* eslint-disable no-prototype-builtins */

/* IMPORT MACHEN!!!!!  */
import ViewCheckbox from '../Elements/ViewCheckbox';
import allViews from '../../../../Data/Designer/list_views.json';
import { IGenericContainerMenuProps } from '../../../../Helpers/Interfaces';
import { useEffect, useState } from 'react';
import loadJsonData from '../../Utils/jsonGet';
import LocalStorage from '../../../../Helpers/MFXLocalStorage';
import URLS from '../../Utils/URLs.API';

export default function ViewMenu(props: IGenericContainerMenuProps) {
    const [searchViews, setSearchViews] = useState('');
    const [views, setViews] = useState(null as any);

    function changeSettings(settingsObject: object) {
        props.onChangeHandler(settingsObject);
    }
    useEffect(() => {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetAllBusinessProcessViewNamesAndLayoutNames).then((data: any) => {
            let layouts = data.Data.sort((a: any, b: any) => (a.ViewName.toLowerCase() > b.ViewName.toLowerCase() ? 1 : -1)).map((d: any) => {
                return { View: d.ViewName, Label: d.LayoutName, ViewLabel: d.ViewLabel, ViewType: d.ViewType };
            });

            layouts = layouts.concat(
                Object.entries(allViews).map((d: any) => {
                    return { View: d[1].View, Label: d[1].Name };
                })
            );
            setViews(layouts);
        });
    }, []);
    return (
        <div id="mfx-generic-view-menu">
            <input id="search-view-input" placeholder={window.Dictionary.getTranslation(162)} onChange={e => setSearchViews(e.target.value)} />
            {views !== null &&
                views
                    .filter((view: any) => {
                        return view.View?.toLowerCase().includes(searchViews.toLowerCase()) || view.Label?.toLowerCase().includes(searchViews.toLowerCase()) || view.ViewLabel?.toLowerCase().includes(searchViews.toLowerCase());
                    })
                    .map((view: any, idx: number) => {
                        if (view.ViewType !== 'Settings') {
                            return (
                                <div key={idx}>
                                    {props.data?.children?.hasOwnProperty(view.View + '-' + view.Label) && (
                                        <ViewCheckbox designData={{ ...view, CheckedInDesigner: true }} uniqueKey={'Data-' + view.View + '-' + view.Label} onChangeHandler={(settingsObject: object) => changeSettings(settingsObject)} />
                                    )}
                                    {!props.data?.children?.hasOwnProperty(view.View + '-' + view.Label) && (
                                        <ViewCheckbox designData={{ ...view, CheckedInDesigner: false }} uniqueKey={'Data-' + view.View + '-' + view.Label} onChangeHandler={(settingsObject: object) => changeSettings(settingsObject)} />
                                    )}
                                </div>
                            );
                        } else {
                            return <></>;
                        }
                    })}
        </div>
    );
}
