/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/anchor-is-valid */

/**
 * Renders a Quicklink
 * @returns {JSX.Element} The styled Link.
 * @param {any} props given by caller
 */

import { useEffect, useRef, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import TextareaElement from './TextareaElement';
import { getCookieValue } from 'Helpers/MFXCookie';

export default function VoiceRecorder(props) {
    const { transcript, resetTranscript } = useSpeechRecognition();
    const [textareaValue, setTextareaValue] = useState(props.customerData || '');
    const [isListening, setIsListening] = useState(false);
    const microphoneRef = useRef(null); 

    let defaultLanguage = 'de-DE';

    useEffect(() => {
        //-------very special condition 
        if(props.designData.curProcess && props.designData.Key == 'Process-Text'){
            console.log(props.designData.curProcess);
            setTextareaValue(props.designData.curProcess.Text ?? '');
            //console.log(props);
            props.disableMode = true;
        } 
    },[props]);
    
        

    if(getCookieValue('MFX_LANGUAGE') == 'English')
        defaultLanguage = 'en-GB';

    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        alert('Browser does not Support Speech Recognition.' + 'If you are on Firefox make sure that media.webspeech.recognition.enable and media.webspeech.recognition.force_enable are true in about:config');
        //return <div className="mircophone-container">Browser is not Support Speech Recognition.</div>;
    }

    const handleListing = () => {
        //setTextareaValue(document.getElementById('test').value);
        console.log(props);
        if (isListening === true || props.isRecording === false) {
            if (isListening) {
                setIsListening(false);
                props.setRecording(false);
                microphoneRef.current.classList.remove('listening');
                SpeechRecognition.stopListening();
                setTextareaValue(textareaValue + transcript);
                props.onChangeHandler({ newValue: textareaValue + transcript, type: props.uniqueIndex, designData: props.designData });
                resetTranscript();
            } else if (!isListening) {
                resetTranscript();
                setIsListening(true);
                props.setRecording(true);
                microphoneRef.current.classList.add('listening');
                SpeechRecognition.startListening({
                    continuous: true,
                    language: defaultLanguage
                });
            }
        }
    };

    function changeTextArea(valueObject) {
        if (!isListening) {
            setTextareaValue(valueObject.newValue);
            props.onChangeHandler({ newValue: valueObject.newValue, type: props.uniqueIndex, designData: props.designData });
        }
    }

    return (
        <div className="microphone-wrapper">
            {SpeechRecognition.browserSupportsSpeechRecognition() && (
                <>
                    <TextareaElement designData={props.designData} disableMode={props.disableMode} customerData={textareaValue + (isListening === true ? transcript : '')} uniqueIndex={props.index} onChangeHandler={valueObject => changeTextArea(valueObject)} noLabel={props.noLabel || false} />
                    {!props.designData.ReadOnly && !props.designData.disableMode && 
                    <div
                        className="mircophone-container"
                        ref={microphoneRef}
                        onClick={handleListing}
                        onTranscriptChange={e => {
                            console.log(e);
                            return changeTextArea(e);
                        }}>
                        <i className={'icon-voicerecorder' + (isListening ? ' active' : '')}></i>
                        <div>{isListening ? window.Dictionary.getTranslation(14317) : window.Dictionary.getTranslation(14316)}</div>
                    </div>
                    }
                </>
            )}
            {!SpeechRecognition.browserSupportsSpeechRecognition() && (
                <TextareaElement designData={props.designData} disableMode={props.disableMode} customerData={textareaValue} uniqueIndex={props.index} onChangeHandler={valueObject => changeTextArea(valueObject)} noLabel={props.noLabel || false} />
            )}
        </div>
    );
}

// This is a voice recorder that creates blobs (audio files)
/*const [mediaRecorder, setMediaRecorder] = useState(null);
    let stream = null;
    const [chunks, setChunks] = useState([]);
    const [isRecording, setIsRecording] = useState(false);
    const [src, setSrc] = useState('');

    function onMediaRecorderDataAvailable(e) {
        chunks.push(e.data);
    }

    function onMediaRecorderStop(e) {
        const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });
        const audioURL = window.URL.createObjectURL(blob);
        props.recordHandler(blob);
        setSrc(audioURL);
        setChunks([]);
        stream.getAudioTracks().forEach(track => {
            track.stop();
        });
        stream = null;
    }

    function handleSuccess(e) {
        stream = e;
        stream.oninactive = () => {
            console.log('Stream ended!');
        };
        let mediaRec = new MediaRecorder(stream);
        mediaRec.ondataavailable = onMediaRecorderDataAvailable;
        mediaRec.onstop = onMediaRecorderStop;
        mediaRec.start();
        setMediaRecorder(mediaRec);
    }

    function handleError(e) {
        console.log('AUDIO: ERROR HAS OCCURED');
        console.log(e);
    }

    function startRecording() {
        if (isRecording) return;
        setIsRecording(true);
        setSrc('');
        navigator.mediaDevices
            .getUserMedia({
                audio: true,
            })
            .then(handleSuccess)
            .catch(handleError);
    }
    function onClickHandler() {
        isRecording ? stopRecording() : startRecording();
    }
    function stopRecording() {
        if (!isRecording) return;
        setIsRecording(false);
        console.log(mediaRecorder);
        mediaRecorder.stop();
    }
    return (
        <>
            <div class="voice-record-container">
                <span>Recorder</span>
                <div>
                    <button onClick={onClickHandler}>
                        {isRecording ? 'Stop' : src === '' ? 'Start' : 'Redo'}
                    </button>
                </div>
                {src !== '' && <audio src={src} controls></audio>}
            </div>
        </>
    );*/
