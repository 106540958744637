/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';
import URLS from 'Components/Lib/Utils/URLs.API';
import { useSearchParams } from 'react-router-dom';
import { getTodaysDate, toDateFormat, toDateTimeFormat, changeTimeOfDate, getTimeFromDate, formatDate, formatedToInput } from '../../../Helpers/dateHelper';
import Select from 'react-select';

const Profiling = props => {
    const [defaultProfiling, setDefaultProfiling] = useState();
    const [searchParams] = useSearchParams();
    const [firstLoad, setFirstLoad] = useState(false);
    const [dropdowns, setDropDowns] = useState({});


    console.log(dropdowns);
    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (firstLoad) loadData();
    }, [props?.customData?.Assignment]);

    function loadData() {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetProcessProfCrit + props?.customData?.Assignment + '&Index=' + (searchParams.get('process') ?? searchParams.get('index'))).then(data => {
            if (data.Meta.Success && data.Data) {
                var res = props?.customData?.Profiling?.map(d => {
                    return { Index: d.Index, CriterionKey: d.CriterionKey, Value: d.Value, ProcessIndex: d.ProcessIndex, Type: data.Data?.find(p => p.Criteria === d.CriterionKey)?.Type, Conformity: data.Data?.find(p => p.Criteria === d.CriterionKey)?.Conformity };
                }) ?? [];
                res = res?.concat(
                    data.Data?.filter(f => {
                        return !res?.find(r => r.CriterionKey === f.Criteria);
                    }).map(d => {
                        console.log(d);
                        return { Index: null, CriterionKey: d.Criteria, Value: null, ProcessIndex: searchParams.get('process') ?? searchParams.get('index'), Type: d.Type, Conformity: d.Conformity };
                    })
                );
                setDefaultProfiling(res);
            } else {
                var res = props?.customData?.Profiling?.filter(f => f.Value).map(d => {
                    return { Index: d.Index, CriterionKey: d.CriterionKey, Value: d.Value, ProcessIndex: d.ProcessIndex, Type: d.Type };
                });
                setDefaultProfiling(res);
            }
            if (!firstLoad) {
                setFirstLoad(true);
            }
            res?.forEach(r => {
                loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetDropdownValues + 'ProfilingCriteria&ReferenceIndex=' + r.CriterionKey).then(data => {
                    if (data.Meta.Success && data.Data) {
                        setDropDowns(prev => {
                            return { ...prev, [r.CriterionKey]: data.Data };
                        });
                    }
                });
            });
        });
    }
    console.log(props);
    function onChange(inx, newValue) {
        var newData = JSON.parse(JSON.stringify(defaultProfiling));
        if (newData[inx]) newData[inx].Value = newValue;
        setDefaultProfiling(newData);
        props.onChangeHandler({ newValue: newData, designData: props.designData });
    }

    console.log(defaultProfiling);

    return (
        <div className="mfx-detailview-formelements-full-width">
            <label htmlFor={props.designData?.Key + '-div'}>{window.Dictionary.getTranslation(props.designData?.Label)}</label>
            <div id={props.designData?.Key + '-div'} className="mfx-detailview-formelements-nested">
                {defaultProfiling &&
                    defaultProfiling?.map((p, inx) => (
                        <>
                            <div>
                                <label>{p.CriterionKey}</label>
                            </div>
                            {
                                <div>
                                    {(p.Type === 0 || p.Type === 4) && p.Conformity && (
                                        <div className='mfx-react-select'>
                                            <Select
                                                maxMenuHeight={150}
                                                id={p.CriterionKey}
                                                name={props.designData.Key}
                                                onChange={e => onChange(inx, e.value)}
                                                options={dropdowns[p.CriterionKey]?.map(option => {
                                                    return { label: option.Name, value: option.Value };
                                                })}
                                                //defaultValue={props.customerData ? {label: props.customerData, value: props.customerData} : defaultData}
                                                value={{ label: p.Value, value: p.Value }}
                                                isDisabled={props.disableMode || Boolean(props.designData.ReadOnly)}
                                            />
                                        </div>
                                    )}
                                    {(p.Type === 0 || p.Type === 4) && !p.Conformity && (
                                        <>
                                            <input type="text" list={'options' + p.CriterionKey} value={p.Value} disabled={props.disableMode} onChange={e => onChange(inx, e.target.value)} />
                                            <datalist id={'options' + p.CriterionKey}>
                                                {dropdowns[p.CriterionKey]?.map(d => {
                                                    return <option>{d.Value}</option>;
                                                })}
                                            </datalist>
                                        </>
                                    )}
                                    {p.Type === 1 && <input type="number" value={isNaN(p.Value) ? null : p.Value} disabled={props.disableMode} onChange={e => onChange(inx, e.target.value)} />}
                                    {p.Type === 2 && <input type="date" value={formatedToInput(p.Value)} disabled={props.disableMode} onChange={e => onChange(inx, formatDate(new Date(e.target.value), 'Date'))} />}
                                    {p.Type === 3 && (
                                        <input
                                            type="checkbox"
                                            checked={p.Value === 'True' ? true : false}
                                            disabled={props.disableMode}
                                            onChange={e => {
                                                onChange(inx, e.target.checked ? 'True' : 'False');
                                            }}
                                        />
                                    )}
                                </div>
                            }
                        </>
                    ))}
            </div>
        </div>
    );
};

export default Profiling;
