
/**
 * Renders a Wrapper fpr a PopUp.
 * @returns {JSX.Element} Rendered component
 */

export default function ModalPopup(props:any) { 

    
    const closePopupModal = (event) =>  {
        
        if (event.keyCode === 13) {
            document.removeEventListener('keypress', closePopupModal);
            document.getElementById('closeButton')?.click();
        }    
    };

    document.addEventListener('keypress', closePopupModal);


    return (
        <div className={`mfx-modal ${props.modalClass ? props.modalClass : ''}`}>
           <div className='mfx-modal-content'>
                {props.closePopup && props.closeIcon &&
                    <div className="mfx-action-buttons">
                        <button id="closeButton" onClick={props.closePopup}><i className='icon-close'></i></button>
                    </div>
                }
                {props.children}
                {props.message && <p>{props.message}</p>}
                {props.closePopup && !props.closeIcon &&
                    <button id="closeButton" onClick={props.closePopup}>{window.Dictionary.getTranslation(1650)}</button>
                }
           </div>
        </div>
    );  
}