import ModalPopup from '../Common/ModalPopup';
import sessionedLink from '../Utils/sessionedLinks';

/**
 * Renders a SavedModal
 * @returns {JSX.Element} The styled Link.
 * @param {any} props given by caller
 */

export default function SavedModal(props) {

    function additionalLink(url) {
        props.handleClose();
        document.location.href = url;
    }


    return (
        <ModalPopup closeIcon={true} closePopup={props.handleClose}>
            <div className={`success-${props.saveMessage.success}`}>
                <div className='save-message'>
                    <div className='icon-style-arrow'>
                        {props.saveMessage.success && <i className='icon-check'></i>}
                        {!props.saveMessage.success && <i className='icon-close'></i>}
                    </div>
                    <p dangerouslySetInnerHTML={{__html : window.Dictionary.getTranslation(props.saveMessage.message)}} />
                </div>
                {props.modalButtons &&
                    <div className='save-modal-buttons'>
                        {
                            props.modalButtons.map((button) => {
                                if(button.url)
                                    return <button className='mfx-button' onClick={() => additionalLink(sessionedLink(button.url))}>{button.label}</button>;
                                else 
                                    return <button className='mfx-button' onClick={() => props.handleClose()}>{button.label}</button>;
                            })           
                        }
                    </div>    

                }
            </div>
        </ModalPopup>
    );
}
