import TabMenu from 'Components/Lib/Common/Menu/TabMenu';
import { useState } from 'react';
import Tabs from './Tabs';
import Topheader from './Topheader';

/**
 * Renders a Wrapper for the Topbar, the Tabs and the Menu inside the Tabs.
 * The resize-Listener sets a Class ('mobile'), if the Menu should be in a mobile State
 * The mobile Breakpoint is defined by the window.mobileBreakPoint
 * @returns {JSX.Element} Rendered component
 */

export default function Header() {
    const [mobile, setMobile] = useState(window.innerWidth <= window.mobileBreakPoint ? true : false);

    const handleResize = () => {
        if(window.innerWidth <= window.mobileBreakPoint) setMobile(true);
        else setMobile(false);
      };
    
    window.addEventListener('resize', handleResize);

    return (
        <div id='header'>
            <Topheader/>
            <div className={`tabcontainer ${mobile ? 'mobile' : ''}`}>
                <Tabs />
                <TabMenu />
            </div>
        </div>
    );
}