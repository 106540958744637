

export function getTodaysDate(hour, type) {
    let date = new Date();
    let month = new Date().getMonth() + 1;

     if(type === 'date' )
        return date.getFullYear() + '-' + addZero(month) + '-' + addZero(date.getDate());
    else {
        let nhour = date.getHours() + hour;
        nhour = nhour <= 24 ? nhour : nhour - 24; 
        return date.getFullYear() + '-' + addZero(month) + '-' + addZero(date.getDate()) + 'T' + addZero(nhour) + ':' + addZero(date.getMinutes());
    }
}

function addZero(number) {
    return number > 9 ? number : '0' + number;
}

export function changeTimeOfDate(date, newTime) {
    date.setHours(newTime.split(':')[0], newTime.split(':')[1]);
    return date;
}
export function getTimeFromDate(date) {
    return date.getHours() + ':' + date.getMinutes().toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false });
}

export function toDateFormat(input) {
    if(input === null || input === undefined){
        return input;
    }
    if(typeof input === 'string'){
        return input.substring(0, 10);
    }
    let date = new Date(input);
    if(isNaN(date.getTime())) date = new Date(null);

    date = date.toJSON().slice(0,10);
    return date;
}

export function toDateTimeFormat(input) {
    if(input === null || input === undefined){
        return input;
    }
    if(typeof input === 'string'){
        return input.substring(0, 16);
    }
    let date = new Date(input);
    if(isNaN(date.getTime())){
    date = new Date(null);
    }
    date = date.toJSON().slice(0,16);
    return date;    
}

export function formatDate(input, format) {
    var date = '';
    date += input.getDate() + '.' + (input.getMonth() + 1 < 10 ? '0' + (input.getMonth() + 1) : input.getMonth() + 1) + '.' + input.getFullYear();
    if(format !== 'Date')
        date += ' ' + input.getHours() + ':' + input.getMinutes().toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false }) + ':' + input.getSeconds().toLocaleString('de-DE', { minimumIntegerDigits: 2, useGrouping: false });
    return date;    
}

export function formatedToInput(input) {
    var date = '';
    if(input){
    date += input.substring(6,11) + '-' + input.substring(3,5) + '-' + input.substring(0,2);}
    return date;    
}