import icons from '../../../../Data/Designer/icons.json';

export default function IconMenu(props: any) {
    return (
        <div id="mfx-generic-view-menu">
            <div className="mfx-designer-icon-radios">
                <label>
                    <input type="radio" value="" name="design-icons" checked={Object.keys(props.curContainer).length === 0 || props.curContainer.Icon === '' ? true : false} onChange={e => props.changeIcon({ type: 'Icon', value: e.target.value })} /> {window.Dictionary.getTranslation(15223)}
                </label>
                {
                    icons.map((key, idx) => {
                        return (
                            <label key={idx}>
                                <input type="radio" value={key} name="design-icons" checked={props.curContainer.Icon === key ? true : false} onChange={e => props.changeIcon({ type: 'Icon', value: e.target.value })} />
                                {props.curContainer.Icon === key && <i style={{ backgroundColor: props.curContainer.iconColor }} className={key}></i>}
                                {props.curContainer.Icon !== key && <i className={key}></i>}
                            </label>
                        );
                    }) //
                }
            </div>
            <label className="color-label">
                <input type="color" className="colorpicker" value={props.curContainer.IconColor || '#0a4b55'} onChange={e => props.changeIcon({ type: 'IconColor', value: e.target.value })} />
                {window.Dictionary.getTranslation(14621)}
            </label>
        </div>
    );
}
