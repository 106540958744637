/**
 * Renders an generic text
 * @returns {JSX.Element} The basic text.
 * @param {any} props given by caller
 */
 
 const Text = (props) => {
  const Htmltag = props.designData.Type ? props.designData.Type : 'p' ;
   return (
     <div className='mfx-text-element'>
       <Htmltag>{props.value ?? ''}</Htmltag>
     </div>
   );
 };
 
 
 export default Text;