
export interface ILocal {
  primaryId: number;
  primaryType: 'start' | 'customer' | 'issue' | 'offer' | 'task' |'contactPerson' | 'lead'/* | ...*/;
  context: {
    customerId?: null | number;
    customerName? : null | string;
    issueId?: null | number;
    curUrl?: string;
  };
  lastUrl?: string;
}

class SessionStorage {
  private storage = sessionStorage;

  public createSession(key:string, data: any) {
    this.storage.setItem(key, JSON.stringify(data));
  }

  public removeAllStorage(reload = true) {
    this.storage.clear();
    if(reload)
      document.location.reload();
  }

  public getUserData():any{
    return this.storage.getItem('UserData') ?? '';
  } 

  public getData(key:string){
    return this.storage.getItem(key) ? JSON.parse(this.storage.getItem(key) as string) : '';
  } 

  public getUsername(){
    return (this.storage.getItem('UserData')) ? (JSON.parse(this.storage.getItem('UserData') as any) as any).CombinedName : '';
  }
  
  public getLoginname(){
    console.log(JSON.parse(this.storage.getItem('UserName') as any));
    return (this.storage.getItem('UserName')) ? (JSON.parse(this.storage.getItem('UserName') as any) as any) : '';
  }

  public getRole(){
    return (this.storage.getItem('UserRole')) ? (JSON.parse(this.storage.getItem('UserRole') as any) as any) : '';
  } 
}

const MFXLocalStorage = new SessionStorage();

export default MFXLocalStorage;
