const DoubleInputElement = props => {
    //DESIGNDATA -> DESIGNER
    //VALUE -> CUSTOMER
    let inputClasses = '';
    let inputID = 'input-' + props.designData.Key + '-' + props.uniqueIndex;
    let inputName = !isNaN(props.designData.Name) ? props.designData.Name : window.Dictionary.getTranslation(props.designData.Name);
    let inputValue = '';
    let inputValue2 = '';

    let readonly = props.designData.EditableStatus === 'never' || props.designData.EditableStatus === 'onlyNew' || props.designData.ReadOnly || props.designData.ReadOnly ? true : false;

    if (props.customerData) {
        inputValue = props.customerData[0];
        inputValue2 = props.customerData[1];
    }
    return (
        <div className={`mfx-input-element mfx-input-${props.designData.Type} ${inputClasses}`}>
            {!props.noLabel && props.designData.Label && (
                <label htmlFor={inputName}>
                    {!isNaN(props.designData.Label) ? window.Dictionary.getTranslation(props.designData.Label) : props.designData.Label}
                    {props.designData.Required && '*'}
                </label>
            )}
            <div className="mfx-input-element-wrapper">
                <input
                    style={props.disableMode ? { pointerEvents: 'none', marginRight: '30px' } : { marginRight: '30px' }}
                    id={inputID}
                    type={props.designData.Type}
                    value={inputValue}
                    placeholder={props.designData.Placeholder}
                    name={props.designData.Key}
                    disabled={Boolean(props.disableMode)}
                    min={props.designData.Min}
                    max={props.designData.Max}
                    readOnly={readonly}
                    step={props.designData.Step}
                    data-type={props.designData.DataType}
                    required={props.designData.Required}
                />
                <input
                    style={props.disableMode ? { pointerEvents: 'none' } : {}}
                    id={inputID}
                    type={props.designData.Type}
                    value={inputValue2}
                    placeholder={props.designData.Placeholder}
                    name={props.designData.Key2}
                    disabled={Boolean(props.disableMode)}
                    min={props.designData.Min}
                    max={props.designData.Max}
                    readOnly={readonly}
                    step={props.designData.Step}
                    data-type={props.designData.DataType}
                    required={props.designData.Required}
                />
            </div>
        </div>
    );
};

export default DoubleInputElement;
