import { useEffect, useState } from 'react';

import Loading from 'Components/Lib/Elements/Loading';
import SmallHeadline from 'Components/Lib/Elements/SmallHeadline';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';
import URLS from '../Utils/URLs.API';
import InputElement from '../Elements/InputElement';

interface IListItems {
    Time?: string;
    ListItemName?: string;
    Action?: string;
    Tasknumber?: string | number;
    Taskdesc?: string;
}

/**
 * Renderes a List
 * @param {any} props Properties given by the caller
 * @returns {JSX.Element} Rendered Element.
 */
function AppointmentListView(props: any): JSX.Element {
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [list, setList] = useState({ Data: [], Meta: {} });

    //TODO
    //load todays Appointments from Outlook
    //from - props.Listtype, today
    useEffect(() => {
        let start = new Date(fromDate.setHours(0, 0, 0));
        let end = new Date(toDate.setHours(23, 59, 59));
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetAppointments + '?StartDate=' + start.toISOString() + '&EndDate=' + end.toISOString()).then(data => {
            if (data.Meta.Success && data.Data) {
                setList({ ...list, Data: data.Data as any, Meta: data.ListMeta });
            }
        });
    }, [fromDate, toDate]);

    function toDateFormat(input) {
        if (input === null || input === undefined) input = new Date();
        return new Date(input).toJSON().slice(0, 10);
    }

    function getCategoryColor(category) {
        switch (category) {
            case 'Blaue Kategorie':
                return 'rgb(39, 115, 186)';
            case 'Gelbe Kategorie':
                return 'yellow';
            case 'Grüne Kategorie':
                return 'rgb(121, 172, 93)';
            case 'Lila Kategorie':
                return '#9a5b92';
            case 'Orangefarbene Kategorie':
                return 'orange';
            case 'Rote Kategorie':
                return 'red';
            default:
                return '';
        }
    }
    return (
        <div className="mfx-view mfx-listview">
            {<SmallHeadline designData={{ Headline: 14198, Subheadline: window.Dictionary.getTranslation(14214) + ' ' + fromDate.toLocaleDateString('de') + ' - ' + toDate.toLocaleDateString('de') }} />}
            <div className='mfx-from-to-date-wrapper'>
                <InputElement id="date-for" customerData={fromDate} designData={{ Type: 'date', Defaultvalue: fromDate, Key: 'ChangeDate', Label: 1586}} disableMode={false} onChangeHandler={e => setFromDate(new Date(e.newValue))}></InputElement>
                <InputElement customerData={toDate} designData={{ Type: 'date', Defaultvalue: toDate, Key: 'ChangeDate', Label: 1587 }} disableMode={false} onChangeHandler={e => setToDate(new Date(e.newValue))}></InputElement>
            </div>
            {loading && <Loading title="ListView" />}
            {!loading && (
                <ul className="mfx-list appointment">
                    {list.Data.map((listitem: any, idx: any) => {
                        return (
                            <li key={idx}>
                                <span style={{ borderColor: getCategoryColor(listitem.Category) }}>
                                    {new Date(listitem.DateFrom)?.toLocaleDateString('de')}

                                    {new Date(listitem.DateFrom)?.toLocaleDateString('de') !== new Date(listitem.DateTo)?.toLocaleDateString('de') && '-' + new Date(listitem.DateTo)?.toLocaleDateString('de')}
                                </span>
                                <span>
                                    {listitem.DateFrom.substring(11, 19) !== '00:00:00' && new Date(listitem.DateFrom)?.toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit' })}
                                    {listitem.DateFrom.substring(11, 19) !== '00:00:00' && '-' + new Date(listitem.DateTo)?.toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit' })}
                                </span>
                                {/*<span style={{color: getCategoryColor(listitem.Category)}}> {listitem.Category}</span>*/}
                                <span> {listitem.FreeBusyStatus}</span>
                                <span> {listitem.Location ?? ''}</span>
                                <span className="bold">{listitem.Subject}</span>
                                {listitem.Action && <a href="/">{listitem.Action}</a>}
                            </li>
                        );
                    })}
                </ul>
            )}
            {!loading && list && list.Data.length <= 0 && <h4>{window.Dictionary.getTranslation(14199)}</h4>}
        </div>
    );
}

export default AppointmentListView;
