import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { viewActions } from '../../../../Helpers/Stores/viewStore';
import Button from '../Elements/Button';
import GenericElement from '../Elements/GenericElements';
import { RootState } from '../GenericViewEditor';

export default function ElementsView(props: any) {
    const dispatch = useDispatch();
    const viewData = useSelector((state: RootState) => state.viewData);


    function onAddElement(e: any, dataId: any, elId: any) {
        let x = (viewData.CustomElements[dataId] as any).Data[elId].length;
        props.onSelectElement(e, { groupKey: dataId, colKey: elId, rowKey: x });
        dispatch(viewActions.addElement({ dataId: dataId, elId: elId, newEl: { Elementtype: 'text', Type: 'p' } }));
    }

    let target: any;
    let shadow: any;

    function dragStart(e: any,index: any) {
        shadow = {target:e.target,index:index};
    }
    function dragOver(e: any,index : any) {
        e.preventDefault();
        var prev = target;
        target = {target: e.target, index: index};
        for (let c = 0; c < 3; c++) {
            if (target.target.className === 'mfx-generic-element' && target.target.draggable === true) break;
            target.target = target.target.parentNode;
        }
        if (prev && prev.target !== target.target) {
            prev.target.style.borderTop = '';
            prev.target.style.borderBottom = '';
        }
        if (target.target !== shadow.target) {
            target.target.style[target.target.clientHeight / 2 > e.clientY - target.target.offsetTop ? 'borderTop' : 'borderBottom'] = '3px solid #58fa7c';
            target.target.style[target.target.clientHeight / 2 < e.clientY - target.target.offsetTop ? 'borderTop' : 'borderBottom'] = '';
        }
    }

    function dragEnd(e: any, index : any) {
        target.target.style.borderTop = '';
        target.target.style.borderBottom = '';
        if (target?.index !== '' && shadow?.index !== '') dispatch(viewActions.switchElements({ target: target.index, shadow: shadow.index, insertBefore: target.target.clientHeight / 2 > e.clientY - target.target.offsetTop }));
        else alert('Drag and Drop failed since the object it was dropped on was not part of the Table');
    }


    function addElementGroup() {
        props.onSelectElement(null, { groupKey: props.data.CustomElements.length });
        dispatch(viewActions.addElementGroup());
    }

    function renderElements(curData: any) {
        return (
            <>
                {curData?.CustomElements &&
                    (curData?.CustomElements as any[]).map((e, idx:number) => {
                        //missing !! select curElement
                        return <GenericElement key={idx} designData={e as any} disableMode={true} index={idx} 
                                    onAddElement={onAddElement} 
                                    onElementClicked={props.onSelectElement} 
                                    onElementGroupClicked={props.onElementGroupClicked} 
                                    onDragEnd={dragEnd} 
                                    onDragStart={dragStart} 
                                    onDragOver={dragOver} 
                                    extraClass={{backgroundColor: 'var(--pageBg)'}}
                                    curElement={props.curElement}
                                     />;
                    })}
                <Button onClickHandler={addElementGroup} iconClass="icon-add" dll="15434" />
            </>
        );
    }
    return (
        <>
            <div className="mfx-gp-container">{renderElements(props.data)}</div>
        </>
    );
}
