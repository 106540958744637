/**
 * Renders only the Filterbutton
 * @returns {JSX.Element} The basic Filterbutton.
 */

 const Filterbutton = (props) => {
    

    return(
       <>
           <button type="button" className={props.filterMode ? 'active' : ''} onClick={props.setFilterMode} >
                <i className="icon-filter"></i>
           </button>
       </>
    );

};

export default Filterbutton;