import { useState } from 'react';
import {QrReader} from 'react-qr-reader';

const QrScanner = (props) => {
  const [error, setError] = useState(null);

  if (error) {
    return <div className='error'>{error}</div>;
  }

  function successfullScan(data) {

        let contactDataArray = data.text.split('\n');
        let contactData = {};


        for(let i = 0; i < contactDataArray.length; i++) {
            console.log(contactDataArray[i]);
            let curString = contactDataArray[i];

            if(curString.indexOf('N:') === 0){ //Lastname
                contactData[props.prefix +'Lastname']  = curString.substring(2, curString.indexOf(';'));
                if(curString.indexOf(';') > 0) //Firstname
                    contactData[props.prefix  +'Firstname'] = curString.substring(curString.indexOf(';') + 1);
            }
            else if (curString.indexOf('TITLE:') === 0) 
                contactData[props.prefix  +'AcademicDegree'] = curString.substring(6);
            else if (curString.indexOf('EMAIL;WORK;INTERNET:' || 'EMAIL;HOME;INTERNET:') === 0) {
                contactData[props.prefix  +'EmailAddress'] = curString.substring(20);
            }else if (curString.indexOf('ADR:') === 0) {
                curString = curString.split(';');
                contactData[props.prefix  +'Street'] =  curString[2] ?? '';
                contactData[props.prefix  +'City'] =  curString[3] ?? '';
                contactData[props.prefix  +'PostCode'] =  curString[5] ?? '';
                
                contactData[props.prefix  +'Country'] =  curString[6] ?? '';
            } else if (curString.indexOf('TEL;CELL:') === 0) 
                contactData[props.prefix  +'PhoneNumber'] = curString.substring(9);
            else if (curString.indexOf('TEL;HOME;VOICE:') === 0) 
                contactData[props.prefix  +'MobilePhoneNumber'] = curString.substring(15);
            
        }
    
        props.onSuccessfullScan(contactData);
  }

  return (
    <div className='qrcode-video'>
      <QrReader
        delay={300}
        onError={(error) => { setError(error.message);}}
        constraints={{ facingMode: 'environment' }}
        key='environment'
        onResult={(data) => { if (data)  successfullScan(data); }}
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default QrScanner;
