import { useEffect, useState } from 'react';

import Loading from 'Components/Lib/Elements/Loading';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';
import URLS from '../Utils/URLs.API';
import sessionedLink from '../Utils/sessionedLinks';
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import InputElement from '../Elements/InputElement';
import {AddViewProvider} from './AddView';
import { getPropertiesPerType } from 'Helpers/getPropertiesPerType';

export interface IDashboardProperties {
  Listtype: 'mytasks' | 'appointments';
}

interface IListItems {
  Time?: string;
  ListItemName?: string;
  Action?: string;
  Tasknumber?: string | number;
  Taskdesc?: string;
}

/**
 * Renderes a List
 * @param {IDashboardProperties} props Properties given by the caller
 * @returns {JSX.Element} Rendered Element.
 */
function LeadTasksView(props: IDashboardProperties): JSX.Element {
  const [loading, setLoading] = useState(true); // eslint-disable-line
  const [curTasks, setCurTasks] = useState(null as any);

  //getParams
  const [searchParams, setSearchParams] = useSearchParams();
  let leadId = searchParams.get('index');
  let customerId = searchParams.get('customer');
  
  const curProperties = getPropertiesPerType('LeadTasksView');

  useEffect(() => {
      LoadData(props.Listtype);
  
  },[]);
  
  function LoadData(type:string){
    setLoading(true);
    loadJsonData('/'+ LocalStorage?.getConnectionKey() + URLS.AllTasksData 
      + '?OnlyOwnTasks=false&FilterEntries=ProcessIndex=' + leadId).then(data => {
      if (data.Meta.Success && data.Data) {
          
          setCurTasks(data.Data);
          setLoading(false);
      }
    });
  }
  

  return (
    <>
    <div className='mfx-view'>   
      {loading && <Loading  />}
      {!loading && curTasks && curTasks.length > 0 && (
        <ul className='mfx-list tasklist'>
          {curTasks.sort((a, b) => a.Index > b.Index ? 1 : -1).map((task:any, idx:any) => {
            return (
              <div className='mfx-task-list-item'>
                <Link to={sessionedLink(`/processes/detailview?type=task&index=${task.Index}&customer=${task.Process.CustomerSupplierNumber}`)}>
                  <div className='tasklist-task-headline'>
                    <h3>{window.Dictionary.getTranslation(35)}: {task.PositionNumber}{task.PersonInChargeName && ' - ' + task.PersonInChargeName}</h3>
                    {/*<Checkbox designData={{Label:14, Name: 'Finished', Key: 'Finished'}} disableMode={true} customData={task.Finished}/> */}
                 
                    <div className={`success-${task.Finished} small`}>
                      <p>{window.Dictionary.getTranslation(14)}</p>
                      <div className='icon-style-arrow'>
                          {task.Finished && <i className='icon-check'></i>}
                          {!task.Finished && <i className='icon-close'></i>}
                      </div>
                    </div>
                  </div>
                  <div className='mfx-taskdates'>
                    <InputElement designData={{Label:1523, Name: 'LastEditDate', Key: 'LastEditDate', Type: 'datetime-local'}} disableMode={true} customerData={task.LastEditDate}/>
                    <InputElement designData={{Label: 5641, Name: 'EndTime', Key: 'EndTime', Type: 'datetime-local'}} disableMode={true} customerData={task.EndTime}/>
                  </div>
                  <InputElement designData={{Label:37, Name: 'Kategorie', Key: 'Categorie', Type: 'text'}} disableMode={true} customerData={task.Categorie}/>
                  <p className='mfx-task-list-text'>{task.TaskText}</p>
                  <p className='mfx-task-list-text'>{task.ProcessingText}</p>
                </Link>
              </div>
            );
          })}
        </ul>
      )}
      </div>
      
      {!loading && curTasks.length===0 &&
          <h4 className='nothing-found'>
            <div className="success-false extra-small"><div className="icon-style-arrow"><i className="icon-close"></i></div></div>
            {window.Dictionary.getTranslation(14261)} 
          </h4>
      }
      <div className='mfx-view-border'>
        <AddViewProvider 
          addLabel={14177}
          viewName='TaskAddView'
          layoutName="AddTaskToLead"
          //saveUrl={URLS.CreateTask}
          customer={customerId}
          fixCustomer={true}
          viewSettings={curProperties}
          >
        </AddViewProvider> 
      </div>
      

      
    </>
  );
}

export default LeadTasksView;
