import GenericView, { IView } from './GenericView';
import { IContainer } from './GenericContainer';

export interface IGroup {
  isGroup: true;
  groupId?: string;
  groupType: 'accordion' | 'tabs' | 'tabs-vertical' | 'list';
  groupHeadline?: string | number;
  groupSubheadline?: string | number;
  views: IView[];
}

/**
 * Renders an generic group
 * @param {IGenericPageProps} props Properties given by caller
 * @returns {JSX.Element} Rendered element
 */
export default function GenericGroup(props: { data: IContainer}): JSX.Element {
  /*
  ---------------- TABS ----------------
  */

  const changeActiveGroup = (event:React.MouseEvent) => {
    let curTarget = (event.target as Element);
    let parentgroupId = curTarget.closest('.mfx-gp-group')?.getAttribute('id');

    
    if(curTarget && parentgroupId) {
      document.querySelector('#' + parentgroupId)?.querySelector('.group-header.active')?.classList.remove('active');
      document.querySelector('#' + parentgroupId)?.querySelector('.mfx-group-content.active')?.classList.remove('active');

      let curTargetId = curTarget.getAttribute('id') ? curTarget.getAttribute('id') : 'id';
      curTarget.classList.add('active');
      document.querySelector('#' + parentgroupId)?.querySelector('[data-id="'+ curTargetId +'"]')?.classList.add('active');
    }
  };
  // view.finished();
  return (
    <div className='mfx-gp-view'>
      <div id={`${props.data.Group}-${props.data.Name}`} className={`mfx-gp-group mfx-group-type-${props.data.Group?.toLowerCase()}`}>
          
          
        {/*props.data.groupHeadline &&
            <Headline designData={{Headline: props.data.groupHeadline, Subheadline: props.data.groupSubheadline}} />
        */}
        {props.data.Group === 'Tabs' &&
          <div className="mfx-group-header">
            {
              Object.keys(props.data.children).map((key, idx) => {
                  return (
                  <button type='button' key={`tabheader-${idx}`} className={idx === 0 ? 'group-header active' : 'group-header test'} 
                        id={props.data.children[key].layoutName} 
                        data-id={props.data.children[key].ViewLabel }
                        onClick={changeActiveGroup}>
                          {window.Dictionary?.getTranslation(props.data.children[key].ViewLabel ? props.data.children[key].ViewLabel : props.data.children[key].layoutName)}</button>
                );})
            }
          </div>
        }
        {props.data.Group !== 'Accordion' &&
          Object.keys(props.data.children).map((key, idx) => (
            <GenericView key={idx} data={props.data.children[key]} dataid={props.data.children[key].layoutName} grouptype={props.data.Group} index={idx} />
          ))
        }


        {props.data.Group === 'Accordion' &&
            
            Object.entries(props.data.children).map((entry:any, idx) => {
                let view = entry[1];

                  return (
                    <div className='mfx-accordion-element'>
                      <button type='button' key={`accheader-${idx}`} className={idx === 0 ? 'group-header active' : 'group-header'} 
                        id={view.name} onClick={changeActiveGroup}>{view.ViewLabel}</button>
                      <GenericView key={idx} data={view} dataid={view.name} grouptype={props.data.Group} index={idx} />
                    </div>
                  );
              })
            
        }

        {/*view.finished()*/}
      </div>
    </div>
  );
}
