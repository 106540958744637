/**
 * Renders only the Editbutton
 * @returns {JSX.Element} The basic Editbutton.
 */

 const Editbutton = (props) => {
    

    return(
       <>
           {!props.disableMode && props.resetChanges &&
                <button type="button" className="mfx-button mfx-button-type-icon" onClick={props.resetChanges}>
                    <i className="icon-close"></i>
                </button>
           }{!props.disableMode &&
                <button type="button" className="mfx-button mfx-button-type-icon" onClick={(e) => props.deleteContainer(e)}>
                    <i className="icon-delete"></i>
                </button>
                
           }{props.disableMode && 
                <button type="button" className="mfx-button mfx-button-type-icon" onClick={(e) => props.disableChangeHandler(e, false)}>
                    <i className="icon-edit"></i>
                </button>
           }
       </>
    );
};

export default Editbutton;