import { Navigate, To } from 'react-router-dom';
import LocalStorage from '../../../Helpers/MFXLocalStorage';

/**
 * Changes the Link of Parameter to working Link
 * CurrentPath depends on the active Tab
 * @returns {JSX.Element} Navigate Link
 */

export default function LocalStorageNavigate(props: { to: To | string }) {
  const newTo ='/' + LocalStorage.getCurrentPath() +(props.to.toString().startsWith('/') ? '' : '/') + props.to;

  return <Navigate to={newTo} />;
}
