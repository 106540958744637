import ModalPopup from '../../Common/ModalPopup';

/**
 * Renders a SavedModal
 * @returns {JSX.Element} The styled Link.
 * @param {any} props given by caller
 */

export default function SavedModal(props) {

    return (
        <ModalPopup closeIcon={true} closePopup={props.handleClose}>
            <div className={`success-${props.saveMessage.success}`}>
                <div className='save-message'>
                    <div className='icon-style-arrow'>
                        {props.saveMessage.success && <i className='icon-check'></i>}
                        {!props.saveMessage.success && <i className='icon-close'></i>}
                    </div>
                    <p>{window.Dictionary.getTranslation(props.saveMessage.message)}</p>
                    {props.saveText && <p>{props.saveMessage.saveText}</p> }
                </div>
            </div>
        </ModalPopup>
    );
}
