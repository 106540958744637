import { NavLink, To } from 'react-router-dom';
import sessionedLink from 'Components/Lib/Utils/sessionedLinks';

export interface IMenuItem {
  label: string;
  className?: string;
  link: string | To;
  children?: IMenu;
  customlabel? : string;
}

export interface IMenu {
  className?: string;
  entries: IMenuItem[];
}
export interface IGenericMenuProperties {
  data: IMenu;
}

/**
 * Renders the Menu from properties.
 * @param {IGenericMenuProperties} props entries and classname passed by TabMenu.
 * @returns {JSX.Element} Rendered component
 */


export default function GenericMenu(props: IGenericMenuProperties) {

  function closeMobileMenu() {
      if(window.innerWidth <= window.mobileBreakPoint)
        document.getElementById('menu')?.classList.remove('active');
  }
  return (
    <ul className={props.data.className || ''}>
      {props.data.entries.map((menuitem, mainindex) => (
        <li key={`menu-item-${mainindex}`} className={menuitem.className || ''}>
            <NavLink to={sessionedLink(menuitem.link)} onClick={closeMobileMenu}>{menuitem.customlabel ?? window.Dictionary?.getTranslation(menuitem.label)}</NavLink>
          
          {menuitem?.children?.entries?.length && (
            <GenericMenu data={menuitem.children} />
          )}
        </li>
      ))}
    </ul>
  );
}
