import LocalStorage from '../../../Helpers/MFXLocalStorage';
import { useEffect, useState } from 'react';
import loadJsonData from '../Utils/loadJsonData';
import URLS from '../Utils/URLs.API';

interface IReportsView {
  designer : IReportsViewData;
}
  
interface IReportsViewData {
  Tablehead? : any;
  Data: Object;
}



function ReportsView(): JSX.Element {
  const [link,setLink] = useState('');

  useEffect(() => {
    loadData();
  },[]);
  function loadData(){
    loadJsonData('/'+ LocalStorage?.getConnectionKey() + URLS.Reports).then(data => {
      console.log(data);
      if (data.Meta.Success && (data).Data) {
          setLink((data.Data as any).Link as string);
        }
    });
  }


  return (
    <div id='mfx-reports' className='mfx-view'>
      <div className='mfx-grid-container'>
        <div className='mfx-gp-view'>
          {link && 
            <a href={link} className='mfx-button mfx-report' target='_blank' rel="noreferrer">Open Reports</a>
          }{!link &&
            <p>{window.Dictionary.getTranslation(14937)}</p>

          }
          
        </div>
        
      </div>
    </div>
  );
}

export default ReportsView;