import { Route, useParams } from 'react-router-dom';
import Settingsview from '../Components/Lib/Designer/Views/Settingsview';
import { getMenuEntry } from './Pages';

const Settings = (
    <Route path=":viewName" element={<ViewByName/>} />
);

export default Settings;


//Important to wrap Provider over the Element

function ViewByName():JSX.Element {
    const { viewName } = useParams<{ viewName: string }>();
    let entry = getMenuEntry(viewName as string);
    console.log(entry);

    return (
        <Settingsview name={viewName} menuData={entry} />
    );
}