/**
 * Interface for the data response given from the back-end. The generic type-parameter is therefore placeholder for the real data interface,
 */
 export interface ApiResult<T> {
    Data: T;
    /** Some additional information beside the main response data. */
    Meta: {
      /** True when the request succeeded.  */
      Success: boolean;
      /** Contains all error-messages to display. */
      ErrorMessages: any[] | null;
      /** Contains all error-messages to display. */
      ConnectionKey : string;
      /** contains the time [ms] used by the database to generate the response data. */
      TimingDatabase: number;
      /** contains the time [ms] used from receiving the request on server side til the response has started sending back. */
      TimingServer: number;
      /** contains the time [ms] used from starting the request til it has been parsed. */
      TimingTotal: number;
    };
  }
  
  /**
   * Loads an URI and returns the resulting data.
   * @param {string} url URL for the request.
   * @param {Object} sendData Data which should be send.
   * @param {string | number | null} id Id if necessary.
   * @returns {Promise<ApiResult<T>>} Result of the request.
   */
  export default async function loadJsonDelete<T = unknown>(url: string): Promise<ApiResult<T>> {
    // store time of beginning
    const timeBegin = performance.now();
  
    //console.log(sendData);
    const settings = {
      method: 'DELETE',
      headers: {
          Accept: 'text/plain',
          'Content-Type': 'application/json-patch+json',
      },
      //body: JSON.stringify(sendData)
  };
    // make request
    const response = await fetch(url, settings);
  
    // start API request
    const data = (await response.json()) as ApiResult<T>;
    if(!data.Meta.Success){
      console.log(data.Meta.ErrorMessages ? data.Meta.ErrorMessages[0] : '');
      alert(data?.Meta?.ErrorMessages ? data?.Meta?.ErrorMessages[0].Message : '');
    }
    // save total timing-data into response.Meta
    data.Meta.TimingTotal = performance.now() - timeBegin;
  
    // finally: return data
   
    return data;
  }
  