import { useEffect, useState } from 'react';

import Loading from 'Components/Lib/Elements/Loading';
import SmallHeadline from 'Components/Lib/Elements/SmallHeadline';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';
import URLS from '../Utils/URLs.API';
import sessionedLink from '../Utils/sessionedLinks';
import { Link, NavLink } from 'react-router-dom';
import InputElement from '../Elements/InputElement';

export interface IDashboardProperties {
    Listtype: 'mytasks' | 'appointments';
}

interface IListItems {
    Time?: string;
    ListItemName?: string;
    Action?: string;
    Tasknumber?: string | number;
    Taskdesc?: string;
}

/**
 * Renderes a List
 * @param {IDashboardProperties} props Properties given by the caller
 * @returns {JSX.Element} Rendered Element.
 */
function TasksListView(props: IDashboardProperties): JSX.Element {
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [list, setList] = useState(null as any);
    const [listMeta, setListMeta] = useState(null as any);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    //TODO
    //load todays Appointments / Tasks
    //from - props.Listtype, today
    useEffect(() => {
        setLoading(true);

        loadJsonData(
            '/' +
                LocalStorage?.getConnectionKey() +
                URLS.AllTasksData +
                '?OnlyOwnTasks=true&Paging.Page=' +
                1 +
                '&Paging.PageSize=' +
                5 +
                '&Paging.SortingColumn=Index&FilterEntries=(Finished = 0 AND End>= "' +
                fromDate.toISOString().substring(0, 10) +
                '" AND END <= "' +
                toDate.toISOString().substring(0, 10) +
                '")'
        ).then(data => {
            if (data.Meta.Success && data.Data) {
                setList(
                    (data.Data as any).map((d: any) => {
                        return { Tasknumber: d.Index, ListItemName: d.PersonInChargeName, Taskdesc: d.TaskText /*, Subject : d.Process.Subject*/ };
                    })
                );
                setListMeta(data.ListMeta);
                setLoading(false);
            }
        });
    }, [fromDate, toDate]);

    return (
        <div className="mfx-view mfx-listview">
            <SmallHeadline
                designData={{
                    Headline: 14200,
                    Subheadline: window.Dictionary.getTranslation(14214) + ' ' + fromDate.toLocaleDateString('de') + ' - ' + toDate.toLocaleDateString('de'),
                }}
            />

            <div className='mfx-from-to-date-wrapper'>
                <InputElement customerData={fromDate} designData={{ Type: 'date', Defaultvalue: fromDate, Key: 'ChangeDate', Label: 1586 }} disableMode={false} onChangeHandler={e => setFromDate(new Date(e.newValue))}></InputElement>
                <InputElement customerData={toDate} designData={{ Type: 'date', Defaultvalue: toDate, Key: 'ChangeDate',  Label: 1587 }} disableMode={false} onChangeHandler={e => setToDate(new Date(e.newValue))}></InputElement>
            </div>
            {loading && <Loading title="ListView" />}
            {!loading && list && list.length > 0 && (
                <table className="mfx-list tasklist">
                    {list.map((listitem: any, idx: any) => {
                        return (
                            <tr key={idx}>
                                <td>
                                    <Link to={sessionedLink(`/processes/detailview?type=task&index=${listitem.Tasknumber}`)}>{listitem.Tasknumber || listitem.Time}</Link>
                                </td>
                                <td className="bold">{listitem.ListItemName}</td>
                                {listitem.Action && <td>{listitem.Action}</td>}
                                {listitem.Taskdesc && (
                                    <td>
                                        <Link to={sessionedLink(`/processes/detailview?type=task&index=${listitem.Tasknumber}`)}>{listitem.Taskdesc.length > 80 ? listitem.Taskdesc.substr(0, 80) + '...' : listitem.Taskdesc}</Link>
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </table>
            )}
            {!loading && list && list <= 0 && (
                <h4 className="nothing-found">
                    <div className="success-false extra-small">
                        <div className="icon-style-arrow">
                            <i className="icon-close"></i>
                        </div>
                    </div>
                    {window.Dictionary.getTranslation(14259)}
                </h4>
            )}
            {listMeta?.EntriesCount > 0 && (
                <NavLink className="mfx-button center" to={sessionedLink('/processes/tasks?filter_TasksView_default' /*=(End = "' + date.toISOString().substring(0, 10) + '")'*/)}>
                    {window.Dictionary.getTranslation(1522)} ({listMeta?.EntriesCount})
                </NavLink>
            )}
        </div>
    );
}

export default TasksListView;
