export default function Loading(props) {
    return (
        <>
            <div data-title={props.title} className="mfx-loading">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    );
}
