import { useEffect, useState } from 'react';

import MFXLocalStorage, { ILocal } from 'Helpers/MFXLocalStorage';
import GenericMenu, { IMenu } from './GenericMenu';
import URLS from '../../../Lib/Utils/URLs.API';
import Loading from 'Components/Lib/Elements/Loading';
import loadJsonData from 'Components/Lib/Utils/loadJsonData';
import menuMain from 'Data/menu_main.json';
import menuCustomer from 'Data/menu_customer.json';

/**
 * Get Menu of Type of Tab 
 * @returns {JSX.Element} Rendered component
 */


export default function TabMenu() {
    const tabData = MFXLocalStorage.getTabData() as ILocal;   //get Data of current Tab
    const [menuData, setMenuData] = useState(null);

    useEffect(() => {
      if(MFXLocalStorage.getConnectionKey()){  
        let type;
        let defaultMenu;

        let mobile = window.innerWidth <= window.mobileBreakPoint ? '-mobile' : '';

        switch (MFXLocalStorage.getTabData()?.primaryType || null) {
            case 'customer':
                type = 'customer' + mobile;
                defaultMenu = menuCustomer;
                break;
            case 'offer':
                type = 'offer' + mobile;
                break;
            default:
                type = 'default' + mobile;
                defaultMenu = menuMain;
                break;
        }
        loadJsonData('/' + MFXLocalStorage.getConnectionKey() + URLS.GetBusinessProcess + 'CRM&PageLayout=' + type).then((data:any) => {
          if (data.Meta.Success && data.Data) {
            setMenuData(data.Data.Json);
          }
          else {
            setMenuData(defaultMenu);
          }
        });
      }
    }, [tabData?.primaryType]);

    return (
        <nav id="menu">
            {menuData && <GenericMenu data={menuData} />}
            {!menuData && MFXLocalStorage.getConnectionKey() && <Loading title="TabMenu" />}
        </nav>
    );
}
