import sessionedLink from '../Utils/sessionedLinks';

function Popup(props) {
    function calcBottom() {
        var init = -60;
        var res = init + 165 * props.index;
        return res < window.screen.height * 0.7 ? res : window.screen.height;
    }
    function openPopup() {
        var link = sessionedLink(`/processes/detailview?type=${'task'}&index=${'8445'}`);
        document.location = link;
    }

    return (
        <div style={{ bottom: calcBottom() }} className="popup-inner">
            <button className="close-btn" onClick={() => props.closePopup(props.popup.id)}>
                <i className="icon-close"></i>
            </button>
            <header>
                <h2>{props.popup.title}</h2>
            </header>

            <p className="popup-content">{props.popup.description}</p>

            <button className="read-btn" onClick={() => props.closePopup(props.popup.id)}>
                Read
            </button>
            <button
                className="open-btn"
                onClick={() => {
                    openPopup();
                }}>
                Open
            </button>
        </div>
    );
}

export default Popup;
