import { Route, useParams } from 'react-router-dom';
import { GenericViewProvider } from '../Components/Lib/Designer/GenericViewEditor';
import { getMenuEntry } from './Pages';

const Views = (
    <Route path=":viewName" element={<ViewByName/>} />
);

export default Views;


//Important to wrap Provider over the Element

function ViewByName():JSX.Element {
    const { viewName } = useParams<{ viewName: string }>();
    let entry = getMenuEntry(viewName as string);
    
    return (
        <GenericViewProvider name={viewName} menuData={entry}/>
    );
}