/**
 * Renders an generic text input field
 * @returns {JSX.Element} The basic text input.
 * @param {any} props given by caller
 */

const ListInputElement = props => {
    //DESIGNDATA -> DESIGNER
    //VALUE -> CUSTOMER
    let inputClasses = '';
    let inputValue = '';

    if (props.designData.Type.toLowerCase() === 'text' || props.designData.Type.toLowerCase() === 'tel' || 
    props.designData.Type.toLowerCase() === 'email') {
        inputValue = props.customerData ? props.customerData : props.designData?.Defaultvalue ? props.designData?.Defaultvalue : '';
    } else if (props.designData?.Defaultvalue === 'today') {
        inputValue = getTodaysDate();
    } else {
        inputValue =
            props.designData.Type.toLowerCase() === 'number'
                ? Math.round((props.customerData ?? props.designData?.Defaultvalue ?? 0) * 100 ) / 100
                : props.designData.Type.toLowerCase() === 'datetime-local'
                ? toDateTimeFormat(props.customerData ?? props.designData?.Defaultvalue)
                : props.designData.Type.toLowerCase() === 'date'
                ? toDateFormat(props.customerData ?? props.designData?.Defaultvalue)
                : props.customerData ?? props.designData?.Defaultvalue;
    }

    


    if(props.designData.Key === 'Priority' || props.designData.Key === 'Data-Priority') {
        inputClasses += 'priority_' + inputValue + ' ';
        
        switch(inputValue){
            case '':
            case 0: inputValue = window.Dictionary.getTranslation(1408); break;
            case 1: inputValue = window.Dictionary.getTranslation(1409); break;
            case 2: inputValue = window.Dictionary.getTranslation(1410); break;
            case 3: inputValue = window.Dictionary.getTranslation(4955); break;
            default:
                break;
        }
        props.designData.Type = 'text';
        
    }

    function getTodaysDate(hour = 0) {
        let date = new Date();
        let month = new Date().getMonth() + 1;

         if(props.designData?.Type === 'date' )
            return date.getFullYear() + '-' + addZero(month) + '-' + addZero(date.getDate());
        else {
            let nhour = date.getHours() + hour;
            nhour = nhour <= 24 ? nhour : nhour - 24; 
            return date.getFullYear() + '-' + addZero(month) + '-' + addZero(date.getDate()) + ' ' + addZero(nhour) + ':' + addZero(date.getMinutes());
        }
    }

    function addZero(number) {
        return number > 9 ? number : '0' + number;
    }

    function toDateFormat(input) {
        if(input === null || input === undefined){
            return '';
        }
        let date = new Date(input.substring(0, 16));

        return date.toLocaleDateString('de-DE');
    }
    
    function toDateTimeFormat(input) {
        if(input === null || input === undefined){
            return '';
        }
        let date = new Date(input.substring(0, 16));

        return date.toLocaleDateString('de-DE');    
    }
    return (
        <div className={`mfx-input-element mfx-input-${props.designData.Type} ${inputClasses}`}>
            <div className='mfx-input-element-wrapper'>
                <p> {inputValue}</p>
            </div>
        </div>
    );
};

export default ListInputElement;
