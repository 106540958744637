/**
 * Renders a Data Chart
 * @returns {JSX.Element} The Data Chart
 */

const DataChart = props => {
    return (
        <div className="mfx-grid-container dataset-chart">
            {props?.chart?.Data?.map((d, idx) => {
                return (
                    <div key={idx} className={`mfx-col-${d.size ?? '12'}`}>
                        <div className="dataset-chart-container" style={{ color: d.color ?? 'var(--fontColor)' }}>
                            <div STYLE={d.valuestyling}>{d.value}</div>
                            <span STYLE={d.idstyling}>{d.id}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default DataChart;
