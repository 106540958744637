/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Fragment, useEffect, useState } from 'react';
import '../../../Styles/Lib/pagination.scss';

const Pagination = props => {
    const { active, size, step, onClickHandler, onPageSizeChangeHandler } = props;
    let showingNumbers = step * 2 + 1 < size - 1 ? step * 2 + 1 : size - 2 > 0 ? size - 2 : 0;
    let startNumber = 2;
    let startArrayNumber = props.step;
    let needStartDots = false;
    let needEndDots = false;
    const [showInput, setShowInput] = useState(true);
    useEffect(() => {
        document.getElementById('setpage-' + props.index).value = active;
    }, [active]);

    if (active > step) {
        startArrayNumber = active - step;
        needStartDots = active > step + startNumber && size - 2 !== showingNumbers ? true : false;
    }

    if (size > showingNumbers) {
        needEndDots = size > active + step + 1 && size - 2 !== showingNumbers ? true : false;

        if (size < active + step + 1) {
            startArrayNumber = size - showingNumbers;
        }
    }
    function setPage(e) {
        var pageNum = null;
        if (e.type !== 'click' && e.nativeEvent.key === 'Enter') {
            pageNum = e.target.value;
        } else if (e.type === 'click') {
            pageNum = document.getElementById('setpage-'+props.index).value;
        }

        if (!isNaN(pageNum) && +pageNum >= 1 && +pageNum <= size) {
            onClickHandler(pageNum);
        }
    }

    let contentNumber;
    return (
        <div className="mfx-action-pagination">
            <div className="mfx-result-count">
                {props.entries} {window.Dictionary.getTranslation(501)}
            </div>
            <div className="mfx-page-arrows">
                <ul className="pagination">
                    {active > 1 ? <li className="page-item prev icon-arrow-left" onClick={() => onClickHandler(active - 1)}></li> : <li className="page-item prev icon-arrow-left disabled"></li>}

                    <Fragment>
                        {
                            <>
                                <li onClick={active === 1 ? () => {} : e => onClickHandler(e.currentTarget.textContent)} className={`page-item ${active === 1 && 'active'}`}>
                                    1
                                </li>
                                {needStartDots && <span>...</span>}
                                {[...Array(showingNumbers)].map(i => (
                                    <li
                                        key={i++}
                                        {...(contentNumber = needStartDots ? startArrayNumber : startNumber)}
                                        {...startNumber++}
                                        {...startArrayNumber++}
                                        onClick={active === contentNumber ? () => {} : e => onClickHandler(e.currentTarget.textContent)}
                                        className={`page-item ${active === contentNumber && 'active'} ${contentNumber}`}>
                                        {contentNumber}
                                    </li>
                                ))}
                                {needEndDots && <span>...</span>}
                                {size > 1 && (
                                    <li onClick={active === size ? () => {} : e => onClickHandler(e.currentTarget.textContent)} className={`page-item ${active === size && 'active'}`}>
                                        {size}
                                    </li>
                                )}
                            </>
                        }
                    </Fragment>
                    {active < size ? <li className="page-item next icon-arrow-right" onClick={() => onClickHandler(active + 1)}></li> : <li className="page-item next icon-arrow-right disabled"></li>}
                </ul>
                <div className="mfx-dropdown-element">
                    <select defaultValue={props.pageSize} onChange={e => onPageSizeChangeHandler(e.target.value)}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
            </div>
            {showInput === true && (
                <div className="mfx-page-input">
                    <div>{window.Dictionary.getTranslation(1102)}</div>
                    <div className="mfx-input-element mfx-input-text">
                        <input className="mfx-setpage-input" id={'setpage-' + props.index} type="text" defaultValue={active} onKeyDown={e => setPage(e)}></input>
                    </div>
                    <div className="page-size">/ {size}</div>
                    <button className="mfx-button small" onClick={e => setPage(e)}>
                        {window.Dictionary.getTranslation(14215)}
                    </button>
                </div>
            )}
        </div>
    );
};

export default Pagination;
