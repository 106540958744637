import React, { useState, useEffect } from 'react';
import GaugeChart from 'react-gauge-chart';


export function MFXGaugeChart(props) {
    //const [percentageVal, setPercentageVal] = useState((props.chart.Data[0].value / props.chart.Data[0].value));
    //console.log(('40,5903') / ('43,4804'));

    useEffect(() => {
        /*let pers = props.chart.Data[0].value / props.chart.Data[0].value; // props. .. */

        
        /*let int = setInterval(() => {
            setPercentageVal(props.chart.Data[0].value / props.chart.Data[0].value);
        }, 10500);

        return () => {
            clearInterval(int);
          };*/

    });

  return (
    <>
    {props.chart.Data.length && 
        props.chart.Data.map((chart) => {
            return(
                <>
                    <h2>{chart.label}</h2>
                    <GaugeChart className='mfx-gauge-chart' 
                        nrOfLevels={20} 
                        colors={['#d12d2d', '#eede34' , '#5BE12C']} 
                        textColor={'var(--fontColor)'}
                        percent={isNaN(parseInt(chart.value)) ? 0 : parseInt(chart.value) / 100} 
                    />
                </>
            );
        })
    }
    </>
  );
}
