import { Route, useParams } from 'react-router-dom';
import { GenericDetailViewProvider } from '../Components/Lib/Designer/GenericDetailViewEditor';
import { getMenuEntry } from './Pages';

const DetailViews = (
    <Route path=":viewName" element={<ViewByName/>} />
);

export default DetailViews;


//Important to wrap Provider over the Element

function ViewByName():JSX.Element {
    const { viewName } = useParams<{ viewName: string }>();
    let entry = getMenuEntry(viewName as string);
    
    return (
        <GenericDetailViewProvider name={viewName} menuData={entry}  />
    );
}