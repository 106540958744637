import GenericContainer, { IContainer } from './GenericContainer';
//import GridLayout from "react-grid-layout";
// import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { memo, useEffect, useState } from 'react';
import Loading from './Elements/Loading';
import ReactGridLayout, { WidthProvider } from 'react-grid-layout';

export interface IGenericPageData {
  name: string;
  containers: IContainer[];
}
interface IGenericPageProps {
  data: IGenericPageData;
}

/**
 * Renders an generic page
 * @param {IGenericPageProps} props Properties given by caller
 * @returns {JSX.Element} Rendered element
 */

const GridLayout = WidthProvider(ReactGridLayout);


export function GenericPage(props: any): JSX.Element {
  let rowMatrix = [] as any;
  
  if(props.data.containers?.length > 1) {
    props.data.containers.forEach((container) => {
      if( !rowMatrix[container.x])
        rowMatrix[container.x] = [] as any;
      rowMatrix[container.x].push({y : container.y, id : container.i, data: container});
    });
  }
  return (
    <>

       <div className='mfx-gp-page'>
          <div className='page-grid-layout'>
            {props.data.containers?.sort((a,b) => (a.y > b.y) ? 1 : ((a.y < b.y) ? -1 : 0)).map((c, idx) => (
                <GenericContainer key={idx} data={c} containersMatrix={rowMatrix} />
            ))}
          </div>
        </div>
    </>
  );
}

// memo not working
export const MemoGenericPage = memo(GenericPage);
export default memo(GenericPage);
