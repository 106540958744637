

/**
 * Interface for the data response given from the back-end. The generic type-parameter is therefore placeholder for the real data interface,
 */
 export interface ApiResultDelete<T> {
  Meta: {
    /** True when the request succeeded.  */
    Success: boolean;
    /** Contains all error-messages to display. */
    ErrorMessages: [] | null;
    /** contains the time [ms] used by the database to generate the response data. */
    TimingDatabase: number;
    /** contains the time [ms] used from receiving the request on server side til the response has started sending back. */
    TimingServer: number;
    /** contains the time [ms] used from starting the request til it has been parsed. */
    TimingTotal: number;
  };
  DeleteRecord: number;
}


/**
 * Loads an URI and returns the resulting data.
 * @param {string} url URL for the request. 
 * @returns {Promise<ApiResultDelete<T>>} Result of the request.
 */
export default async function deleteData<T = unknown>(url: string): Promise<ApiResultDelete<T>> {  
  // store time of beginning
  const timeBegin = performance.now();
  // make request
  const response = await fetch(url, {method: 'DELETE'});
  // start API request

  const data = (await response.json()) as ApiResultDelete<T>;

  if(data.Meta.ErrorMessages !== null && data.Meta.ErrorMessages.length > 0){
    console.log('%c Error Occured! See ErrorMessages below.', 'background: #222; color: #bada55');
    console.log(data.Meta.ErrorMessages);
    //alert(data.Meta.ErrorMessages);
  }
  // save total timing-data into response.Meta
  data.Meta.TimingTotal = performance.now() - timeBegin;

  // finally: return data
  return data;
}
