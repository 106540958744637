/**
 * Renders an generic hidden input field
 * @returns {JSX.Element} The basic text input.
 * @param {any} props given by caller
 */

 const HiddenInput = (props) => {
  
    let inputID = (!isNaN(props.designData) ? window.Dictionary.getTranslation(props.designData) : props.designData);
     inputID = 'input-' + inputID.replace(/\s/g, '-').toLowerCase();

    return (
      <div className='mfx-input-element mfx-input-hidden'>
        <input 
          id={inputID + props.index}
          type='hidden'
          value={props.customerData}
          name={props.index}
        />
      </div>
    );
  };
  
  
  export default HiddenInput;