/*
* Storage for all Customers
* Customer List
*/

import { createSlice, configureStore, current } from '@reduxjs/toolkit';


const menuSlice = createSlice ( {
    name : 'menu',
    initialState : {
        menuData  : {
            entries : []
        },
        defaultMenuData : {
            entries : []
        }
    },
    reducers: {
        init(state, action) { //sets initial Data            
            if(action.payload.menuData)
                state.menuData = action.payload.menuData;
            if(action.payload.defaultMenuData)
                state.defaultMenuData = action.payload.defaultMenuData;
        },
        setOrder(state, action) {

            const copyListItems = [...state.menuData.entries]; //only one row at this time
            const dragItemContent = copyListItems[action.payload.shadow];
            copyListItems.splice(action.payload.shadow, 1);
            copyListItems.splice(action.payload.target, 0, dragItemContent);

            state.menuData.entries = copyListItems;
        },
        changeData(state, action) {

            if(action.payload.newValue)
                state.menuData.entries.push({label: action.payload.label, link: action.payload.link});
            else{
                let array = [];
                state.menuData.entries.forEach(el =>  { 
                    console.log(el);
                    if (el.label !== action.payload.label) array.push(el);
                });
                state.menuData.entries = array;
            }
        },
        addCustomValue(state, action) {
            /* ---------- ADD CUSTOM Menu Labels ------- */ 
            state.menuData.entries.forEach(el =>  { 
                if (el.label === action.payload.designData.Label) {
                    if(action.payload.newValue == '') delete el.customlabel;
                    else el.customlabel = action.payload.newValue;
                }     
            });
        },
        saveViewData(state) {
            state.savedViewData = state.viewData;
        },
        resetViewData(state) {
            state.viewData = state.savedViewData;
        },
        switchOrder(state, action){
            console.log(action.payload);            
        },
        removeElement(state,action){
            state.viewData.ElementsOrder[0].splice(action.payload.key,1);
        }
    }
});


const store = configureStore({
    reducer: menuSlice.reducer
});
export const menuActions = menuSlice.actions;
export default store;
