import { Route, useParams } from 'react-router-dom';
import {ClientMenuProvider} from '../Components/Lib/Designer/Views/ClientMenus';
import { getMenuEntry } from './Pages';

const Menus = (
    <Route path=":viewName" element={<ViewByName/>} />
);

export default Menus;

//Important to wrap Provider over the Element

function ViewByName():JSX.Element {
    const { viewName } = useParams<{ viewName: string }>();
    let entry = getMenuEntry(viewName as string);
    
    return (
        <ClientMenuProvider name={viewName} menuData={entry}  />
    );
}