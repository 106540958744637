import URLS from './URLs.API';
import LocalStorage from '../../../Helpers/MFXLocalStorage';
import loadJsonPost from './loadJsonPost';

export interface ILogger {
    Message: string | [];
    Level: 'Trace' | 'Debug' | 'Information' | 'Warning' | 'Error' | 'Critical';
}

/**
 * Enables the caller with an unified output to log data.
 * @param {string} name Name of the component requesting the logger.
 */
export default class PLXLogger {
    public warning(m: string) {
        this.saveClientLogger({ Message: m, Level: 'Warning' });
    }

    public error(m: any) {
        if (typeof m === 'object') m = m[0];
        this.saveClientLogger({ Message: m, Level: 'Error' });
    }

    public critical(m: string) {
        this.saveClientLogger({ Message: m, Level: 'Critical' });
    }

    public trace(m: string) {
        this.saveClientLogger({ Message: m, Level: 'Trace' });
    }

    public information(m: string) {
        this.saveClientLogger({ Message: m, Level: 'Information' });
    }

    //Send to Backend when neccessary
    public debug(m: string) {
        //this.saveClientLogger({Message: m, Level : 'Debug'});
    }

    //Only Console Logging - never send to Backend
    public test(m: string) {}

    private saveClientLogger(LoggingObj: ILogger): void {
        if (LocalStorage.getConnectionKey()) {
            loadJsonPost('/' + LocalStorage.getConnectionKey() + URLS.Logging, LoggingObj, null, true)
                .then((data: any) => {
                    this.test(data);
                })
                .catch(e => {
                    this.test(e);
                    this.test('/' + LocalStorage.getConnectionKey() + URLS.Logging);
                });
        }
    }
}
