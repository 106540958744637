import CustomerDetailView from './CRM/CustomerDetailView';
import CustomerGeneralView from './CRM/CustomerGeneralView';
import CustomerVisitView from './CRM/CustomerVisitView';
import Dashboard from './CRM/Dashboard';

import FreeView from './CRM/FreeView';
import ReportsView from './CRM/ReportsView';
import loadJsonData from './Utils/loadJsonData';
import URLS from './Utils/URLs.API';
import LocalStorage from '../../Helpers/MFXLocalStorage';
import { useEffect, useState } from 'react';
import AppointmentListView from './CRM/AppointmentListView';
import TasksListView from './CRM/TasksListView';
import GenericDetailView from './CRM/GenericDetailView';
import LeadTasksView from './CRM/LeadTasksView';
import OfferPositionsView from './CRM/OfferPositionsView';
import UserView from './CRM/UserView';
import ModalOverlay from './Common/ModalOverlay';
import Loading from './Elements/Loading';
import NotesView from './CRM/NotesView';
import TableView from './CRM/TableView';
import ItemView from './CRM/ItemView';

export interface IView<T_SETTINGS = unknown> {
    isGroup?: false;
    title?: string | number;
    name?: string;
    viewType: string;
    settings?: T_SETTINGS;
    layoutName?: string;
}

/**
 * Renders an generic view
 * @param {IGenericPageProps} props Properties given by caller
 * @returns {JSX.Element} Rendered element
 */
export default function GenericView(props: any /*{ data: IView; dataid?: string; grouptype?: string; index?: number; id?: number}*/): JSX.Element {
    const [designer, setDesigner]: any = useState(null);
    const [dataLoaded, setDataLoaded]: any = useState(false);

    const className = props.grouptype ? `mfx-group-content ${props.index === 0 ? 'active' : ''}` : 'mfx-gp-view';
    useEffect(() => {
        setDesigner(null);
        setDataLoaded(false);

        if (props.data.viewType === 'DetailView') props.data.layoutName = 'default';
        else if (props.data.viewType === 'NewOfferView') props.data.layoutName = 'newoffer';

        let mobile = window.innerWidth <= window.mobileBreakPoint ? '&IsMobile=true' : '';

        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.ViewLayouts + '?ViewName=' + props.data.viewType + '&LayoutName=' + props.data.layoutName + mobile).then((data: any) => {
            if (data.Data){
                setDesigner(data.Data);
                logger.debug(data.Data);
                setDataLoaded(true);
            }else if (data.Meta.Success) {
                setDataLoaded(true);
                setDesigner({}); //some Views don't need a Designer
            }
            /*else {
                loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.ViewLayouts + '?ViewName=' + props.data.viewType + '&LayoutName=default').then((data: any) => {
                    if (data.Data) {
                        setDesigner(data.Data);
                        props.data.layoutName = 'default';
                        setDataLoaded(true);
                    } else if (data.Meta.Success) {
                        setDataLoaded(true);
                        setDesigner({}); //some Views don't need a Designer
                    }
                    else logger.debug('zu dieser View gibt es leider kein Layout');
                });
            }*/
        });
    }, [props.data.viewType, props.data.layoutName]);
    return (
        <>
            {designer && (
                <div className={className} data-id={props.dataid}>
                    {getGenericViewContent(props.data.viewType, 
                        { designer: designer?.Json, ViewName: designer?.ViewName, LayoutName: props.data.layoutName, ViewType: designer?.ViewType }, props.containerdata, props)}
                </div>
            )}
            {designer === null && dataLoaded && <p>Layout not found</p>}
            {!dataLoaded && (
                <ModalOverlay loading="loading">
                    <Loading />
                </ModalOverlay>
            )}
        </>
    );
}

function getGenericViewContent(viewType: any, settings: any, containerData:any, props) {
    if (settings.ViewType === 'Custom' || settings.ViewType === 'StaticTable'  || !settings.ViewType) {

        //if there is no specific ViewType in Database
        switch (viewType) {
            case 'CustomerGeneralView':
                return <CustomerGeneralView {...settings} viewRendered={props.renderedCompletly} />;
            case 'CustomerDetailView':
                return <CustomerDetailView {...settings} />;
            case 'CustomerVisitView':
            case 'CustomerVisitDetailView':
                return <CustomerVisitView {...settings} />;
            case 'ReportsView':
                return <ReportsView />;
            case 'AppointmentListView':
                return <AppointmentListView {...settings} />;
            case 'LeadTasksView':
                return <LeadTasksView {...settings} />;
            case 'OfferPositionsView':
                return <OfferPositionsView {...settings} />;
            case 'ItemView':
                return <ItemView {...settings} />;
            case 'UserView':
                return <UserView {...settings} />;
            case 'MyTasksView':
            case 'TasksListView':
                return <TasksListView Listtype={settings.layoutName} />;
            case 'NotesView':
                return <NotesView {...settings} />;
        }
    }else {
        switch(settings.ViewType){
            case 'Table':
                return <TableView {...settings} />;
            case 'Detail':
                return <GenericDetailView {...settings} containerData={containerData} />;
            case 'Dashboard':
                return <Dashboard {...settings} viewRendered={props.renderedCompletly} />;
            case 'Free':
                return <FreeView {...settings} containerData={containerData} />;
        }
        return 'View not found';
    }
    
}
