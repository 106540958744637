/**
 * Renders an generic link
 * @returns {JSX.Element} The basic link.
 * @param {any} props given by caller
 */
 
 const Anchor = (props) => {
  return (
     <div className='mfx-text-element'>
       <a href={props.designData.Href || ((props.designData.Type === 'tel' ? 'tel:' : props.designData.Type === 'mail' ? 'mailto:' : '//' ) + (props.value ?? ''))} target={props.designData.Target ? props.designData.Target : '_self'}>{props.value ? props.value: props.designData.Href}</a>
     </div>
   );
 };
 
 
 export default Anchor;