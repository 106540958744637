/**
 * Renders a CustomerDetailView.
 * @returns {JSX.Element} Rendered component
 */
import {AddViewProvider} from './AddView';
import URLS from '../Utils/URLs.API';


function CustomerDetailView(props: any): JSX.Element {

    return (
        <>
            <AddViewProvider 
                viewName="CustomerVisitAddView" 
                viewSettings={{ needCustomer: true, createUrl: URLS.CreateTask, fixCustomer: true, addView: 'CustomerVisitAddView', noOverflow: true }}>
            </AddViewProvider>
        </>

      
    );
}

export default CustomerDetailView;
