/**
 * Renders an generic Headline
 * @returns {JSX.Element} The basic Headline.
 */

const Headline = (props) => {

    return(
        <div className="mfx-element-headline">
            {props.designData.Icon && (
                <div className="mfx-headline-icon" style={{ backgroundColor: props.designData.IconColor }}>
                    <i className={props.designData.Icon}></i>
                </div>
            )}
            <div>
                {props.designData.Headline && <h2 className="mfx-headline">{!isNaN(props.designData.Headline) ? window.Dictionary.getTranslation(props.designData.Headline) : props.designData.Headline}</h2>}
                {props.designData.Subheadline && <h3 className="mfx-subheadline">{!isNaN(props.designData.Subheadline) ? window.Dictionary.getTranslation(props.designData.Subheadline) : props.designData.Subheadline}</h3>}
            </div>
        </div>
    );

};

export default Headline;