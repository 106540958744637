let elements: [] = [];
elements.push({ Elementtype: 'text', Types: ['p', 'h2', 'h3', 'h4', 'h5', 'h6'], Settings: {Key: true, DefaultValue: true }} as never);
elements.push({ Elementtype: 'input', Types: ['text', 'password', 'number', 'range', 'datetime-local', 'date'], Settings: {Key: true, DefaultValue: true, Label: true, Placeholder: true} } as never);
elements.push({ Elementtype: 'dropdown', Settings: { Label: true, Key:true} } as never);
elements.push({ Elementtype: 'checkbox',Settings: {Key: true, DefaultValue: true, Label: true} } as never);
elements.push({ Elementtype: 'currency', Types: ['text', 'number'],Settings: {Key: true, DefaultValue: true, Label: true} } as never);
elements.push({ Elementtype: 'textarea', Settings: {Key: true, DefaultValue: true, Label: true, Placeholder: true}} as never);
elements.push({ Elementtype: 'link', Types: ['web', 'mail', 'tel'], Settings: {Key: true, Href:true,Target:true}} as never);
elements.push({ Elementtype: 'voicetotext', Settings: {Key: true, DefaultValue: true, Label: true, Placeholder: true} } as never);

elements.push({ Elementtype: 'elementgroup', Types: ['checkbox', 'input', 'preview'], ElementGroup: true } as never);
elements.push({ Elementtype: 'headline', ElementGroup: true, Settings: {Headline:true, Subheadline:true}  } as never);
elements.push({ Elementtype: 'smallheadline', ElementGroup: true, Settings: {Headline:true, Subheadline:true}  } as never);
elements.push({ Elementtype: 'mainheadline', ElementGroup: true, Settings: {Headline:true, Subheadline:true} } as never);

//elements.push({ Elementtype: 'quicklink', ElementGroup: true } as never);
// elements.push({ Elementtype: 'button' } as never);
// elements.push({ Elementtype: 'hiddeninput', Types: ['text', 'password', 'number', 'range'] } as never);
//elements.push({ Elementtype: 'linkicon' } as never);

// Settings: Headline, Subheadline, DefaultValue,Key, Href, Target, Label, Placeholder, Options

export default elements;
