/**
 * Renders an generic Headline
 * @returns {JSX.Element} The basic Headline.
 */

const Headline = (props) => {
    return(
        <div className="mfx-element-headline headline-main">
            <div className='mfx-headline-icon-container'>
                {props.designData.Icon && 
                    <div className='mfx-headline-icon' style={{ backgroundColor: props.designData.IconColor}} >
                        <i className={props.designData.Icon}></i>
                    </div> 
                }                
            
                <div>
                    {props.designData.Subheadline && <h2 className='mfx-subheadline'>{!isNaN(props.designData.Subheadline) ? window.Dictionary?.getTranslation(props.designData.Subheadline) : props.designData.Subheadline}</h2>} 
                    {props.designData.Headline && <h1 className='mfx-headline' style={props.designData.IconColor ? { color: props.designData.IconColor} : {color: ''}}>{!isNaN(props.designData.Headline) ? window.Dictionary?.getTranslation(props.designData.Headline) : props.designData.Headline}</h1> }
                </div>
            </div>
            {props.designData.Description && <p className={`mfx-description ${props.designData.Icon ? 'h-with-icon' : ''}`}>{props.designData.Description}</p>}
        </div>
    );

};

export default Headline;