/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 * Renders a CustomerDetailView.
 * @returns {JSX.Element} Rendered component
 */

import LocalStorage from 'Helpers/MFXLocalStorage';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ModalOverlay from '../Common/ModalOverlay';
import Loading from '../Elements/Loading';
import QrScanner from '../Elements/QrScanner';
import SavedModal from '../Elements/SavedModal';
import apiPost from '../Hooks/apiPost';
import GenericElement from '../Utils/GenericElement';
import loadJsonData from '../Utils/loadJsonData';
import URLS from '../Utils/URLs.API';
import store, { detailViewActions } from '../../../Helpers/Stores/detailViewStoreCRM';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { getTodaysDate } from '../../../Helpers/dateHelper';

function AddView(props: any): JSX.Element {
    //const viewData = useSelector((state: RootState) => state.viewData);
    const [viewData, setViewData] = useState(null as any);

    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get('type');
    const index = searchParams.get('index');
    const customer = searchParams.get('customer');
    const process = props.withoutProcess ? null : searchParams.get('process');

    const [dataLoaded, setDataLoaded] = useState(false);
    const [saveMessage, setSaveMessage] = useState(null as any);
    const [curCustomer, setCustomer] = useState<null | any>(null);
    const [curProcess, setCurProcess] = useState<null | any>(null);
    const [searchResults, setSearchResults] = useState<null | any>(null);
    const [addForm, setAddForm] = useState<null | any>(null);
    const [recording, setRecording] = useState(false);
    const [downloadICL, setDownloadICL] = useState(false);

    const [qrCode, setQrCode] = useState({
        active: false,
        qrcodedata: {},
        hasVideo: 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices ? true : false,
    });

    let timeout: null | ReturnType<typeof setTimeout> = null;
    useEffect(() => {
        loadCustomer();
        loadForm();

        if (process) loadProcess();
    }, []);
    function loadProcess() {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetProcess + process).then((data: any) => {
            if (data.Data !== null) {
                setCurProcess(data.Data);
            }
        });
    }
    function loadCustomer() {
        if (customer) {
            setCustomer({ id: customer });
        } else if (props.viewSettings.customer) {
            setCustomer(props.viewSettings.customer);
            props.viewSettings.fixCustomer = true;
        } else if (props.viewSettings.needCustomer) {
            const currentPath = LocalStorage.getCurrentPath();
            if (currentPath && currentPath.indexOf('CUSTOMER') > 0) {
                let tabCustomer = LocalStorage.getTabData();
                setCustomer({ id: tabCustomer?.primaryId, name: tabCustomer?.context.customerName });
                props.viewSettings.fixCustomer = true;
            }
        }
    }

    function genObject(obj, keys, val) {
        if (keys.length === 1) {
            obj[keys[0]] = val;
            return obj;
        }
        obj[keys[0]] = genObject(obj[keys[0]] ?? {}, keys.slice(1), val);
        return obj;
    }

    function loadForm() {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.ViewLayouts + '?ViewName=' + props.viewName + '&LayoutName=' + (props.layoutName ?? 'default'))
            .then((data: any) => {
                if (data.Data !== null) {
                    setAddForm(data.Data.Json);
                    let emptyObject = {} as any;
                    if (data.Data.Json.ElementsOrder) {
                        // OLD 17.01.24
                        data.Data.Json.ElementsOrder[0].forEach(key => {
                            const keys = (key as string).split('-');
                            if (keys[0] === 'Data') keys.splice(0, 1);
                            emptyObject = genObject(emptyObject, keys, data.Data.Json.DesignElements[key]?.Elementtype === 'checkbox' ? false : '');

                            //emptyObject[key] = data.Data.Json.DesignElements[key].Elementtype === 'checkbox' ? false : '';

                            /*------------ SPECIAL CONDITIONS --------------*/

                            if (key === 'Begin') emptyObject[key] = getTodaysDate(0, 'datetime-local');
                            else if (key === 'EndTime') emptyObject[key] = getTodaysDate(2, 'datetime-local');

                            /*------------ ADD DEFAULT VALUES!!!! --------------*/
                        });
                    } else {
                        Object.entries(data.Data.Json.DesignElements).forEach((el: any) => {
                            const keys = (el[1].Key as string).split('-');
                            emptyObject = genObject(emptyObject, keys, el[1]?.Elementtype === 'checkbox' ? false : '');

                            if (el[1].Key === 'Begin') emptyObject[el[1].Key] = getTodaysDate(0, 'datetime-local');
                            else if (el[1].Key === 'EndTime') emptyObject[el[1].Key] = getTodaysDate(2, 'datetime-local');
                        });
                    }

                    /*------------ PROCESS AUS TASK --------------*/
                    if (props.withFilledData) {
                        emptyObject = JSON.parse(JSON.stringify(props.viewData.Data));
                        emptyObject.Process.Text = props.viewData.Data.ProcessingText;
                        emptyObject.ProcessingText = '';
                        emptyObject.TaskText = 'Vorgang: ' + props.viewData.Data.Process.Number + ' Aufgaben Pos.: ' + props.viewData.Data.PositionNumber;
                        emptyObject.Process.Number = null;
                        emptyObject.Process.Index = null;
                        emptyObject.PositionNumber = null;
                        emptyObject.Begin = getTodaysDate(0, 'datetime-local');
                        emptyObject.EndTime = getTodaysDate(2, 'datetime-local');
                        emptyObject.Index = null;
                        emptyObject = Object.fromEntries(Object.entries(emptyObject).filter(([_, v]) => v != null));
                        emptyObject.Process = Object.fromEntries(Object.entries(emptyObject.Process).filter(([_, v]) => v != null));
                    }
                    if (emptyObject.PersonInChargeName === '' || emptyObject.PersonInChargeID === '') {
                        emptyObject.PersonInChargeName = LocalStorage.getData('UserData').CRMPersonInCharge;
                        emptyObject.PersonInChargeID = LocalStorage.getData('UserData').Index;
                    }

                    setViewData({ Data: emptyObject, DesignElements: data.Data.Json.DesignElements });
                    setDataLoaded(true);
                }
            })
            .catch(e => {
                console.log(e);
            });
    }
    
    async function handleSubmit(e: any) {
        console.log(viewData.Data);
        setDataLoaded(false);
        e.preventDefault();
        let curFormData = e.target;
        let validate = 0;

        /* ----------------- VALIDATION ------------------- */

        for (let i = 0; i < curFormData.length; i++) {
            if (curFormData.elements[i].Required && !curFormData.elements[i].value) {
                curFormData.elements[i].classList.add('required-error');
                validate++;
            }
        }

        //clear errors
        document.querySelector('required-error')?.classList.remove('required-error');
        let requiredContainer = document.getElementsByClassName('required-true');
        for (let j = 0; j < requiredContainer.length; j++) {
            let input = (requiredContainer[j] as HTMLElement).querySelectorAll('input')[1];
            if (input && !input.value) {
                let nameOfAlternative = viewData.DesignElements[input.name].Conditions && viewData.DesignElements[input.name].Conditions[0] ? viewData.DesignElements[input.name].Conditions[0].value : null;
                if (nameOfAlternative && (typeof viewData.Data[nameOfAlternative] === 'object' ? viewData.Data[nameOfAlternative]?.value : viewData.Data[nameOfAlternative])) {
                    //make a loop if more than 1 condition
                    console.log('alternative erfüllt');
                } else {
                    (requiredContainer[j] as HTMLElement).querySelector('div[class*=-control]')?.classList.add('required-error');
                    validate++;
                }
            }
        }

        if (validate > 0) {
            setDataLoaded(true);
        } else {
            const newData = {} as any;
            newData.CreateData = {} as any;
            if (props.viewSettings.addView === 'LeadAddView' || props.viewSettings.addView === 'NoteAddView') {
                newData.CreateData = { ...viewData.Data };
                if (props.viewSettings.needCustomer) {
                    newData.CreateData.CustomerSupplierNumber = customer ?? curCustomer?.id;
                    newData.CreateData.ContactPersonData.CustomerNumber = customer ?? curCustomer?.id;
                }
            } else if (props.viewSettings.addView === 'ContactPersonAddView') {
                newData.CreateData = { ...viewData.Data };
                newData.CreateData.CustomerNumber = customer ?? curCustomer?.id;
            } else {
                newData.CreateData.Task = { ...viewData.Data };
                if (!newData.CreateData.Process) newData.CreateData.Process = newData.CreateData.Task.Process ?? {};
                newData.CreateData.Process = { ...newData.CreateData.Process, CustomerSupplierNumber: customer ?? curCustomer.id };
                if (type === 'lead') newData.CreateData.Process.Index = index;
                if (props.viewSettings.addView === 'CustomerVisitAddView') {
                    newData.CreateData.TaskType = 'Visit';
                }

                //save Task to specifix Process
                if (curProcess) newData.CreateData.Process.Index = curProcess.Index;
            }
            if (viewData.Data.PersonInChargeID && typeof viewData.Data.PersonInChargeID === 'object') {
                newData.CreateData.Task.PersonInChargeName = viewData.Data.PersonInChargeID.label;
                newData.CreateData.Task.PersonInChargeID = viewData.Data.PersonInChargeID.value;
            }

            //Multiflexcenter saves mutliple Emails with ; - in HTML we need ,
            if (newData.CreateData.EmailAddress) newData.CreateData.EmailAddress = newData.CreateData.EmailAddress.replace(',', ';');

            console.log(newData);
            apiPost('/' + LocalStorage?.getConnectionKey() + props.viewSettings.createUrl, newData)
                .then((data: any) => {
                    if (data.Meta.Success) {
                        if (props.withFilledData && downloadICL) {
                            createDownloadICSFile(
                                'Europe/Vienna',
                                new Date(newData?.CreateData?.Task?.Begin),
                                new Date(newData?.CreateData?.Task?.EndTime),
                                newData?.CreateData?.Process?.Text ?? 'NewTask',
                                window.location.origin + window.location.pathname + '?type=task&index=' + data.CreatedData.Task.Index + '&customer=' + data.CreatedData.Process.CustomerSupplierNumber + '&process=' + data.CreatedData.Process.Index
                            );
                        }
                        curFormData.reset();
                        setSaveMessage({ success: true, message: 14233 });
                        if (props.viewSettings.modalButtons) {
                            props.viewSettings.modalButtons.forEach(button => {
                                if (button.action === 'toCreatedData' && (props.viewSettings.viewName === 'TaskAddView' || props.viewSettings.viewName === 'CustomerVisitAddView')) return (button.url = button.url + 'index=' + data.CreatedData.Task.Index);
                            });
                        }
                    } else {
                        console.log(data.Meta);
                        setSaveMessage({ success: false, message: window.Dictionary.getTranslation(14234) + ': <br/>' + data.Meta.ErrorMessages[0].Message });
                    }
                    setDataLoaded(true);
                })
                .catch(data => {
                    console.log(data);
                    setSaveMessage({ success: false, message: window.Dictionary.getTranslation(14234) + ': <br/>' + data.Meta.ErrorMessages[0].Message });

                    setDataLoaded(true);
                });
        }
    }

    function changeData(valueObject) {
        setSaveMessage(null);
        setValue(viewData.Data, valueObject.designData.Key.split('-'), valueObject.newValue);
        //---------- different conditions ----------------
        if (valueObject.designData.Conditions && valueObject.designData.Conditions[0] && valueObject.designData.Conditions[0].type === 'alternative') {
            setValue(viewData.Data, valueObject.designData.Conditions[0].value.split('-'), '');
            if (valueObject.designData.Conditions[0].value === 'PersonInChargeID' && valueObject.designData.Conditions[0].type === 'alternative') {
                setValue(viewData.Data, valueObject.designData.Conditions[0].value.replace('PersonInChargeID', 'PersonInChargeName').split('-'), '');
            }
            if (valueObject.designData.Conditions[0].value === 'PersonInChargeNumber' && valueObject.designData.Conditions[0].type === 'alternative') {
                setValue(viewData.Data, valueObject.designData.Conditions[0].value.replace('PersonInChargeNumber', 'PersonInChargeName').split('-'), '');
            }
        }
        setViewData({ ...viewData });
    }

    function setValue(data, keys, value) {
        if (keys.length === 1) data[keys[0]] = value;
        else {
            setValue(data[keys[0]], keys.slice(1), value);
        }
    }

    const getSearchResults = e => {
        setSearchResults(null);
        if (timeout) clearTimeout(timeout);
        if (e.target.value.length > 2) {
            if (e.key === 'Enter') loadSearchData(e.target.value);
            else
                timeout = setTimeout(() => {
                    loadSearchData(e.target.value);
                }, 300);
        }
    };

    function loadSearchData(term: string) {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.Search + '?Term=' + term + '&Type=Customers').then((data: any) => {
            if (data?.Data?.SearchResults) {
                setSearchResults({ data: data.Data.SearchResults });
                setDataLoaded(true);
                if (data?.Data?.SearchResults.length === 0) setSearchResults({ message: 14225 });
            } else {
                setSearchResults({ message: 14225 });
            }
        });
    }

    function fillQrCodeData(data) {
        Object.entries(data).forEach(d => {
            setValue(viewData.Data, d[0].split('-'), d[1]);
        });
        console.log(data);
        setViewData({ ...viewData });
    }
    function getGenericElement(designD, key, uniqueIndex, idx) {
        const keys = (key as string).split('-');
        if (keys[0] === 'Data') keys.splice(0, 1);
        let value = viewData.Data;
        keys.forEach(key => {
            value = value[key];
        });
        if (keys[0] === 'Process' && process) {
            designD.ReadOnly = true;
        }

        return (
            <GenericElement
                customData={value}
                designData={{ ...designD, curProcess: curProcess }}
                customerId={curCustomer?.id}
                key={uniqueIndex + idx}
                index={uniqueIndex}
                isRecording={recording}
                setRecording={val => setRecording(val)}
                disableMode={false}
                noLabel={false}
                onChangeGenericElement={(valueObject: any) => {
                    changeData({ ...valueObject, idx: idx });
                }}
                viewName="addView"
            />
        );
    }

    //#region ical
    function download(filename, fileBody) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileBody));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    function convertToICSDate(dateTime) {
        dateTime.setHours(dateTime.getHours() - 1);
        const year = dateTime.getFullYear().toString();
        const month = dateTime.getMonth() + 1 < 10 ? '0' + (dateTime.getMonth() + 1).toString() : (dateTime.getMonth() + 1).toString();
        const day = dateTime.getDate() < 10 ? '0' + dateTime.getDate().toString() : dateTime.getDate().toString();
        const hours = dateTime.getHours() < 10 ? '0' + dateTime.getHours().toString() : dateTime.getHours().toString();
        const minutes = dateTime.getMinutes() < 10 ? '0' + dateTime.getMinutes().toString() : dateTime.getMinutes().toString();
        return year + month + day + 'T' + hours + minutes + '00';
    }

    function createDownloadICSFile(timezone, startTime, endTime, title, description) {
        const icsBody =
            'BEGIN:VCALENDAR\n' +
            'VERSION:2.0\n' +
            'PRODID:Calendar\n' +
            'CALSCALE:GREGORIAN\n' +
            'METHOD:PUBLISH\n' +
            'BEGIN:VTIMEZONE\n' +
            'TZID:' +
            timezone +
            '\n' +
            'END:VTIMEZONE\n' +
            'BEGIN:VEVENT\n' +
            'SUMMARY:' +
            title +
            '\n' +
            'UID:@Default\n' +
            'SEQUENCE:0\n' +
            'STATUS:CONFIRMED\n' +
            'TRANSP:TRANSPARENT\n' +
            'DTSTART;TZID=' +
            timezone +
            ':' +
            convertToICSDate(startTime) +
            '\n' +
            'DTEND;TZID=' +
            timezone +
            ':' +
            convertToICSDate(endTime) +
            '\n' +
            'DTSTAMP:' +
            convertToICSDate(new Date()) +
            '\n' +
            'DESCRIPTION:' +
            description +
            '\n' +
            'END:VEVENT\n' +
            'END:VCALENDAR\n';
        download(title + '.ics', icsBody);
    }
    //#endregion ical

    return (
        <div className={`mfx-add-view ${props.viewName}`}>
            {props.children}
            {!saveMessage && props.viewSettings.addLabel && <h2>{window.Dictionary.getTranslation(props.viewSettings.addLabel)}</h2>}
            {!dataLoaded && <Loading />}
            {dataLoaded && !saveMessage && (
                <>
                    {props.viewSettings.needCustomer && !curCustomer && (
                        <>
                            <h3>{window.Dictionary.getTranslation(14179)}</h3>
                            <div className="mfx-search">
                                <input type="text" id="mfx-mainsearch-input" placeholder={window.Dictionary.getTranslation(7745)} onKeyUp={e => getSearchResults(e)} />
                                <i className="icon-search"></i>
                            </div>
                            {searchResults && !searchResults.message && (
                                <ul className="mfx-customer-result-list">
                                    {Object.keys(searchResults?.data).map(key => (
                                        <li>
                                            <button
                                                onClick={() =>
                                                    setCustomer({
                                                        id: searchResults?.data[key].Value,
                                                        name: searchResults?.data[key].SearchData.Name1,
                                                        searchdata: searchResults?.data[key].SearchData,
                                                    })
                                                }>
                                                <span>{searchResults?.data[key].Value}</span>
                                                <span>{searchResults?.data[key].SearchData.Name1}</span>
                                                <span>{searchResults?.data[key].SearchData.Street},</span>
                                                <span>{searchResults?.data[key].SearchData.City}</span>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {searchResults && !searchResults.message && <p className="error">{searchResults.message}</p>}
                            {!dataLoaded && (
                                <ModalOverlay>
                                    <Loading />
                                </ModalOverlay>
                            )}
                        </>
                    )}
                    {qrCode.active && (
                        <>
                            <div className="gray-borderradius-bg" onClick={() => setQrCode({ ...qrCode, active: false })}>
                                <i className="icon-arrow-left"></i>
                            </div>
                            <QrScanner
                                prefix={props.viewSettings.qrPrefix}
                                onSuccessfullScan={data => {
                                    setQrCode({ ...qrCode, qrcodedata: data, active: false });
                                    fillQrCodeData(data);
                                }}
                            />
                        </>
                    )}
                    {((props.viewSettings.needCustomer && curCustomer) || !props.viewSettings.needCustomer) && props.viewSettings.enableqrcode && qrCode.hasVideo && !qrCode.active && (
                        <div className="flex flex-end">
                            <button className="button qr-code-button" style={{ margin: 'initial', marginBottom: '0.7rem' }} onClick={() => setQrCode({ ...qrCode, active: true })}>
                                <i className="icon-qr-code"></i>
                            </button>
                        </div>
                    )}

                    <form id="mfx-add-form" onSubmit={handleSubmit}>
                        {!qrCode.active && curCustomer && props.viewSettings.needCustomer && (
                            <>
                                {curProcess && (
                                    <div className="add-form-client gray-borderradius-bg">
                                        <h3 style={{ margin: 0 }}>
                                            {window.Dictionary.getTranslation(1398)}: {curProcess.Number}
                                        </h3>
                                        <div>{curProcess.Subject}</div>
                                    </div>
                                )}
                                {curCustomer.name && !props.viewSettings.fixCustomer && (
                                    <div className="add-form-client gray-borderradius-bg">
                                        <div className="add-form-clientheadline flex">
                                            {!props.viewSettings.fixCustomer && <i className="icon-arrow-left" onClick={() => setCustomer(null)}></i>}
                                            {curCustomer.name && (
                                                <h3>
                                                    {window.Dictionary.getTranslation(14180)}: {/*<span>{curCustomer.name}</span>*/}
                                                </h3>
                                            )}
                                        </div>
                                        <div className="add-form-clientdata">
                                            {curCustomer.searchdata && curCustomer.searchdata.Name1 && <p>{curCustomer.searchdata.Name1}</p>}
                                            {curCustomer.searchdata && curCustomer.searchdata.Index && <p>{curCustomer.searchdata.Index}</p>}
                                            {curCustomer.searchdata && curCustomer.searchdata.PostCode && (
                                                <p>
                                                    {curCustomer.searchdata.PostCode} {curCustomer.searchdata.City}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {curCustomer.name && props.viewSettings.fixCustomer && (
                                    <div className="add-form-client">
                                        <h3>
                                            <span>{curCustomer.name}</span>
                                        </h3>
                                    </div>
                                )}
                            </>
                        )}
                        {!qrCode.active && addForm && ((props.viewSettings.needCustomer && curCustomer) || !props.viewSettings.needCustomer) && (
                            <>
                                <div className="mfx-elements" style={{ overflow: props.viewSettings.noOverflow ? 'unset' : 'auto', maxHeight: props.viewSettings.noOverflow ? '' : '70vh' }}>
                                    {
                                        // OLD 17.01.24
                                        addForm.ElementsOrder &&
                                            addForm.ElementsOrder[0].map((key, idx) => {
                                                if (addForm.DesignElements[key]) {
                                                    const designD = addForm.DesignElements[key];
                                                    return getGenericElement(designD, key, idx, idx);
                                                }
                                                return <></>;
                                            })
                                    }
                                    {!addForm.ElementsOrder &&
                                        addForm.DesignElements &&
                                        Object.entries(addForm.DesignElements)
                                            .sort((a: any, b: any) => {
                                                return a[1].Order > b[1].Order ? 1 : -1;
                                            })
                                            .map((key: any, idx) => {
                                                return getGenericElement(key[1], key[1].Key, idx, idx);
                                            })}
                                </div>
                                <div>
                                    {props.withoutProcess && (
                                        <div className="mfx-checkbox-element">
                                            <input type="checkbox" id="checkbox-download-icl" checked={downloadICL} onChange={e => setDownloadICL(e.target.checked)}></input>
                                            <label htmlFor="checkbox-download-icl"> {window.Dictionary.getTranslation(16342)}</label>
                                        </div>
                                    )}
                                    <button type="submit" className="send-button button center">
                                        {window.Dictionary.getTranslation(4765)}
                                    </button>
                                </div>
                            </>
                        )}
                    </form>
                </>
            )}
            {saveMessage && <SavedModal saveMessage={saveMessage} closeIcon={true} handleClose={() => window.location.reload()} modalButtons={props.viewSettings.modalButtons} />}
        </div>
    );
}

export default AddView;

export function AddViewProvider(props: any): JSX.Element {
    return (
        <Provider store={store}>
            <AddView {...props} />
        </Provider>
    );
}

export type RootState = ReturnType<typeof store.getState>;
