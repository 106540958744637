/* eslint-disable jsx-a11y/no-autofocus */
/**
 * Renders a Search
 * @returns {JSX.Element} The styled Link.
 * @param {any} props given by caller
 */

import { useState, useEffect } from 'react';
import URLS from '../Utils/URLs.API';
import Loading from './Loading';
import { GenericTds } from '../Utils/GenericTds';
import NewTab from '../Elements/NewTab';
import LocalStorage from '../../../Helpers/MFXLocalStorage';
import loadJsonData from '../Utils/loadJsonData';
import {useLocation, useSearchParams} from 'react-router-dom';
import designer from 'Data/swagger_search_designer.json';
import ModalOverlay from '../Common/ModalOverlay';

const MainSearch = () => {
    const [mobile, setMobile] = useState(window.innerWidth <= window.mobileBreakPoint ? true : false);
    const [searchActive, setSearchActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const {pathname} = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [tabs, setTabs] = useState([
        { name: 7288, count: 0, code: 1 },
        { name: 135, count: 0, code: 2000 },
        { name: 863, count: 0, code: 8000 },
        { name: 932, count: 0, code: 10100 },
    ]);
    
    const [tableHead,setTableHead] = useState(designer.Tablehead[0]); //.Tablehead[0];
    const tabsAll = [0, 0, 0, 0, 0];
    let timeout = null;

    

    useEffect(() => {
        setSearchActive(false); // Close the navigation panel
    }, [ pathname, searchParams]);

    const openSearch = () => {
        setSearchActive(true);
    };

    const closeSearch = () => {
        setSearchActive(false);
    };

    function handleResize() {
        if(window.innerWidth <= window.mobileBreakPoint) setMobile(true);
        else setMobile(false);
    }
    window.addEventListener('resize', handleResize);

    const getSearchResults = e => {
        if (timeout) clearTimeout(timeout);
        if (e.target.value.length > 2) {
            if (e.key === 'Enter') loadSearchData(e.target.value);
            else
                timeout = setTimeout(() => {
                    loadSearchData(e.target.value);
                }, 300);
        }
    };

    function loadSearchData(term) {
        setLoading(true);
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.Search + '?Term=' + term).then(data => {
            if (data?.Data?.SearchResults)
                data.Data.SearchResults = data?.Data?.SearchResults.filter(d => d.SearchType !== 1000).map(d => {
                    return { ...d, ...getSearchType(d.SearchType) };
                });
            setTabs(
                tabs.map((tab, idx) => {
                    return { ...tab, count: tabsAll[idx] };
                })
            );
            setSearchResults({ searchstring: term, data: data.Data });
            setLoading(false);
        });
    }

    function getSearchType(type) {
        tabsAll[0]++;
        switch (type) {
            /*case 1000:
                tabsAll[1]++;
                return 110;*/
            case 2000:
                tabsAll[1]++;
                return {SearchType : window.Dictionary.getTranslation(135), SearchNumber: 135};
            case 8000:
                tabsAll[2]++;
                return {SearchType : window.Dictionary.getTranslation(863), SearchNumber: 863};
            case 10100:
                tabsAll[3]++;
                return {SearchType : window.Dictionary.getTranslation(932), SearchNumber: 932};
            default:
                return type;
        }
    }

    function changeActiveTab(tabId) {
        setActiveTab(tabId);
        setTableHead(designer.Tablehead[tabId]);
    }

    const addNewTab = (data, idx, type) => {
        LocalStorage.createTab(
            {
                primaryId: idx,
                primaryType: type,
                context: {
                    customerName: data.Label,
                },
            },
            true,
            true,
            '/processes/' + type,
            '/processes/' + type
        );
    };

    function getType(data){
        switch(data){
            case 135: return 'customer';
            //case 110: return 'item';
            case 863: return 'contactperson';
            case 932: return 'task';
            default: return 'SEARCH';
        }
    }

    function getDataObjPerType(searchResult) {

        let newObj = {...searchResult, ...searchResult.SearchData};

        if(newObj.Customer)
            newObj.CustomerNumber = newObj.Customer.Number;
        else if(newObj.Suppli)

        console.log(newObj);

        return newObj;
    }


    function onClickHandler(link){
        const mainKey = LocalStorage.getDBKeys()[0];
        LocalStorage.setCurrent(mainKey, true, link.replace('/'+mainKey,''));
    }

    return (
        <>
            <div id='mfx-mainsearch'>
                <input type='text' placeholder={window.Dictionary?.getTranslation(7745)} onClick={openSearch} />
                <i className='icon-search'></i>
            </div>

            {searchActive && (
                <div id='mfx-search-popup'>
                    <input autoFocus type='text' id='mfx-mainsearch-input' placeholder={window.Dictionary?.getTranslation(7745)} onKeyUp={e => getSearchResults(e)} />
                    <i className='icon-close' onClick={closeSearch} />

                    {loading && (
                        <ModalOverlay>
                            <Loading />
                        </ModalOverlay>
                    )}
                    {searchResults && (
                        <div className='mfx-gp-view'>
                            {!mobile && 
                                <div className='mfx-gp-group mfx-group-type-tabs'>
                                    <div className='mfx-group-header'>
                                        {tabs.map((tab, idx) => {
                                            return (
                                                <button type='button' key={`tabheader-${idx}`} className={idx === activeTab ? 'group-header active' : 'group-header'} id={tab.name} onClick={() => changeActiveTab(idx)}>
                                                    {window.Dictionary?.getTranslation(tab.name) + ' (' + tab.count + ')'}
                                                </button>
                                            );
                                        })}
                                    </div>
                            </div>
                            }
                            {mobile && 
                                <div className='mfx-dropdown-element'>
                                    <select className='mfx-group-header ' onChange={(e) => changeActiveTab(e.target.value)}>
                                        {tabs.map((tab, idx) => {
                                            return (
                                                <option value={idx}>
                                                    <button type='button' key={`tabheader-${idx}`} className={idx === activeTab ? 'group-header active' : 'group-header'} id={tab.name}>
                                                    {window.Dictionary?.getTranslation(tab.name) + ' (' + tab.count + ')'}
                                                </button></option>
                                            );
                                        })}
                                    </select>
                                </div>
                            } 
                        </div>
                    )}
                    <div className='mfx-gp-view table-view'>
                        {searchResults && searchResults.searchstring && (
                            <h2>
                                {window.Dictionary?.getTranslation(577)} <span>{searchResults.searchstring}</span>
                            </h2>
                        )}
                        {searchResults && searchResults.data && searchResults.data.SearchResults && searchResults.data.SearchResults.length > 0 && (
                            <div className='mfx-table-container' style={{ overflow: 'auto' }}>
                                <table className={'mfx-table disable-true'}>
                                    <tbody>
                                        <tr className='mfx-table-sticky mfx-table-row mfx-table-head' style={{ gridTemplateColumns: 'repeat(' + (Object.keys(tableHead).length + (activeTab === 0 || activeTab === 1 ? 1 : 0)) + ', 1fr)' }}>
                                            {Object.keys(tableHead).map((key, idx) => {
                                                return (
                                                    <th key={idx} className='mfx-table-column-head' data-id={`search${key}`}>
                                                        {window.Dictionary.getTranslation(tableHead[key].Name)}
                                                    </th>
                                                );
                                            })}
                                            {(activeTab === 0 || activeTab === 1) && <th>{window.Dictionary.getTranslation(14298)}</th>}
                                        </tr>
                                        {searchResults.data.SearchResults.length > 0 &&
                                            searchResults.data.SearchResults.filter(d => (activeTab !== 0 ? d.SearchNumber === tabs[activeTab].name : true))
                                                .map((searchResult, idx) => {
                                                return (
                                                    <tr key={idx} style={{ gridTemplateColumns: 'repeat(' + (Object.keys(tableHead).length + (activeTab === 0 || activeTab === 1 ? 1 : 0)) + ', 1fr)' }}
                                                        //onClick={(e) => {(searchResult.SearchNumber === 135 && e.detail === 2) ?? addNewTab(searchResult, idx, getType(searchResult.SearchNumber));}} doubleClick - not working here
                                                        >{
                                                            //Render Table Data - merges Customer and Designer
                                                            <GenericTds 
                                                                data={getDataObjPerType(searchResult)} 
                                                                index={'' + idx} 
                                                                idx={searchResult.Value}
                                                                disableMode={true} 
                                                                changeData={() => {}} 
                                                                tableHead={tableHead}
                                                                mainlink={true}
                                                                detailType={getType(searchResult.SearchNumber)}
                                                                onClickHandler={onClickHandler}
                                                                onAddNewTab={addNewTab} 
                                                                
                                                                 />
                                                        }
                                                        {(activeTab === 0 || activeTab === 1) && <td className='mfx-table-column'>
                                                            {searchResult.SearchNumber === 135 && 
                                                                <NewTab type={getType(searchResult.SearchNumber)} idx={searchResult.Value} data={searchResult} onAddNewTab={addNewTab} />
                                                            }
                                                        </td>}
                                                        
                                                        
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default MainSearch;
