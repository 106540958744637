/**
 * <Class description>
 * Gives back the right for the given dll_nr
 * JSONs are generated from the backend
 * Dictionary is filled with Translationdata
 * call window.Dictionary.getTranslation(dll_nr:number) to get the right Translation
 * @param {Array} Array of jsons
 */

interface dictJson {
    Dll_Nr: number;
    Text: String;
}

class Dictionary {
    jsons;
    dictionaryArray: Array<String>;

    constructor(translations: Array<Array<dictJson>>) {
        this.jsons = translations;
        this.dictionaryArray = this.buildDictionary();
    }

    buildDictionary() {
        //TODO load and save basis-json
        //Dynamic check if there is another json to render

        let langArray: Array<String> = [];

        for (let i = 0; i < this.jsons.length; i++) {
            let curLang = this.jsons[i];

            for (let j = 0; j < curLang.length; j++) {
                langArray[curLang[j].Dll_Nr] = curLang[j].Text;
            }
        }
        return langArray;
    }

    getTranslation(dll_nr: any) {
        /*console.log(dll_nr);
        if(isNaN(dll_nr)) parseFloat(dll_nr.replace(':', ''));*/
        if (isNaN(dll_nr) || !dll_nr) {
            return dll_nr;
        } else {
            if (this.dictionaryArray[dll_nr]) return this.dictionaryArray[dll_nr];
            else return 'invalid dll_nr: ' + dll_nr;
        }
    }
    getTranslationOrText(dll_nr: number) {
        if (isNaN(dll_nr)) {
            return dll_nr;
        } else {
            if (this.dictionaryArray[dll_nr]) return this.dictionaryArray[dll_nr];
            else return dll_nr;
        }
    }
}

export default Dictionary;
