/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * Renders a Quicklink
 * @returns {JSX.Element} The styled Link.
 * @param {any} props given by caller
 */
 

 const Quicklink = (props) => {
    return (

        <a className="mfx-element-quicklink button button-border" 
            style={{borderColor: props.designData.LinkColor}}>
            <i className='icon-add icons'></i>
            {!isNaN(props.designData.DefaultValue) ? window.Dictionary.getTranslation(props.designData.DefaultValue) : props.designData.DefaultValue}
        </a>
        
    );

};

export default Quicklink;