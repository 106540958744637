/**
 * Renders only the Filterbutton
 * @returns {JSX.Element} The basic Filterbutton.
 */

const FullTextSearch = props => {
    let timeout = null;

    const getSearchResults = e => {
        if (timeout) clearTimeout(timeout);
        //if (e.target.value.length > 2) {
            
        if (e.key === 'Enter') {
            console.log(e.target.value);
            props.loadSearchData(e.target.value);
        }
        /*else
                timeout = setTimeout(() => {
                    props.loadSearchData(e.target.value);
                }, 300);*/
        //}
    };
    return (
        <>
            <div className='table-search'>
                <input defaultValue={props.value} type="text" id="mfx-mainsearch-input" className="test"
                placeholder={window.Dictionary?.getTranslation(7745)} 
                onKeyUp={e => getSearchResults(e)}
                    />
            </div>
        </>
    );
};

export default FullTextSearch;
