/**
 * Renders a CustomerDetailView.
 * @returns {JSX.Element} Rendered component
 */
import { useEffect, useState } from 'react';
import Loading from '../Elements/Loading';
import loadJsonData from '../Utils/loadJsonData';
import URLS from '../Utils/URLs.API';
import LocalStorage from 'Helpers/MFXLocalStorage';



function NewOffer(props: any): JSX.Element {
  //true for debugging
    const [items , setItems] = useState(null as any);
    const [selectedItems , setSelectedItems] = useState([] as any);
    const [searchOpen, setSearchOpen] = useState(false);
    const [offerNumbers, setOfferNumbers] = useState({
        amount : 0,
        netSum : 0,
    });
    const [loading, setLoading] = useState(false);

  
    const loadItems = (e) =>  {
        setSearchOpen(true);
        console.log(e.target.value);

        if(e.target.value.length > 2) {
            loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.Search + '?Type=Items&Term=' + e.target.value).then(data => {
                if (data.Meta.Success && data.Data) {
                    console.log((data.Data as any).SearchResults);
                    setItems((data.Data as any).SearchResults);
                    setSearchOpen(true);
                }
            });
        }
    };  

    const addItemToOffer = (item:any) => {

        console.log(item);

        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetItem + '?ItemIndex=' + item.SearchData.Index).then(data => {
            if (data.Meta.Success && data.Data) {
                console.log(data.Data);

                (document.getElementById('item-search-input') as HTMLInputElement).value = '';
                setItems(null);
                item = {...data.Data, discount : 0, amount : 1};
                setSelectedItems([...selectedItems, item]);
                setOfferNumbers ({...offerNumbers, amount : offerNumbers.amount + 1, netSum : offerNumbers.netSum + item.SalesPrice1});
                setSearchOpen(false);
            }
        });

        /*(document.getElementById('item-search-input') as HTMLInputElement).value = '';
        setItems(null);
        item = {...item, amount: 1, price: 20.30, discount : 0};
        setSelectedItems([...selectedItems, item]);
        setOfferNumbers ({...offerNumbers, amount : offerNumbers.amount + 1, netSum : offerNumbers.netSum + item.price});
        setSearchOpen(false);*/
    };

    const removeItemToOffer = (itemnumber) => {
        let offerItems = [...selectedItems];
        
        for(let i = 0; i < offerItems.length; i++) {
            if(offerItems[i].Identifier === itemnumber)
                offerItems.splice(i, 1);
        }
        
        setSelectedItems(offerItems);

    };

    //close search when clicking outside
    window.onclick= function(e) {
        if(searchOpen && e.target.tagName !== 'P' && e.target.tagName !== 'INPUT') 
            setSearchOpen(false);
    };

    function changeAmount(value, identifier) {
        let items = [...selectedItems];

        for(let i  = 0; i < items.length; i++) {
            if(items[i].Identifier === identifier) {
                items[i].amount = value;
                break;
            }
        }
        setAmount();
    }
    const setDiscount = (value, identifier) => {
        let items = [...selectedItems];

        for(let i  = 0; i < items.length; i++) {
            if(items[i].Identifier === identifier) {
                items[i].discount = value;
            }
            break;
        }
        setAmount();
    };

    const setAmount = () => {
        let items = [...selectedItems];
        let newAmount = 0;
        let sum = 0;

        for(let i  = 0; i < items.length; i++) {
            newAmount += parseFloat(items[i].amount);
            sum += items[i].SalesPrice1 * items[i].amount * ((100 - items[i].discount) / 100);
        }

        console.log(items, newAmount);

        setOfferNumbers({...offerNumbers, amount : newAmount, netSum : sum});
        setSelectedItems(items);
    };

    return (
        <>
        {/*loading && <Loading title='Customer details' />*/}
            <h2>View in Bearbeitung</h2>
        {/*!loading && (
           <div className='mfx-view'>
            <div className='mfx-grid-container'>
                <div className='mfx-col-12'>
                    <input id='item-search-input' 
                     onClick={() => setSearchOpen(true)} onChange={loadItems} type='text' placeholder='Geben Sie einen Artikelnamen ein' /> 

                    <div id='item-search'>
                        {searchOpen && items && items.length > 0 && items.map((value, idx) => {
                            return <p onClick={() => addItemToOffer(value)} key={idx}>{value.Label}</p>; // eslint-disable-line
                        })
                        }{searchOpen && items && items.length === 0 &&
                            <p>{window.Dictionary.getTranslation(14797)}</p>
                        }
                    </div>
                   
                </div>
                <div className='mfx-col-12 mfx-table-container mfx-table-search-container'>
                    <table className='mfx-table'>
                        <thead>
                            {!props.designer?.Settings?.IsListView && 
                            <tr className='mfx-table-row mfx-table-head' >
                                {Object.entries((props.designer.TableElements ?? props.designer.Tablehead)[0]).sort((a:any,b:any) => a[1].Order > b[1].Order ? 1 : -1).map((data, idx) => {
                                    let curThData = (props.designer.TableElements ?? props.designer.Tablehead)[0][data[0]];
                                    return <th key={idx} 
                                        className={`mfx-table-column-head type-${curThData.Elementtype} key-${curThData.Key}`} 
                                        data-id={`table${data[0]}`} >
                                        {window.Dictionary.getTranslation((props.designer.Tablehead ?? props.designer.TableElements)[0][data[0]].Label)} 
                                    </th>;
                                })}
                            </tr> }
                        </thead>
                        {selectedItems && selectedItems.length > 0 &&
                            selectedItems.map((value, idx) => {
                                return (
                                    <>
                                    <tr key={idx} className='mfx-table-row'>
                                        <td>{value.Identifier}</td>
                                        <td>{value.Label}</td>
                                        <td>Beschreibung</td>
                                        <td><input type="number" min='0' defaultValue={1} onChange={(e) => changeAmount(e.target.value, value.Identifier)} /></td>
                                        <td>{value.SalesPrice1}</td>
                                        <td className="discount"><input type="number" step="1" onChange={(e) => setDiscount(e.target.value, value.Identifier)} defaultValue={0} /> <span>%</span></td>
                                        <td className="price">{(value.SalesPrice1 * ((100 - value.discount) / 100)).toFixed(2)} <span>€</span></td>
                                        <td className="price">{(value.SalesPrice1 * ((100 - value.discount) / 100) * value.amount).toFixed(2)} <span>€</span></td>
                                        <td><i className='icon-delete' onClick={() => removeItemToOffer(value.Identifier)}></i></td>
                                    </tr>
                                    <tr className='mfx-table-row mfx-table-head' >
                                        {Object.entries((props.designer.TableElements ?? props.designer.Tablehead)[0]).sort((a:any,b:any) => a[1].Order > b[1].Order ? 1 : -1).map((data, idx) => {
                                            let curThData = (props.designer.TableElements ?? props.designer.Tablehead)[0][data[0]];
                                            console.log(curThData, value);
                                            return <td key={idx} 
                                                className={`mfx-table-column-head type-${curThData.Elementtype} key-${curThData.Key}`} 
                                                data-id={curThData.Key} >
                                                {value[curThData.Key]}
                                            </td>;
                                        })}
                                    </tr>
                                    </> 
                                );
                            })   
                        }
                        <tfoot>
                            <tr>
                                <td colSpan={3}></td>
                                <td>{offerNumbers.amount}</td>
                                <td></td>
                                <td></td>
                                <td className="price">{offerNumbers.netSum.toFixed(2)} <span>€</span></td>
                                <td className="price">{offerNumbers.netSum.toFixed(2)} <span>€</span></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
        )}
        {loading &&  (
            <h4 className='nothing-found'>{window.Dictionary.getTranslation(14296)} <i className='icon-search'></i></h4>
        )} */}
        </>
    );
}

export default NewOffer;
