/* eslint-disable jsx-a11y/label-has-associated-control */
import MFXLocalStorage from 'Helpers/MFXLocalStorage';
import { useEffect, useState } from 'react';
import BaseChart from '../Elements/BaseChart';
import BaseChartGrouped from '../Elements/BaseChartGrouped';
import DataChart from '../Elements/DataChart';
import Headline from '../Elements/Headline';
import PieChart from '../Elements/PieChart';
import loadJsonData from '../Utils/loadJsonData';
import URLS from '../Utils/URLs.API';
import LineChart from '../Elements/LineChart';
import { MFXGaugeChart } from '../Elements/GaugeChart';
import Loading from '../Elements/Loading';
import SqlTable from '../Elements/SqlTable';
import { formatDate } from '../../../Helpers/dateHelper';

export interface IDashboardProperties {
    id: number;
}

/**
 * Renderes a dashboard
 * @param {IDashboardProperties} props Properties given by the caller
 * @returns {JSX.Element} Rendered Element.
 */
function Dashboard(props: any): JSX.Element {
    const [chartStates, setChartStates]: any = useState({
        chartData: null,
        dataLoaded: false,
    });

    const [ouData, setOUData]: any = useState();
    const [ouNumber, setOUNumber]: any = useState('');

    const [salesRepData, setSalesRepData]: any = useState();
    const [salesRepNumber, setSalesRepNumber]: any = useState('');

    const [updateTime, setUpdateTime] = useState(null as any);

    useEffect(() => {
        setChartStates({ ...chartStates, dataLoaded: false });
        if (props.designer?.length > 0) {
            if ((props.designer as []).find((d: any) => d['SQL-Data']?.includes(':ouNumber'))) {
                loadJsonData('/' + MFXLocalStorage?.getConnectionKey() + URLS.GetDropdownValues + 'OrganisationalUnit').then((data: any) => {
                    setOUData(data.Data);
                });
            }
        }
        if (props.designer?.length > 0) {
            if ((props.designer as []).find((d: any) => d['SQL-Data']?.includes(':salesRepNumber'))) {
                loadJsonData('/' + MFXLocalStorage?.getConnectionKey() + URLS.GetDropdownValues + 'SalesRep').then((data: any) => {
                    setSalesRepData(data.Data);
                });
            }
        }
        loadData();
    }, []);

    useEffect(() => {
        setChartStates({ chartData: null, dataLoaded: false });
        loadData();
    }, [ouNumber, salesRepNumber]);

    function loadData() {
        let currentData = MFXLocalStorage?.getTabData();
        loadJsonData(
            '/' +
                MFXLocalStorage?.getConnectionKey() +
                URLS.GetDashboard +
                '?ViewName=' +
                props.ViewName +
                '&LayoutName=' +
                props.LayoutName +
                (ouNumber ? '&OUNumber=' + ouNumber : '') +
                (salesRepNumber ? '&SalesRepNumber=' + salesRepNumber : '') +
                (currentData.primaryType === 'customer' && currentData.primaryId ? '&CustomerNumber=' + currentData.primaryId : '')
        ).then((data: any) => {
            if (data.Meta.Success && data.Data) {
                logger.debug('Got Dashboarddata successfully');
                if (!isNaN(data.Data.Json[0]?.UpdateTime)) {
                    setUpdateTime(new Date());
                    setTimeout(function () {
                        loadData();
                    }, 60000 * data.Data.Json[0]?.UpdateTime);
                }
                setChartStates({ chartData: data.Data.Json, dataLoaded: true });
            } else {
                logger.warning(data.Meta.Message);
            }
        });
    }

    function generateChart(chart) {
        logger.debug(props);
        logger.debug(chart);
        switch (chart.Type) {
            case 'pie':
                return <PieChart chart={chart} elementRendered={props.viewRendered} />;
            case 'base':
                return <BaseChart chart={chart} elementRendered={props.viewRendered} />;
            case 'base-grouped':
                return <BaseChartGrouped chart={chart} elementRendered={props.viewRendered} />;
            case 'dataset':
                return <DataChart chart={chart} elementRendered={props.viewRendered} />;
            case 'line':
                return <LineChart chart={chart} elementRendered={props.viewRendered} />;
            case 'gauge':
                return <MFXGaugeChart chart={chart} elementRendered={props.viewRendered} />;
            case 'sqlTable':
                return <SqlTable chart={chart} elementRendered={props.viewRendered} />;
            default:
                return <BaseChart chart={chart} elementRendered={props.viewRendered} />;
        }
    }

    return (
        <div id="mfx-dashboard" className="mfx-view">
            <div className="dashboard-filter">
            {ouData && (
                <div>
                    <label>{window.Dictionary.getTranslation(136)}</label>
                    <select
                        onChange={e => {
                            setOUNumber(e.target.value);
                        }}>
                        <option></option>
                        {ouData?.map(d => {
                            return <option value={d.Value}>{d.Name}</option>;
                        })}
                    </select>
                </div>
            )}
            {salesRepData && (
                <div>
                    <label>{window.Dictionary.getTranslation(736)}</label>
                    <select
                        onChange={e => {
                            setSalesRepNumber(e.target.value);
                        }}>
                        <option></option>
                        {salesRepData?.map(d => {
                            return <option value={d.Value}>{d.Name}</option>;
                        })}
                    </select>
                </div>
            )}
            </div>
            <div className="mfx-grid-container">
                {chartStates.chartData &&
                    chartStates.chartData.map((chart, idx) => {
                        return (
                            <div key={idx} className={`mfx-col-${12 / chartStates.chartData.length}`}>
                                <div className="mfx-chart-container">
                                    {(chart.Headline || updateTime) && (
                                        <div className="mfx-element-headline">
                                            <div>
                                                <h1 className="mfx-headline">{chart.Headline}</h1>
                                                {updateTime && <h5>{window.Dictionary.getTranslation(16162)}: {formatDate(updateTime, null)}</h5>}
                                            </div>
                                        </div>
                                    )}

                                    <div className={`mfx-chart-${chart.Type}`} /*style={{height: (12 / props.cols) * 5 + 'vw'}}*/>{generateChart(chart)}</div>
                                </div>
                            </div>
                        );
                    })}
                {!chartStates.chartData && <Loading />}
            </div>
        </div>
    );
}

/*<ChartContainer key={idx} cols={chartStates.chartData.length} chart={chart} elementRendered={props.viewRendered}/>*/

export default Dashboard;
