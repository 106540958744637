import useSessions from 'Components/Lib/Hooks/useSessions';
import { useState } from 'react';
import TabEntry from './TabEntry';

/**
 * Wrapper for all TabEntries
 * @returns {JSX.Element} Rendered component
 */

export default function Tabs() {
  const sessions = useSessions();
  const [mobile, setMobile] = useState(window.innerWidth <= window.mobileBreakPoint ? true : false);
  let tabMenuOpen = false;


  function openTabMenu() {
    let tabs = document.getElementById('mfx-tabs') as HTMLDivElement| null;

    if(tabs && !tabMenuOpen) tabs.style.height = sessions.length * 40 + 'px';
    else if(tabs) tabs.style.height =  '40px';

    tabMenuOpen = !tabMenuOpen;
  }

  function handleResize() {
    if(window.innerWidth <= window.mobileBreakPoint) setMobile(true);
    else setMobile(false);
  }

  window.addEventListener('resize', handleResize);

  function openMenu() {
    let menu = document.getElementById('menu') as HTMLDivElement| null;
    if(menu?.classList.contains('active'))
      menu?.classList.remove('active');
    else
      menu?.classList.add('active');
  }

  return (
    <><div>
        <ul id='mfx-tabs'  className={`top-tabs menu ${mobile ?  'mobile-selected-tab' : ''}`}>
          {sessions.map((s) => (
            <TabEntry key={s.id} {...s} onClickHandler={openTabMenu}/>
          ))}
        </ul>
      </div>
      { mobile &&
        <div onClick={openMenu} className='mobile-menu-bars'><i className='icon-burgermenu'></i></div>
      }
      
    </>
  );
}
