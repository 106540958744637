import MFXLocalStorage from 'Helpers/MFXLocalStorage';
import { To } from 'react-router-dom';

export default function sessionedLink(to: string | To) {
  const connectionKey = MFXLocalStorage.getCurrentTabUrl() || 'NO_SESSION';
  const separator = to.toString().startsWith('/') ? '' : '/';

  return `/${connectionKey}${separator}${to}`;
}

export function mainTabLink(to: string | To) {
  const connectionKey = MFXLocalStorage.getMainTabUrl() || 'NO_SESSION';
  const separator = to.toString().startsWith('/') ? '' : '/';

  return `/${connectionKey}${separator}${to}`;
}
