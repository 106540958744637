/**
 * Renders a Pie Chart
 * @returns {JSX.Element} The Pie Chart
 */

import { ResponsiveBar } from '@nivo/bar';
import { useEffect, useState } from 'react';

const BaseChartGrouped = props => {
    const [chartData, setChartData] = useState({
        data: [],
        tickValues: [],
        indexBy: null,
        keys: [],
        labels: [],
    });
    useEffect(() => {
        let newChartData = [];

        props?.chart?.Data.forEach((data, idx) => {
            newChartData[idx] = data;
            let newValue = 0;

            if (typeof data.value == 'string') newValue = (parseFloat(data.value.replace(',', '.')) * 100).toFixed(2);

            newChartData[idx] = { ziel: 100, aktuell: newValue, id: data.id };
        });
        newChartData[0].id = 'DB';
        newChartData[1].id = 'Umsatz';

        console.log(newChartData);

        setChartData({
            ...chartData,
            tickValues: [0, 50, 100],
            //percentageValues: percentageValues,
            data: newChartData,
            legendLabel: [
                { label: 'Erreichter Umsatz in %', color: '#263455' },
                { label: 'Erreichter DB in %', color: '#263455' },
            ],
        });
    }, [props]);

    return (
        <>
            {chartData.data && (
                <ResponsiveBar
                    data={chartData.data}
                    col
                    keys={['ziel', 'aktuell']}
                    indexBy="id"
                    arcLinkLabelsTextColor="#fff"
                    margin={{ top: 20, right: 10, bottom: 30, left: 60 }}
                    padding={0.6}
                    groupMode="grouped"
                    layout="horizontal"
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: false }}
                    colors={['#516eb2', '#263455']}
                    theme={{
                        axis: {
                            ticks: {
                                text: {
                                  fill: '#999444'
                                }
                              },
                        }
                    }}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#38bcb2',
                            size: 4,
                            padding: 1,
                            stagger: true,
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eed312',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10,
                        },
                    ]}
                    borderColor={{
                        from: 'color',
                        modifiers: [['darker', '1.4']],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        tickValues: chartData.tickValues,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 20,
                        format: value => value + '%',
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -55,
                    }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom',
                            direction: 'row',
                            justify: false,
                            translateX: 15,
                            translateY: 102,
                            itemsSpacing: 2,
                            itemWidth: 116,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                        },
                    ]}
                    enableGridX={true}
                    enableLabel={true}
                    labelTextColor={'var(--iconColor)'}
                />
            )}

            <div className="mfx-legend">
                {chartData?.legendLabel &&
                    chartData.legendLabel.map(labelData => {
                        return (
                            <div>
                                <span style={{ backgroundColor: labelData.color }}></span>
                                <p>{labelData.label}</p>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default BaseChartGrouped;
