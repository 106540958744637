/**
 * Renders a Quicklink
 * @returns {JSX.Element} The styled Link.
 * @param {any} props given by caller
 */
 
 import elements from '../Common/AllElements';

 const ElementTypeOptions = (props) => {
    return (
        <>
        {elements.filter(e => (e.ElementGroup ?? false) === props.onlyElementGroups).map((e, idx) => {
            return (
                <option key={idx} value={e.Elementtype}>
                    {e.Elementtype}
                </option>
            );
        })}
        </>
    );
};

export default ElementTypeOptions;