/**
 * Renders an generic checkbox
 * @returns {JSX.Element} The basic checkbox.
 * @param {any} props given by caller
 */
import { useState , useEffect} from 'react';

const Checkbox = (props) => {
  let checkboxID = 'checkbox-' + props.uniqueKey;
  const [inputChecked, setInputChecked] = useState(false);



  useEffect(() => {
    setInputChecked(props.checked ?? props.designData.CheckedInDesigner);
  },[props.checked]);

  const checkedChangeHandler = (e) => {
    setInputChecked(!inputChecked);
    props.onChangeHandler({'key': props.uniqueKey, 'newValue': e.target.checked, 'view' : props.designData.View,'label': props.designData.Label, 'elementtype': props.designData.Elementtype ?? '', 'type':props.designData.Type, 'viewLabel': props.designData.ViewLabel});
  };
  return (
    <div className='mfx-checkbox-element'>
      
        <>
        <input type="checkbox" id={checkboxID}
          name={checkboxID} 
          checked={inputChecked}
          onChange={(e) => checkedChangeHandler(e)} />
        
          <label htmlFor={checkboxID} >
            {!props.designData.ViewLabel && <span className='viewName'>{props.designData.View ? props.designData.View + ' ' : ''}</span>}
            {props.designData.ViewLabel && <span className='viewLabel'>{props.designData.ViewLabel ? props.designData.ViewLabel + ' ' : ''}</span>}
            <span className='layoutName'>
              {(!isNaN(props.designData.Label) ? window.Dictionary.getTranslation(props.designData.Label) : props.designData.Label ?? window.Dictionary.getTranslation(props.designData.Name) ?? '')}
              {props.withType && props.designData.Elementtype && (' (' + (props.designData.Type ?? props.designData.Elementtype) + ')')}
            </span>
          </label>
        </>
    </div>
  );
};


export default Checkbox;