import { GenericTds } from '../Utils/GenericTds';
import NewTab from '../Elements/NewTab';
import { useEffect, useState } from 'react';
import loadJsonData from '../Utils/loadJsonData';
import LocalStorage from 'Helpers/MFXLocalStorage';
import URLS from '../Utils/URLs.API';

/**
 * Renders a Table
 * @returns {JSX.Element} The styled Link.
 * @param {any} props given by caller
 */

const Table = props => {
    let today = new Date();
    today = today.toISOString();
    const [priority,setPriority] = useState(null);

    function onTrClickHandler(e, data, idx) {
        if (e.detail === 2) props.addNewTab(data, idx);
    }
    function sortTable(e, tableHeadKey) {
        if (!props.sortTable) return;
        if (e.crtlKey) {
            // TODO Multisort
        }
        var column = tableHeadKey.Key.replaceAll('-', '.');
        const direction = props.sortingColumn?.split(' ');
        if (direction && column === direction[0]) {
            if (direction.length === 1 || direction[1] === 'asc') props.sortTable(direction[0] + ' desc');
            else if (direction[1] === 'desc') props.sortTable(direction[0] + ' asc');
            else console.log('Something went wrong with Asc Desc Column');
        } else {
            props.sortTable(column);
        }
    }

    function getDirection(tableHeadKey) {
        var column = tableHeadKey.Key.replaceAll('-', '.');
        const direction = props.sortingColumn?.split(' ');
        if (direction && column === direction[0]) {
            if (direction.length === 1 || direction[1] === 'asc') return <i className="icon-arrow-up"></i>;
            else return <i className="icon-arrow-down"></i>;
        } else {
            return <></>;
        }
    }

    useEffect(() => {
        if(props.detailType === 'task' || props.detailType === 'lead' || props.detailType === 'offer'){
            loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetDropdownValues + 'CrmPriority').then(data => {
                if (data.Meta.Success && data.Data) {
                    setPriority(data.Data);
                } else {
                    window.logger.error('No DropdownData: CrmPriority'); // ERROR MESSAGE
                }
            });
        }
    },[]);
    
    return (
        <div className={`mfx-table-container ${props.designer?.Settings?.IsListView ? 'mfx-listview' : ''}`}>
            {props.data?.length > 0 && (
                <>
                    <table className={`mfx-table disable-${props.states?.disableMode}`}>
                        <tbody>
                            {!props.designer?.Settings?.IsListView && (
                                <tr className="mfx-table-row mfx-table-head">
                                    {Object.entries((props.designer.TableElements ?? props.designer.Tablehead)[0])
                                        .sort((a, b) => (a[1].Order > b[1].Order ? 1 : -1))
                                        .map((data, idx) => {
                                            let curThData = (props.designer.TableElements ?? props.designer.Tablehead)[0][data[0]];

                                            return (
                                                <th key={idx} className={`mfx-table-column-head type-${curThData.Elementtype} key-${curThData.Key}`} onClick={e => sortTable(e, curThData)} data-id={`table${data[0]}`}>
                                                    {window.Dictionary.getTranslation((props.designer.Tablehead ?? props.designer.TableElements)[0][data[0]].Label)}
                                                    &nbsp; {getDirection((props.designer.TableElements ?? props.designer.Tablehead)[0][data[0]])}
                                                </th>
                                            );
                                        })}
                                    {props.withOpenTab === true && <th>{window.Dictionary.getTranslation(14298)}</th>}
                                </tr>
                            )}
                            {props.data && (((props.detailType === 'task' || props.detailType === 'lead' || props.detailType === 'offer') && priority) || (props.detailType !== 'task' && props.detailType !== 'lead' && props.detailType !== 'offer')) &&
                                props.data?.length > 0 &&
                                props.data.map((d, idx) => {
                                    // Special data changes
                                    if(d?.Priority && d?.Priority >= 0){
                                        d.Priority = priority.find(p => p.Value === d.Priority.toString())?.Name;
                                    }
                                    if(d?.Process?.Priority && d?.Process?.Priority >= 0){
                                        d.Process.Priority = priority.find(p => p.Value === d.Process.Priority.toString())?.Name;
                                    }

                                    return (
                                        <>
                                            {!props.withOpenTab && (
                                                <tr key={idx} className={!d.Finished && props.type === 'TASK' && d.End < today ? 'exceeded' : 'not-exceeded'}>
                                                    {
                                                        //Render Table Data - merges Offer and Designer
                                                        <GenericTds
                                                            data={d}
                                                            index={'' + idx}
                                                            disableMode={props.states?.disableMode}
                                                            changeData={value => props.callDispatch(value)}
                                                            tableHead={(props.designer.TableElements ?? props.designer.Tablehead)[0]}
                                                            detailType={props.detailType}
                                                            noForm={props.designer.Settings?.IsListView ?? false}
                                                        />
                                                    }
                                                </tr>
                                            )}
                                            {props.withOpenTab === true && (
                                                <tr key={idx} onClick={e => onTrClickHandler(e, d, d.Number ? d.Number : d.Index)}>
                                                    <GenericTds data={d} index={'' + idx} disableMode={props.states?.disableMode} changeData={value => props.callDispatch(value)} tableHead={(props.designer.TableElements ?? props.designer.Tablehead)[0]} />
                                                    <td className="mfx-table-column tab-column">
                                                        <NewTab type={props.type} idx={d.Number ? d.Number : d.Index} data={d} onAddNewTab={props.addNewTab} />
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    );
                                })}
                        </tbody>
                    </table>
                </>
            )}
            {props.data?.length <= 0 && (
                <>
                    <h4 className="nothing-found">
                        <div className="success-false extra-small">
                            <div className="icon-style-arrow">
                                <i className="icon-close"></i>
                            </div>
                        </div>
                        {window.Dictionary.getTranslation(5484)}
                    </h4>
                </>
            )}
        </div>
    );
};
export default Table;
