import { Navigate, Route, useParams, useSearchParams } from 'react-router-dom';

import { GenericPage, MemoGenericPage } from 'Components/Lib/GenericPage';
import Loading from 'Components/Lib/Elements/Loading';
import URLS from 'Components/Lib/Utils/URLs.API';
import { memo, useEffect, useMemo, useState } from 'react';
import loadJsonData from 'Components/Lib/Utils/loadJsonData';
import Login from 'Components/Lib/Common/Login';
import SessionStorage from 'Helpers/MFXSessionStorage';
import LocalStorage from 'Helpers/MFXLocalStorage';
import * as signalR from '@microsoft/signalr';
import Popup from 'Components/Lib/Elements/Popup';

/**
 * ProcessList
 * @returns {JSX.Element} Returns the list of processes (allowed by the current user)
 */
function ProcessList(): JSX.Element {
    return <p>Process-List should be here</p>;
}

/**
 * &lt;GenericView id={idFromName} /&gt; where id is loaded user-given name transformed into database-id
 * @returns {JSX.Element} Rendered Process
 */
function ProcessByName(): JSX.Element {
    // 1. API-Call an Backend => Name oder ID soll zu einem IContainer[]-Result führen

    const { processName } = useParams<{ processName: string }>();
    /*const component =  useAsComponent({ componentName: 'GenericPage-' + processName });*/
    //const pageData = useApi<IGenericPageData>(URLS.PageLayouts, processName);
    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get('type');
    const [pageData, setPageData] = useState(null);
    const updatedPageData = useMemo(() => pageData, [pageData]);
    const [val, setVal] = useState('');

    const [login, setLogin] = useState(false);
    const [role, setRole] = useState('');
    const [popups, setPopups] = useState([] as any);

    var popupIndex = 0;

    const closePopup = id => {
        return setPopups((prev: any) => {
            var newArray = JSON.parse(JSON.stringify(prev)).filter(p => p.id !== id);
            return newArray;
        });
    };
    useEffect(() => {
        let currentData = LocalStorage?.getTabData();
        if ((type && role && processName) || currentData.primaryType === 'customer') {
            loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetPageLayouts + '?PageName=' + processName)
                .then((data: any) => {
                    let pageFound = false;
                    if (data.Data) {
                        logger.debug(data);
                        if (currentData.primaryType === 'customer' && processName !== 'detailview') {
                            let curRole = role ? role : 'default';

                            data.Data.forEach(layoutobj => {
                                if (layoutobj.PageLayout === curRole + '-customer') {
                                    loadPage(layoutobj.PageLayout);
                                    pageFound = true;
                                    return;
                                }
                            });
                        } else if (type && role) {
                            data.Data.forEach(layoutobj => {
                                if (layoutobj.PageLayout === type) {
                                    loadPage(layoutobj.PageLayout);
                                    pageFound = true;
                                    return;
                                }
                            });
                        }
                        if (!pageFound) loadPage('');
                    }
                })
                .catch(e => {
                    console.log(e);
                    logger.error(e);
                });
        } else if (role) loadPage();
    }, [processName, type, role]);

    useEffect(() => {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetCurrentLayoutRole)
            .then((data: any) => {
                if (data.Meta.Success) setRole(data.Data.LayoutName);
                else setRole('default');
            })
            .catch(e => {
                logger.information(e);
                LocalStorage.createSession('UserRole', 'default');
            });

        /*let connection = new signalR.HubConnectionBuilder().withUrl('/websocket').build();

        connection.start();

        connection.on('testPopup', data => {
            console.log(data);
            setPopups(prev => {
                var newArray = JSON.parse(JSON.stringify(prev));
                newArray.push({ title: data, description: popupIndex, id: popupIndex });
                popupIndex++;
                return newArray;
            });
            //setPopups([{ title: data, description: 99, id: 99 }]);
        });*/
    }, []);

    function loadPage(layout = '') {
        /*if (SessionStorage.getData('UserRole') !== role) {
            SessionStorage.createSession('UserRole', role ?? 'default');
        }*/
        if (!layout) layout = LocalStorage.getData('UserRole');

        //let mobile = (window.innerWidth <= window.mobileBreakPoint) ? '-mobile' : '';

        /* OBI - get Default if layout not there ??????????? */

        let mobile = window.innerWidth <= window.mobileBreakPoint ? '&IsMobile=true' : '';
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetBusinessProcessPage + '?PageName=' + processName + '&PageLayout=' + layout + mobile).then(data => {
            if (data.Meta.Success && data.Data) {
                let containers = { containers: (data.Data as any).Json };
                setPageData(containers as any);
            }
        });
    }

    return (
        <>
            {!login && !pageData && <Loading title={`Process ${processName}`} />}
            {!login && pageData && (
                <>
                    {<MemoGenericPage data={updatedPageData} />}
                    {popups.map((popup, inx) => {
                        return <Popup popup={popup} index={inx} closePopup={closePopup}></Popup>;
                    })}
                </>
            )}
            {login && <Login />}
        </>
    );
}

const Routes = (
    <>
        <Route index={true} element={<ProcessList />} />
        <Route path=":processName" element={<ProcessByName />}>
            <Route path="" element={<ProcessByName />} />
        </Route>
    </>
);

export default Routes;
