/* eslint-disable jsx-a11y/no-autofocus */
/**
 * Renders an generic text input field
 * @returns {JSX.Element} The basic text input.
 * @param {any} props given by caller
 */

const ChangeInputElement = props => {
    const inputOnchangeHandler = event => {
        props.onChangeInput({ key: props.dataKey, newValue: event.target.value });
    };
    return (
        <div>
            <input autoFocus title={props.title} className="table-head-input" type={props.type} value={props.type === 'number' ? props.value : window.Dictionary.getTranslation(props.value)} onChange={e => inputOnchangeHandler(e)} />
        </div>
    );
};

export default ChangeInputElement;
