import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import MainApplication from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';

/* -------------- GLOBAL DICTIONARY ------------ */

import Colors from 'Data/custom_colors.json';


/* -------------- GLOBAL VARIABES ------------ */
window.Colors = Colors;
window.LocationUrl = 'staging-plx-crm.pollex-lc.com'; //window.location.href.split('/'); -> set the right domain
window.mobileBreakPoint = 1025;


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        {/* No basename for this router. This app renders at the root / URL. */}
        <BrowserRouter>
            <MainApplication />
        </BrowserRouter>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA



// serviceWorkerRegistration.register();




// TODO: MK
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
// reportWebVitals(console.log);
