/* eslint-disable no-prototype-builtins */
/*
* Storage for all Customers
* Customer List
*/

import { createSlice, configureStore, current } from '@reduxjs/toolkit';


const detailViewSlice = createSlice ( {
    name : 'view',
    initialState : {
        viewData  : {
            ID: null,
            Data: [],
            DesignElements: [],
            Filter: {},
            Elements : [],
            ElementsOrder: [],
            ViewLabel: ''
        }
    },
    reducers: {
        init(state, action) { //sets initial Data
            state.viewData = action.payload;
        },
        setOrder(state, action) {
            const newOrder = state.viewData.DesignElements[action.payload.target].Order;
            state.viewData.DesignElements[action.payload.shadow].Order = newOrder + (action.payload.insertBefore ? -0.5 : 0.5);
            fillOrder(state.viewData.DesignElements);
        },
        addData(state, action) {
            if(action.payload.newValue)
                state.viewData.DesignElements[action.payload.key] = { Key: action.payload.designData.Key, Label: action.payload.designData.Label, Order: 999, Elementtype: action.payload.designData.Elementtype, Type: action.payload.designData.Type,
                    ReadOnly: action.payload.designData.ReadOnly, Visible: action.payload.designData.Visible, DefaultValue: action.payload.designData.DefaultValue, DropdownName: action.payload.designData.DropdownName, Conditions: action.payload.designData.Conditions};
            else
                delete state.viewData.DesignElements[action.payload.key];
            fillOrder(state.viewData.DesignElements);
        },
        changeDesignElements(state, action) {
            state.viewData.DesignElements[action.payload.key] = action.payload.element;   
        },
        changeViewSettings(state, action) {
            state.viewData[action.payload.key] = action.payload.value;
        },
        setConditions(state, action) {
            state.viewData.DesignElements[action.payload.key].Conditions = [action.payload.condition];
        },
        //#endregion Tables
    }
});

function fillOrder(table) {
    let previous = 0;
    Object.entries(table)
        .sort((a, b) => {
            return a[1].Order > b[1].Order ? 1 : -1;
        })
        .forEach((attributes, idx) => {
            if (idx === 0) attributes[1].Order = 1;
            else attributes[1].Order = previous + 1;
            previous = attributes[1].Order;
        });
}

const store = configureStore({
    reducer: detailViewSlice.reducer
});
export const detailViewActions = detailViewSlice.actions;
export default store;
