/**
 * Renders a customers main data.
 * @returns {JSX.Element} Rendered component
 */
import { useEffect, useState } from 'react';

import Editbutton from 'Components/Lib/Elements/Editbutton';
import URLS from 'Components/Lib/Utils/URLs.API';
import Loading from 'Components/Lib/Elements/Loading';
import Morebutton from 'Components/Lib/Elements/Morebutton';
import LocalStorage from 'Helpers/MFXLocalStorage';
import { ViewElement } from 'Components/Lib/Common/Interfaces/IViewProperties';
import GenericElement from 'Components/Lib/Utils/GenericElement';
import loadJsonData from '../Utils/loadJsonData';
import { useSearchParams } from 'react-router-dom';

// TODO: use Model from Backend:
type ICustomer = Record<string, string>;

function CustomerGeneralView(props: any): JSX.Element {
    const [disableMode /*, setDisableMode*/] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const customerIdParam = searchParams.get('customer');
    //true for debugging
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState(null as any);

    useEffect(() => {
        let customerId = customerIdParam ?? props.id ?? LocalStorage?.getTabData()?.primaryId;

        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetCustomer + customerId).then(data => {

            if (data.Meta.Success && (data as any).Data) {
                setCustomer(data.Data as any);
                setLoading(false);
            }
        });
    }, []);

    return (
        <>
            {/*<div className="mfx-action-buttons">
                <Morebutton />
            </div>*/}
            {loading && <Loading title="" />}
            {!loading && customer && (
                <form id="mfx-general-customer-form" className="mfx-view disabled-true readonly-view">
                    <>
                        {(props.designer?.CustomElements as any[]).map((e, idx) => {
                            return <GenericElement key={idx} designData={e as ViewElement} customData={customer} disableMode={disableMode} index={idx} />;
                        })}
                    </>
                </form>
            )}{' '}
            {!loading && !customer && <h3>{window.Dictionary?.getTranslation(2783)}</h3>}
        </>
    );
}

export default CustomerGeneralView;
