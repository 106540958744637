/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { detailViewActions } from '../../../Helpers/Stores/detailViewStore';
import { Fragment, useEffect, useState } from 'react';
import Button from './Elements/Button';
import loadJsonPost from '../Utils/jsonPost';
import LocalStorage from '../../../Helpers/MFXLocalStorage';
import loadJsonData from '../Utils/jsonGet';
import loadJsonPut from '../Utils/jsonPut';
import { useParams, useSearchParams } from 'react-router-dom';
import Checkbox from './Elements/ElementCheckbox';
import GenericElement from './Elements/GenericElements';
import URLS from '../Utils/URLs.API';
import SavedModal from './Elements/SavedModal';
import CreatableSelect from 'react-select/creatable';
import loadJsonDelete from '../Utils/jsonDelete';
import Info from '../Elements/Info';

interface IGenericViewEditorProps {
    name: string | undefined;
    menuData: any;
}

/* --------------- IMPORT ALL FILES from Detailviews ------------- */

function importAll(r: any) {
    let data = {};
    r.keys().forEach((item: any) => {
        (data as any)[item.replace('./', '').replace(/\.(json)$/, '')] = r(item);
    });
    return data;
}
const defaultViews = importAll((require as any).context('../../../Data/Designer/Defaultviews', false, /\.(json)$/));

/* ---------------------------------------------------------
    --------------- GenericDetailViewEditor (and AddViewEditor) ------------- */

export default function GenericDetailViewEditor(props: IGenericViewEditorProps) {
    const viewData = useSelector((state: RootState) => state.viewData);
    const name = useParams();

    const [searchParams] = useSearchParams();
    const type = searchParams.get('type');
    const viewType = searchParams.get('viewtype');

    const dispatch = useDispatch();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [curLayoutName, setCurLayoutName] = useState('default' as any);
    const [layoutNames, setLayoutNames] = useState(null as any);
    const [saveMessage, setSaveMessage] = useState(null as any);
    const [checkboxSearch, setCheckboxSearch] = useState('' as any);
    const [userGroups, setUserGroups] = useState(null as any);
    const [valueAccord, setValueAccord]: any = useState({});

    const [curGenericElement, setCurGenericElement] = useState({ elementClicked: false, designData: {} as any, key: '', customData: '' });
    //defaultData from JSON
    function loadDataDefault(id = null) {
        let designerObject = {} as any;
        if (type) {
            let curdata = (defaultViews as any)[type];
            if(curdata.Data[0]?.CustomerData?.FillData === true){
                curdata.Data[0].CustomerData = {...JSON.parse(JSON.stringify({...defaultViews['customer']?.Data[0]}).replaceAll('"Key":"', '"Key":"'+curdata.Data[0].CustomerData.Key + '-'))};
            }
            if(curdata.Data[0]?.Customer?.FillData === true){
                curdata.Data[0].Customer = {...JSON.parse(JSON.stringify({...defaultViews['customer']?.Data[0]}).replaceAll('"Key":"', '"Key":"'+curdata.Data[0].Customer.Key + '-'))};
            }
            if (curdata[viewType + 'Elements']) {
                let shownElements = curdata[viewType + 'Elements'][0];
                let preparedData = prepareData(curdata.Data[0]);
                console.log(shownElements);
                if (Array.isArray(shownElements)) {
                    // OLD 17.01.24
                    console.log(shownElements);
                    shownElements.forEach((entry: any) => {
                        console.log(preparedData);
                        designerObject[entry] = preparedData[entry];
                    });
                } else designerObject = shownElements;

                console.log(designerObject);
                let Data = { Data: curdata.Data[0], viewData: name, DesignElements: designerObject, ID: id, ViewLabel: viewData.ViewLabel };
                dispatch(detailViewActions.init(Data));
            }
        }
    }

    //#region UseEffect
    useEffect(() => {
        setCurGenericElement({ elementClicked: false, designData: {}, key: '', customData: '' });
        setDataLoaded(false);
        loadLayoutNames();
        getLayout();
        getUserGroups();
    }, [dispatch, viewType, type]);

    function getUserGroups() {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetUserGroups)
            .then((data: any) => {
                setUserGroups(data.Data);
            })
            .catch(e => {
                console.log('No Usergroups found!');
            });
    }

    useEffect(() => {
        console.log(curGenericElement);
        if (curGenericElement.elementClicked) {
            var genericElement = JSON.parse(JSON.stringify(curGenericElement));
            console.log(genericElement);
            console.log(viewData.DesignElements);
            console.log(viewData.DesignElements[curGenericElement.key]);
            (genericElement.designData as any).Conditions = viewData.DesignElements[curGenericElement.key].Conditions;
            setCurGenericElement(genericElement);
        }
    }, [viewData?.DesignElements[curGenericElement?.key]?.Conditions]);
    //#endregion UseEffect

    //#region Api Calls
    function getLayout(layoutname: string = curLayoutName) {
        setDataLoaded(false);
        loadDataDefault();
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetViewLayouts + props.name + '&LayoutName=' + layoutname)
            .then((data: any) => {
                if (data.Data) {
                    //overwriteDesigner(data.Data.Json, Data); //setCustomData
                    var designElements = data.Data.Json.DesignElements;
                    if (data.Data.Json.ElementsOrder) {
                        var x = {};
                        var count = 1;
                        data.Data.Json.ElementsOrder[0].forEach((el: any) => {
                            if (data.Data.Json.DesignElements[el]) {
                                x[el] = data.Data.Json.DesignElements[el];
                                x[el].Order = count;
                                count++;
                            }
                        });
                        designElements = x;
                    }
                    dispatch(detailViewActions.changeViewSettings({ key: 'DesignElements', value: designElements }));
                    dispatch(detailViewActions.changeViewSettings({ key: 'ID', value: data.Data.ID }));
                    dispatch(detailViewActions.changeViewSettings({ key: 'ViewLabel', value: data.Data.ViewLabel ?? '' }));
                } else {
                    dispatch(detailViewActions.changeViewSettings({ key: 'ID', value: null }));
                }
                setDataLoaded(true);
            })
            .catch(e => {
                console.log(e);
                console.log('Default Json Data!');
                setCurLayoutName('default');
                dispatch(detailViewActions.changeViewSettings({ key: 'ID', value: null }));
                setDataLoaded(true);
            });
    }

    function loadLayoutNames() {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + '/api/Layout/GetBusinessProcessViewLayoutNames?ViewName=' + props.name).then((data: any) => {
            setLayoutNames(data.Data);
            if (data.Data.findIndex((l: any) => l.LayoutName === curLayoutName) === -1) {
                const idx = data.Data.findIndex((l: any) => l.LayoutName === 'default');
                setCurLayoutName(data.Data[idx > 0 ? idx : 0]?.LayoutName);
            }
        });
    }

    function saveViewData() {
        if (viewData.ID === null) {
            loadJsonPost('/' + LocalStorage?.getConnectionKey() + URLS.PostBusinessProcessView, {
                CreateData: { ConfigData: JSON.stringify({ DesignElements: viewData.DesignElements }), LayoutName: curLayoutName, ViewName: props.name, ViewType: viewType, ViewLabel: viewData.ViewLabel },
            }).then(data => {
                if (data.Meta.Success) setSaveMessage({ message: 14445, success: true });
                else setSaveMessage({ message: 13656, success: false });
            });
        } else {
            loadJsonPut('/' + LocalStorage?.getConnectionKey() + URLS.PutBusinessProcessView, {
                UpdateData: {
                    ConfigData: JSON.stringify({ DesignElements: viewData.DesignElements }),
                    LayoutName: curLayoutName,
                    ViewName: props.name,
                    ID: viewData.ID,
                    ViewType: viewType,
                    ViewLabel: viewData.ViewLabel,
                },
            }).then(data => {
                if (data.Meta.Success) setSaveMessage({ message: 14445, success: true });
                else setSaveMessage({ message: 14847, success: false });
            });
        }
        setCurGenericElement({ elementClicked: false, designData: {}, key: '', customData: '' });
    }

    function deleteViewData() {
        if (viewData.ID) {
            loadJsonDelete('/' + LocalStorage?.getConnectionKey() + URLS.DeleteBusinessProcessView + '?Index=' + viewData.ID).then(data => {
                if (data.Meta.Success) {
                    setSaveMessage({ message: 14905, saveText: props.name + '-' + curLayoutName, success: true });
                }
            });
        }
        setCurLayoutName('default');
        loadLayoutNames();
        getLayout('default'); // parameter needed, because viewdata are not updated immediately
    }

    //#endregion Api Calls

    //#region Dragable
    let target: any;
    let shadow: any;
    function dragStart(e: any) {
        shadow = e.target;
    }
    function dragOver(e: any) {
        e.preventDefault();
        var prev = target;
        target = e.target.closest('.mfx-generic-element');
        if (!target.id && !target.draggable) return;
        if (prev && prev !== target) {
            prev.style.borderLeft = '';
            prev.style.borderRight = '';
        }
        if (target !== shadow) {
            target.style[target.clientWidth / 2 > e.clientX - target.offsetLeft ? 'borderLeft' : 'borderRight'] = '3px solid #58fa7c';
            target.style[target.clientWidth / 2 < e.clientX - target.offsetLeft ? 'borderLeft' : 'borderRight'] = '';
        }
    }

    function dragEnd(e: any) {
        if (target) {
            target.style.borderLeft = '';
            target.style.borderRight = '';
            if (target?.id !== '' && shadow?.id !== '') dispatch(detailViewActions.setOrder({ target: target.id, shadow: shadow.id, insertBefore: target.clientWidth / 2 > e.clientX - target.offsetLeft }));
            else alert('Drag and Drop failed since the object it was dropped on was not part of the Table');
        }
    }

    //#endregion Dragable

    //#region Event Handlers

    function addElement(settingsObject: any) {
        var data = { ...settingsObject };
        dispatch(detailViewActions.addData(data));
    }

    function handleCreate(inputValue: any) {
        setCurLayoutName(inputValue);
        setLayoutNames([...layoutNames, { LayoutName: inputValue }]);
        loadDataDefault();
    }
    //#endregion Event Handlers

    //#region Helper Methods

    function renderOption(newEntry: any) {
        return (
            <option key={newEntry.Key} value={newEntry.Key}>
                {newEntry.Key}
            </option>
        );
    }

    // The Type:Option isn't used yet
    function ViewData(curdata: Object, index: string, type: string, withType = false): any {
        if (Array.isArray(curdata)) curdata = curdata[0];
        if (!Object.entries(curdata).find((d: any) => d[1].Designable)) {
            return null;
        }
        return (
            <>
                {curdata &&
                    Object.entries(curdata)
                        .filter((data: any) => {
                            if (type !== 'Option' && data[1].Label) return window.Dictionary.getTranslation(data[1].Label).toLowerCase().includes(checkboxSearch.toLowerCase());
                            return true;
                        })
                        .sort((a, b) => {
                            return !a[1].Designable && b[1].Designable ? 1 : window.Dictionary.getTranslation(a[1].Label) > window.Dictionary.getTranslation(b[1].Label) ? 1 : -1;
                        })
                        .map((key, idx) => {
                            if (type === 'Option' && typeof key[1] === 'object' && key[1].Designable === true && (key[1].Label || key[1].Name)) {
                                return (
                                    <option key={(index ? index + '-' : '') + key[0]} value={key[1].Key}>
                                        {(index ? index + '-' : '') + window.Dictionary.getTranslation(key[1].Label ?? key[1].Name ?? '')}
                                    </option>
                                );
                            } else if (typeof key[1] === 'object' && key[1].Designable === true && (key[1].Label || key[1].Name)) {
                                return (
                                    <div key={idx}>
                                        <Checkbox
                                            checked={Object.entries(viewData.DesignElements).find((d: any) => d[1].Key === key[1].Key) ? true : false}
                                            key={key[1].Key}
                                            designData={key[1]}
                                            uniqueKey={'Data-' + key[1].Key}
                                            onChangeHandler={(settingsObject: object) => addElement(settingsObject)}
                                        />
                                    </div>
                                );
                            } else if ((Array.isArray(key[1]) || typeof key[1] === 'object') && !key[1].Designable && key[1].Designable !== false && ViewData(key[1], (index ? index + '-' : '') + key[0], type)) {
                                if (type === 'Option') {
                                    return <>{ViewData(key[1], (index ? index + '-' : '') + key[0], type)}</>;
                                }
                                return (
                                    <Fragment key={idx}>
                                        <h4 onClick={() => setValueAccord({ ...valueAccord, [key[0]]: !valueAccord[key[0]] ?? true })}>{'-'.repeat(index ? index.split('-').length + 1 : 1) + key[0]}</h4>
                                        {valueAccord[key[0]] && ViewData(key[1], (index ? index + '-' : '') + key[0], type, withType)}
                                    </Fragment>
                                );
                            }
                            return <></>;
                        })}
            </>
        );
    }
    //#endregion Helper Methods

    //#region screen resize
    const [width, setWidth] = useState(75);
    function resizeScreen(e: any) {
        if ((e.pageX / window.innerWidth) * 100 > 280) {
            if (e.clientX === 0) return;
            setWidth((e.pageX / window.innerWidth) * 100);
        }
    }
    //#endregion screen resize

    function getEmptyKey() {
        var count = Object.entries(viewData.DesignElements).filter((el: any) => (el[1].Key as string).startsWith('EmptyElement-')).length;
        return 'EmptyElement-' + count;
    }

    return (
        <Provider store={store}>
            <main id="mfx-generic-view-editor" className="mfx-gp-container detailview">
                {dataLoaded && (
                    <>
                        <div className="mfx-preview" style={{ gridTemplateColumns: width + '% 10px auto' }}>
                            <div id="mfx-generic-view-preview" className="mfx-gp-page">
                                <h1>
                                    {props.menuData && window.Dictionary.getTranslation(props.menuData.label)} ({curLayoutName}){' '}
                                </h1>
                                <div className="mfx-view">
                                    <div className="mfx-container detailviewcontainer">
                                        {viewData.DesignElements &&
                                            Object.entries(viewData.DesignElements)
                                                .sort((a: any, b: any) => {
                                                    return a[1].Order > b[1].Order ? 1 : -1;
                                                })
                                                .map((key: any, idx: number) => {
                                                    let entry = key[1];
                                                    let activeClass = curGenericElement.key === entry.Key ? 'active' : '';
                                                    if (entry)
                                                        return (
                                                            <div className={activeClass} style={entry.Elementtype === 'profiling' ? {gridColumn: '1/-1'} : {}}>
                                                                <GenericElement
                                                                    designData={entry as any}
                                                                    disableMode={true}
                                                                    index={idx}
                                                                    onDragEnd={dragEnd}
                                                                    onDragStart={dragStart}
                                                                    onDragOver={dragOver}
                                                                    onElementClicked={() => {
                                                                        if (curGenericElement.key === (entry as any).Key) setCurGenericElement({ elementClicked: false, designData: {}, key: '', customData: '' });
                                                                        else setCurGenericElement({ elementClicked: true, designData: entry, key: (entry as any).Key, customData: (entry as any).DefaultValue });
                                                                    }}
                                                                />
                                                            </div>
                                                        );

                                                    return <></>;
                                                })}
                                    </div>
                                </div>
                                <div className="grid-common-buttons">
                                    <Button onClickHandler={() => loadDataDefault(viewData.ID)} iconClass="fa-solid fa-rotate-right" dll="Load Default" />
                                    <Button iconClass="icon-close" onClickHandler={getLayout} dll={1472} />
                                    {curLayoutName !== 'default' && <Button iconClass="icon-delete" important={false} onClickHandler={deleteViewData} dll={64} />}
                                    <Button iconClass="icon-save" important={true} onClickHandler={saveViewData} dll={63} />
                                </div>
                            </div>
                            <div className="mfx-resizer" draggable={true} onDrag={resizeScreen}></div>

                            <div className="mfx-sidebar">
                                {
                                    <>
                                        <h2>
                                            {window.Dictionary.getTranslation(134)}
                                            <Info text={window.Dictionary.getTranslation(15407)} />
                                        </h2>
                                        <div className='mfx-react-select'>
                                            {
                                                <CreatableSelect
                                                    onChange={(newValue: any) => {
                                                        setCurLayoutName(newValue.value);
                                                        getLayout(newValue.value);
                                                    }}
                                                    onCreateOption={handleCreate}
                                                    options={layoutNames?.map((name: any) => {
                                                        return { label: name.LayoutName, value: name.LayoutName };
                                                    })}
                                                    value={{ label: curLayoutName, value: curLayoutName }}
                                                />
                                            }
                                        </div>

                                        <h4>
                                            {window.Dictionary.getTranslation(15224)}
                                            <Info text={window.Dictionary.getTranslation(15406)} />
                                        </h4>
                                        <input type="text" value={viewData.ViewLabel} onChange={e => dispatch(detailViewActions.changeViewSettings({ key: 'ViewLabel', value: e.target.value }))} />
                                    </>
                                }
                                {curGenericElement.elementClicked && (
                                    <div className="customsettings">
                                        <h3>{window.Dictionary.getTranslation(1109)}</h3>
                                        <h4>{curGenericElement.key}</h4>
                                        <div className="customsettings-elements">
                                            <div>
                                                <label htmlFor="label">{window.Dictionary.getTranslation(14469)}</label>
                                                <input
                                                    name="label"
                                                    type="text"
                                                    id="label"
                                                    value={window.Dictionary.getTranslation((viewData.DesignElements[curGenericElement.key as any] as any).Label)}
                                                    onChange={e => {
                                                        if (viewData.DesignElements[curGenericElement.key as any] as any) {
                                                            setCurGenericElement({ ...curGenericElement, designData: { ...curGenericElement.designData, Label: e.target.value ? e.target.value : (viewData.DesignElements[curGenericElement.key as any] as any).DefaultLabel } });
                                                            dispatch(detailViewActions.changeDesignElements({ ...curGenericElement, element: { ...curGenericElement.designData, Label: e.target.value ? e.target.value : (viewData.DesignElements[curGenericElement.key as any] as any).DefaultLabel } }));
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="defaultvalue">{window.Dictionary.getTranslation(5680)}</label>

                                                <GenericElement
                                                    disableMode={false}
                                                    designData={curGenericElement.designData}
                                                    getDropdownValues={true}
                                                    noLabel={true}
                                                    readOnly={false}
                                                    onChangeHandler={(data: any) => {
                                                        setCurGenericElement({ ...curGenericElement, designData: { ...curGenericElement.designData, DefaultValue: data.newValue ?? data } });
                                                        dispatch(detailViewActions.changeDesignElements({ ...curGenericElement, element: { ...curGenericElement.designData, DefaultValue: data.newValue ?? data } }));
                                                    }}
                                                />
                                            </div>
                                            <div className="">
                                                <h4>{window.Dictionary.getTranslation(15408)}</h4>
                                                <select
                                                    onChange={e => dispatch(detailViewActions.setConditions({ condition: { type: 'alternative', value: e.target.value }, key: curGenericElement.key }))}
                                                    value={curGenericElement.designData.Conditions && curGenericElement.designData.Conditions[0] ? curGenericElement.designData.Conditions[0].value : ''}>
                                                    <option value=""></option>
                                                    {viewData.Data &&
                                                        Object.entries(viewData.DesignElements).map((entry: any, idx) => {
                                                            if (curGenericElement.designData.Key === entry[1].Key) return <></>;
                                                            let newEntry = { ...entry[1] };
                                                            return renderOption(newEntry);
                                                        })}
                                                </select>
                                            </div>
                                            <div className="mfx-checkbox-element">
                                                <input
                                                    name="required"
                                                    id="requiredcheckbox"
                                                    type="checkbox"
                                                    checked={curGenericElement.designData.Required || false}
                                                    onChange={e => {
                                                        setCurGenericElement({ ...curGenericElement, designData: { ...curGenericElement.designData, Required: e.target.checked } });
                                                        dispatch(detailViewActions.changeDesignElements({ ...curGenericElement, element: { ...curGenericElement.designData, Required: e.target.checked } }));
                                                    }}
                                                />
                                                <label htmlFor="requiredcheckbox">{window.Dictionary.getTranslation(325)}</label>
                                            </div>
                                            <div className="mfx-checkbox-element">
                                                <input
                                                    name="readonly"
                                                    id="readonlycheckbox"
                                                    type="checkbox"
                                                    checked={curGenericElement.designData.ReadOnly || false}
                                                    onChange={e => {
                                                        setCurGenericElement({ ...curGenericElement, designData: { ...curGenericElement.designData, ReadOnly: e.target.checked } });
                                                        dispatch(detailViewActions.changeDesignElements({ ...curGenericElement, element: { ...curGenericElement.designData, ReadOnly: e.target.checked } }));
                                                    }}
                                                />
                                                <label htmlFor="readonlycheckbox">{window.Dictionary.getTranslation(14933)}</label>
                                            </div>
                                            <div className='mfx-react-select'>
                                                <label htmlFor="fullAccessUsers">{window.Dictionary.getTranslation(15974)}</label>
                                                <CreatableSelect
                                                    onChange={(newValue: any) => {
                                                        var val = newValue.map(v => v.value);
                                                        setCurGenericElement({ ...curGenericElement, designData: { ...curGenericElement.designData, WriteGroups: val } });
                                                        dispatch(detailViewActions.changeDesignElements({ ...curGenericElement, element: { ...curGenericElement.designData, WriteGroups: val } }));
                                                    }}
                                                    isMulti
                                                    options={userGroups?.map((group: any) => {
                                                        return { label: group.Name, value: group.Name };
                                                    })}
                                                    value={curGenericElement.designData?.WriteGroups?.map((group: any) => {
                                                        return { label: group, value: group };
                                                    })}
                                                />
                                            </div>
                                            <button
                                                onClick={e => {
                                                    var key = curGenericElement.designData.Key;
                                                    setCurGenericElement({elementClicked: false, designData: {} as any, key: '', customData: '' });
                                                    addElement({ key: key, newValue: false });
                                                }}>
                                                Delete Element
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {!curGenericElement.elementClicked && (
                                    <>
                                        <h3>{window.Dictionary.getTranslation(1672)}</h3>
                                        <input id="checkboxsearch" placeholder={window.Dictionary.getTranslation(162)} value={checkboxSearch} onChange={e => setCheckboxSearch(e.target.value)} />
                                        <div className="mfx-element-selection">
                                            {ViewData(viewData.Data, '', 'Checkbox', false)}{' '}
                                            <div>
                                                <button
                                                    onClick={e => {
                                                        var key = getEmptyKey();
                                                        addElement({ key: key, newValue: true, label: '', designData: { Elementtype: 'empty', Key: key } });
                                                    }}>
                                                    Add Empty
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        {saveMessage && <SavedModal saveMessage={saveMessage} closeIcon={true} handleClose={() => setSaveMessage(null)} />}
                    </>
                )}
            </main>
        </Provider>
    );
}

export function GenericDetailViewProvider(props: IGenericViewEditorProps) {
    return (
        <Provider store={store}>
            <GenericDetailViewEditor {...props} />
        </Provider>
    );
}
export type RootState = ReturnType<typeof store.getState>;

// OLD 17.01.24
function prepareData(data: any) {
    let preparedData = {} as any;
    Object.keys(data).forEach((key, idx) => {
        if (data[key].Designable) {
            preparedData[key] = data[key];
        } else if (typeof data[key] == 'object') {
            Object.keys(data[key]).forEach((subkey, idx) => {
                if (data[key][subkey].Designable && data[key][subkey].Label) {
                    preparedData[data[key][subkey].Key] = { ...data[key][subkey] };
                    preparedData[data[key][subkey].Key].Label = key + ' - ' + window.Dictionary.getTranslation(data[key][subkey].Label);
                }
            });
        }
    });
    return preparedData;
}
