/**
 * Renders an generic checkbox
 * @returns {JSX.Element} The basic checkbox.
 * @param {any} props given by caller
 */

import { useEffect, useState } from 'react';

const Checkbox = props => {
    let checkboxID = window.Dictionary.getTranslation(props.designData.Key);
    checkboxID = 'checkbox-' + checkboxID?.replace(/\s/g, '-').toLowerCase();
    const [inputChecked, setInputChecked] = useState(false);

    const checkboxChangeHandler = event => {
        props.onChangeHandler({ newValue: !inputChecked, type: props.uniqueIndex, designData: props.designData });
        setInputChecked(!inputChecked);
    };

    useEffect(() => {
        setInputChecked(props.customerValue !== undefined ? props.customerValue : typeof props.designData.DefaultValue === 'boolean' ? props.designData.DefaultValue : false);
    }, [props.customerValue,props.designData.DefaultValue]);
    

    return (
        <div className="mfx-checkbox-element">
            <input type="checkbox" id={checkboxID} name={props.designData.Key} defaultChecked={props.designData.Checked} checked={inputChecked} disabled={Boolean(props.disableMode) || props?.designData?.ReadOnly} onChange={checkboxChangeHandler} />
            {props.designData.Label && <label htmlFor={checkboxID}>{!isNaN(props.designData.Label) ? window.Dictionary.getTranslation(props.designData.Label) : props.designData.Label}</label>}
        </div>
    );
};

export default Checkbox;
