/**
 * Renders an generic ChartContainer
 * @returns {JSX.Element} The basic ChartContainer.
 */

import BaseChart from './BaseChart';
import DataChart from './DataChart';
import PieChart from './PieChart';
import BaseChartGrouped from './BaseChartGrouped';
import SqlTable from './SqlTable';
import LineChart from './LineChart';
import {MFXGaugeChart} from './GaugeChart';

const ChartContainer = props => {
    function generateChart() {
        switch (props.chart.Type) {
            case 'pie':
                return <PieChart chart={props.chart} />;
            case 'base':
                return <BaseChart chart={props.chart} />;
            case 'base-grouped':
                return <BaseChartGrouped chart={props.chart} />;
            case 'dataset':
                return <DataChart chart={props.chart} />;
            case 'line':
                return <LineChart chart={props.chart} />;
            case 'sqlTable':
                return <SqlTable chart={props.chart} />;
            case 'gauge':
                return <MFXGaugeChart chart={props.chart} elementRendered={props.viewRendered} />;
            default:
                return <></>;
        }
    }

    return (
        <div className={`mfx-col-${12 / props.cols}`}>
            <div className="mfx-chart-container">
                <div className={`mfx-chart-${props.chart.Type}`} /*style={{height: (12 / props.cols) * 5 + 'vw'}}*/>{generateChart()}</div>
            </div>
        </div>
    );
};

export default ChartContainer;
