/**
 * Renders an generic checkbox
 * @returns {JSX.Element} The basic checkbox.
 * @param {any} props given by caller
 */

const Checkbox = props => {
    return (
        <div className="mfx-checkbox-element">
            <input type="checkbox" id={'checkbox-table-' + props.dataKey} name={'checkbox-table-' + props.dataKey} checked={props.value} onChange={e => props.onChangeInput({ key: props.dataKey, newValue: e.target.checked })} />
        </div>
    );
};

export default Checkbox;
