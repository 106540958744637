/**
 * Renders an generic dropdown with 2 Options: yes or no
 * @returns {JSX.Element} The dropdown with 2 Options: yes or no.
 * @param {any} props given by caller
 */
 
 const YesOrNoElement = (props) => {

    let radioLabel = window.Dictionary.getTranslation(props.designData.Name);
    let radioID = 'radio-' + radioLabel.replace(/\s/g, '-').toLowerCase();

    const handleOnChange = (e) => {
      if(props.onChangeHandler) 
        props.onChangeHandler({newValue:e.target.value,type:'YesOrNo', designData:props.designData});
    };
    return (
      <div className='mfx-dropdown-element'>
       {props.designData.Label && 
         <label htmlFor={radioID} >{window.Dictionary.getTranslation(props.designData.Label)}</label>
       }
       <select id={radioID} name={radioID} defaultValue={props.designData.Value ?? props.designData.DefaultValue} style={{pointerEvents: 'none'}} disabled={Boolean(props.disableMode ? props.disableMode :true)} onChange={handleOnChange}>
            <option value=""></option>
            <option value="1">{window.Dictionary.getTranslation(1651)}</option>
            <option value="0">{window.Dictionary.getTranslation(1652)}</option>
        </select>
      </div>
    );
 
  };
  
  
  export default YesOrNoElement;