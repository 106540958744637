/**
 * Renders a Pie Chart
 * @returns {JSX.Element} The Pie Chart
 */

import { ResponsiveBar } from '@nivo/bar';
import { useEffect, useState } from 'react';

const BaseChart = props => {
    const [chartData, setChartData] = useState({
        data: [],
        tickValues: [],
        indexBy: null,
        keys: [],
        labels: [],
    });

    useEffect(() => {
        let maxTick = 0;
        let percentageValues = [];
        let newChartData = [];
        const grouped = props?.chart?.Data?.some(d => d.label2) && props.chart.GroupMode === 'grouped';

        if (props?.chart?.Data?.length > 0) {
            props?.chart?.Data?.forEach((data, idx) => {
                newChartData[idx] = { ...data };

                if (typeof data.value == 'string') data.value = parseFloat(data.value.replace(',', '.'));
                if (data.value2 && typeof data.value2 == 'string') data.value2 = parseFloat(data.value2.replace(',', '.'));

                newChartData[idx].value = data.value;

                if (data.value2 !== undefined && data.value2 !== null && !isNaN(data.value2)) newChartData[idx].value2 = data.value2;

                if (data.value2 !== undefined && data.value2 !== null) percentageValues.push((newChartData[idx].value2 / parseFloat(data.value)).toFixed(2) * 100 + '%');
                if(!grouped){
                    if (maxTick < newChartData[idx].value + newChartData[idx].value2 ?? 0) maxTick = newChartData[idx].value + newChartData[idx].value2 ?? 0;
                }
                else{
                    if (maxTick < (newChartData[idx].value ?? 0) || maxTick < (newChartData[idx].value2 ?? 0)){
                        maxTick = newChartData[idx].value > newChartData[idx].value2 ?? 0 ? newChartData[idx].value : newChartData[idx].value2 ?? 0;
                    } 
                }

                //if(newChartData[idx].value > 999) newChartData[idx].value = new Intl.NumberFormat('en-DE').format(newChartData[idx].value);
            });
            setChartData({
                ...chartData,
                tickValues: [0, Math.round(maxTick / 4), Math.round(maxTick / 2),Math.round(maxTick/1.5),Math.round(maxTick)],
                percentageValues: percentageValues ?? [],
                data: newChartData,
                legendLabel: props?.chart?.Data.some(d => d.label2)
                    ? [
                          { label: window.Dictionary.getTranslation(props?.chart?.Data?.find(d => d.label)?.label ?? ''), color: 'var(--statisticcolor1)' },
                          { label: window.Dictionary.getTranslation(props?.chart?.Data?.find(d => d.label2)?.label2 ?? ''), color: 'var(--statisticcolor2)' },
                      ]
                    : null,
            });
        }
        //if (props.elementRendered) props.elementRendered();
    }, [props]);

    return (
        <>
            {chartData.data?.length > 0 && chartData.data?.find(d => d.value || d.value2) && (
                <>
                    <div className="mfx-chart">
                        <ResponsiveBar
                            data={chartData.data}
                            keys={['value', 'value2']}
                            indexBy="id"
                            margin={{ top: 20, right: 30, bottom: 50, left: 50 }}
                            padding={0.4}
                            layout={props.chart.Layout === 'vertical' ? 'vertical' : 'horizontal'}
                            groupMode={chartData.percentageValues.length > 0 && props.chart.GroupMode === 'grouped' ? 'grouped' : 'stacked'}
                            labelSkipWidth={10}
                            labelSkipHeight={10}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: false }}
                            theme={{
                                axis: {
                                    ticks: {
                                        text: {
                                            fill: 'var(--fontColor)',
                                        },
                                    },
                                },
                                tooltip: {
                                    container: {
                                        backgroundColor: 'var(--bgColor)',
                                    },
                                },
                            }}
                            colors={['var(--statisticcolor1', 'var(--statisticcolor2)']}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: '#38bcb2',
                                    size: 4,
                                    padding: 1,
                                    stagger: true,
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: '#eed312',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10,
                                },
                            ]}
                            borderColor={{
                                from: 'color',
                                modifiers: [['darker', '1.4']],
                            }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                tickValues: props.chart.TickCount && !isNaN(props.chart.TickCount) ? +props.chart.TickCount : undefined,
                                
                                format: value => isNaN(value) ?  value : `${new Intl.NumberFormat('en-DE').format(value)}`
                                }} 
                                valueFormat={value => isNaN(value) ?  value : `${new Intl.NumberFormat('en-DE').format(value)}`
                            }
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                            }}
                            enableGridY={true}
                            enableGridX={true}
                            enableLabel={true}
                            label={
                                d => {
                                    if(d.data.value && d.data.value2){
                                        let percent = '';
                                        switch(props.chart.Percent){
                                            case 'none': break;
                                            case 'first': 
                                                if(d.id === 'value')
                                                    percent = ' (' + ((d.value / d.data.value2) * 100).toFixed(2).replace('.',',') + '%)';
                                                break;
                                            case 'second': 
                                                if(d.id === 'value2')
                                                    percent = ' (' + ((d.value / d.data.value) * 100).toFixed(2).replace('.',',') + '%)';
                                                break;
                                            default: percent = ' (' + ((d.value / (d.value + d.data[d.id === 'value' ? 'value2' : 'value'])) * 100).toFixed(2).replace('.',',') + '%)'; break;
                                        }
                                        return new Intl.NumberFormat('en-DE', {maximumFractionDigits: 0}).format(d.value) + percent;
                                    }else if(d.data.value > 999){
                                        return new Intl.NumberFormat('en-DE', {maximumFractionDigits: 0}).format(d.value);
                                    }else 
                                        return d.value;
                                }
                            }
                            tooltipLabel={d => {
                                var obj = chartData.data.find(dt => dt.id === d.indexValue);
                                return `${d.id === 'value' ? window.Dictionary.getTranslation(obj.label) ?? 'Value' : window.Dictionary.getTranslation(obj.label2) ?? 'Value2'}`;
                            }}
                            labelTextColor={'var(--iconColor)'}
                        />

                        <div className="mfx-legend">
                            {chartData?.legendLabel &&
                                chartData.legendLabel.map(labelData => {
                                    return (
                                        <div>
                                            <span style={{ backgroundColor: labelData.color }}></span>
                                            <p>{labelData.label}</p>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </>
            )}
            {(chartData.data?.length === 0 || !chartData.data?.find(d => d.value || d.value2)) && (
                <h4 className="nothing-found">
                    <div className="success-false extra-small">
                        <div className="icon-style-arrow">
                            <i className="icon-close"></i>
                        </div>
                    </div>
                    {window.Dictionary.getTranslation(5484)}
                </h4>
            )}
        </>
    );
};

export default BaseChart;
