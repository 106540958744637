import MainHeadline from './Elements/MainHeadline';
import GenericGroup from './GenericGroup';
import GenericView, { IView } from './GenericView';

export interface IContainer {
  icon: string;
  Subheadline?: string;
  Headline?: string;
  w?: number;
  children: Array<IView>;
  x : number;
  y : number;
  h : number ;
  size?:number;
  Group? : string;
  Name?:string;
  title?: string; //delete when live-data
  subtitle?: string; //delete when live-data
}

/**
 * Renders an generic container with all its views and groups
 * @param {IGenericPageProps} props Properties given by caller
 * @returns {JSX.Element} Rendered element
 */
export default function GenericContainer(props: { data: IContainer; containersMatrix:any }): JSX.Element {
  //remove after loading all Pages via Database
  let gridColumnEndT = props.data.w ? (props.data.w + props.data.x) : ((props.data.size ? props.data.size : 0) + props.data.x);
    
  //logger.debug(props);

  const containerGrid = {
    gridRowStart: props.data.y + 1,
    gridColumnStart: props.data.x + 1,
    gridRowEnd:  props.data.h + props.data.y,
    gridColumnEnd : gridColumnEndT + 1
  };

  const resizeContainers = (container) => {
    /* ------------- RESPONSIVE / Mobile FEATURES HERE ------------ */
    /*if(props.containersMatrix.length == 2 ) {
      if(document.getElementById(container.Name)?.style)
        (document.getElementById(container.Name) as any).style.height = 'calc(' + document.getElementById(container.Name + '-container')?.offsetHeight + 'px + 2.6rem)';
    }*/
  };
  return (
      <div id={props.data.Name} className={'mfx-gp-container size-' + props.data.w} style={containerGrid}> 
          {props.data.Headline &&
            <div className='mfx-container-headline'>
              <MainHeadline designData={props.data} />
            </div>
          }
          {props.data && props.data.Group  &&
              <GenericGroup data={props.data} />
          }
          
          {props.data && !props.data.Group && Object.entries(props.data.children).sort((a:any,b:any) => {return a.order > b.order ? 1 : -1;}).map((v, idx) => {
            return <GenericView key={idx} data={v[1]} 
                        containerdata={props.data} 
                        renderedCompletly={() => resizeContainers(props.data)}/>;
          })}
      </div>
    
  );
}
